import axios from 'axios';
import { useEffect } from 'react';
import { urls } from '../_rego.config';
import { buildUrl } from '../../../helpers/utils';

const DocumentDownload = (props) => {
    const { config, documents } = props;
    useEffect(() => {}, [documents, config]);

    const downloadFile = async () => {
        const tmp = [];
        for (const document of documents) {
            let response = await axios.get(
                buildUrl(
                    urls.storage_documents_details,
                    { id: document.storage_id },
                    { ignoreExtra: true }
                )
            );
            response = response.data.files;
            for (const element of response) {
                try {
                    const { data } = await axios.get(
                        buildUrl(urls.storage_file, { file_path: element.file_name })
                    );
                    window.open(data.url);
                } catch (error) {}
            }
        }
        return tmp;
    };

    return (
        <span
            className='text-primary cursor-pointer'
            onClick={downloadFile}>
            {(config?.icon && <i className={`text-primary ${config.icon}`}></i>) ||
                (config?.btn && (
                    <button className='btn btn-sm me-2 btn-icon btn-light-primary btn-circle'>
                        <i className={config.btn}></i>
                    </button>
                )) ||
                config.label ||
                'View File'}
        </span>
    );
};

export default DocumentDownload;
