import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWarehouseDetails } from '../_rego.service';
import DataList from '../../../components/organisms/DataList';

const SignzyItrPullAnalytics = () => {
    const { report_id } = useParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        setReport(data);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, [report_id]);
    return (
        <>
            {report?.result &&
                Object.entries(report?.result).map(([year, row], key) => {
                    return (
                        <React.Fragment key={key}>
                            {Object.entries(row.ITR).map(([itrName, itrRow]) => {
                                return (
                                    <React.Fragment key={`${key}_${itrRow}`}>
                                        <h1>
                                            {year} - {itrName}
                                        </h1>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='card'>
                                                    <div className='card-header'>
                                                        <div className='card-title'>
                                                            General Information
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DataList
                                                            fields={[
                                                                {
                                                                    value: 'panNumber',
                                                                    label: 'PAN Number',
                                                                },
                                                                {
                                                                    value: 'filingYear',
                                                                    label: 'Filing Year',
                                                                },
                                                                {
                                                                    value: 'filingDate',
                                                                    label: 'Filing Date',
                                                                },
                                                                {
                                                                    value: 'filingSection',
                                                                    label: 'Filing Section',
                                                                },
                                                                {
                                                                    value: 'itrType',
                                                                    label: 'ITR Type',
                                                                },
                                                                {
                                                                    value: 'acknowledgementNumber',
                                                                    label: 'Acknowledgement Number',
                                                                },
                                                                {
                                                                    value: 'filingType',
                                                                    label: 'Filing Type',
                                                                },
                                                                {
                                                                    value: 'filedBy',
                                                                    label: 'Filed By',
                                                                },
                                                            ]}
                                                            details={row}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='card mt-5'>
                                                    <div className='card-header'>
                                                        <div className='card-title'>
                                                            Personal Information
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DataList
                                                            details={
                                                                itrRow?.PartA_GEN1?.PersonalInfo ||
                                                                itrRow?.PersonalInfo
                                                            }
                                                            fields={[
                                                                'panNumber',
                                                                {
                                                                    value: `AssesseeName.FirstName`,
                                                                    label: 'First Name',
                                                                },
                                                                {
                                                                    value: `AssesseeName.MiddleName`,
                                                                    label: 'Middle Name',
                                                                },
                                                                {
                                                                    value: `AssesseeName.SurNameOrOrgName`,
                                                                    label: 'Surname or Organization Name',
                                                                },
                                                                {
                                                                    value: `PAN`,
                                                                    label: 'PAN',
                                                                },
                                                                {
                                                                    value: `DOB`,
                                                                    label: 'Date of Birth',
                                                                },
                                                                {
                                                                    value: `EmployerCategory`,
                                                                    label: 'Employer Category',
                                                                },
                                                                {
                                                                    value: `Status`,
                                                                    label: 'Status',
                                                                },
                                                                {
                                                                    value: `AadhaarCardNo`,
                                                                    label: 'Aadhaar Card Number',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                {row?.ITR[itrName]?.ScheduleBP?.NatOfBus44AD && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Business Details
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            {row.ITR[
                                                                itrName
                                                            ].ScheduleBP.NatOfBus44AD.map(
                                                                (businessRow) => {
                                                                    return (
                                                                        <DataList
                                                                            fields={[
                                                                                {
                                                                                    value: `NameOfBusiness`,
                                                                                    label: 'Name of Business',
                                                                                },
                                                                                {
                                                                                    value: `CodeAD`,
                                                                                    label: 'Nature of Business',
                                                                                },
                                                                                {
                                                                                    value: `Description`,
                                                                                    label: 'Business Description',
                                                                                },
                                                                            ]}
                                                                            details={businessRow}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                            {row.ITR[
                                                                itrName
                                                            ].ScheduleBP.TurnoverGrsRcptForGSTIN.map(
                                                                (item) => {
                                                                    return (
                                                                        <DataList
                                                                            fields={[
                                                                                {
                                                                                    value: `GSTINNo`,
                                                                                    label: 'GSTIN Number',
                                                                                },
                                                                            ]}
                                                                            details={item}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                            <DataList
                                                                fields={[
                                                                    {
                                                                        value: `ITR.${itrName}.ScheduleBP.PersumptiveInc44AD.GrsTotalTrnOver`,
                                                                        label: 'Gross Total Turnover',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.ScheduleBP.PersumptiveInc44AD.GrsTrnOverAnyOthMode`,
                                                                        label: 'Gross Turnover Any Other Mode',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.ScheduleBP.PersumptiveInc44AD.PersumptiveInc44AD8Per`,
                                                                        label: 'Presumptive Income 44AD (8%)',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.ScheduleBP.PersumptiveInc44AD.TotPersumptiveInc44AD`,
                                                                        label: 'Total Presumptive Income 44AD',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.ScheduleBP.TurnoverGrsRcptForGSTIN.AmtTurnGrossRcptGSTIN`,
                                                                        label: 'Amount Turnover Gross Receipt GSTIN',
                                                                        transform: 'amount',
                                                                    },
                                                                ]}
                                                                details={row}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <div className='card mt-5'>
                                                    <div className='card-header'>
                                                        <div className='card-title'>
                                                            Address Details
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DataList
                                                            details={
                                                                itrRow?.PartA_GEN1?.PersonalInfo ||
                                                                itrRow?.PersonalInfo
                                                            }
                                                            fields={[
                                                                {
                                                                    value: `Address.ResidenceNo`,
                                                                    label: 'Residence Number',
                                                                },
                                                                {
                                                                    value: `Address.ResidenceName`,
                                                                    label: 'Residence Name',
                                                                },
                                                                {
                                                                    value: `Address.RoadOrStreet`,
                                                                    label: 'Street Name',
                                                                },
                                                                {
                                                                    value: `Address.LocalityOrArea`,
                                                                    label: 'Locality',
                                                                },
                                                                {
                                                                    value: `Address.CityOrTownOrDistrict`,
                                                                    label: 'City/Town/District',
                                                                },
                                                                {
                                                                    value: `Address.StateCode`,
                                                                    label: 'State Code',
                                                                },
                                                                {
                                                                    value: `Address.PinCode`,
                                                                    label: 'Pincode',
                                                                },
                                                                {
                                                                    value: [
                                                                        `Address.CountryCodeMobile`,
                                                                        `Address.MobileNo`,
                                                                    ],
                                                                    delimeter: '-',
                                                                    label: 'Mobile Number',
                                                                },
                                                                {
                                                                    value: `Address.EmailAddress`,
                                                                    label: 'E-Mail',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='card mt-5'>
                                                    <div className='card-header'>
                                                        <div className='card-title'>
                                                            Verification
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DataList
                                                            fields={[
                                                                {
                                                                    value: `ITR.${itrName}.Verification.Declaration.AssesseeVerName`,
                                                                    label: 'Assessee Verification Name',
                                                                },
                                                                {
                                                                    value: `ITR.${itrName}.Verification.Declaration.FatherName`,
                                                                    label: "Father's Name",
                                                                },
                                                                {
                                                                    value: `ITR.${itrName}.Verification.Declaration.AssesseeVerPAN`,
                                                                    label: 'Assessee Verification PAN',
                                                                },
                                                                {
                                                                    value: `ITR.${itrName}.Verification.Capacity`,
                                                                    label: 'Capacity',
                                                                },
                                                                {
                                                                    value: `ITR.${itrName}.Verification.Place`,
                                                                    label: 'Place',
                                                                },
                                                            ]}
                                                            details={row}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                {itrRow?.IncomeDeductions && (
                                                    <div className='card'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Income Details
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                details={itrRow?.IncomeDeductions}
                                                                fields={[
                                                                    {
                                                                        value: `GrossSalary`,
                                                                        label: 'Gross Salary',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `Salary`,
                                                                        label: 'Salary',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `PerquisitesValue`,
                                                                        label: 'Perquisites Value',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ProfitsInSalary`,
                                                                        label: 'Profits in Salary',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `IncomeNotified89A`,
                                                                        label: 'Income Notified 89A',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `NetSalary`,
                                                                        label: 'Net Salary',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `DeductionUs16`,
                                                                        label: 'Deduction U/S 16',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `DeductionUs16ia`,
                                                                        label: 'Deduction U/S 16(ia)',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `EntertainmntalwncUs16ii`,
                                                                        label: 'Entertainment Allowance U/S 16(ii)',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ProfessionalTaxUs16iii`,
                                                                        label: 'Professional Tax U/S 16(iii)',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `IncomeFromSal`,
                                                                        label: 'Income from Salary',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `TypeOfHP`,
                                                                        label: 'Type of House Property',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `AnnualValue`,
                                                                        label: 'Annual Value',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `AnnualValue30Percent`,
                                                                        label: 'Annual Value 30 Percent',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `InterestPayable`,
                                                                        label: 'Interest Payable',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `TotalIncomeOfHP`,
                                                                        label: 'Total Income of House Property',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `IncomeOthSrc`,
                                                                        label: 'Income from Other Sources',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `DeductionUs57iia`,
                                                                        label: 'Deduction U/S 57(iia)',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `Increliefus89AOS`,
                                                                        label: 'Income Relief U/S 89A OS',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `GrossTotIncome`,
                                                                        label: 'Gross Total Income',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `TotalIncome`,
                                                                        label: 'Total Income',
                                                                        transform: 'amount',
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {(itrRow?.ScheduleS || itrRow?.['PartB-TI']) && (
                                                    <>
                                                        <div className='card'>
                                                            <div className='card-header'>
                                                                <div className='card-title'>
                                                                    Income Details
                                                                </div>
                                                            </div>
                                                            <div className='card-body'>
                                                                <DataList
                                                                    details={{
                                                                        ...itrRow?.ScheduleS,
                                                                        ...itrRow?.['PartB-TI'],
                                                                    }}
                                                                    fields={[
                                                                        {
                                                                            value: `TotalGrossSalary`,
                                                                            label: 'Gross Salary',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `NetSalary`,
                                                                            label: 'Net Salary',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `DeductionUS16`,
                                                                            label: 'Deduction U/S 16',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `EntertainmntalwncUs16ii`,
                                                                            label: 'Entertainment Allowance U/S 16(ii)',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `ProfessionalTaxUs16iii`,
                                                                            label: 'Professional Tax U/S 16(iii)',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `GrossTotalIncome`,
                                                                            label: 'Gross Total Income',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            value: `TotalIncome`,
                                                                            label: 'Total Income',
                                                                            transform: 'amount',
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        {itrRow?.ScheduleS?.Salaries?.map(
                                                            (salary) => {
                                                                return (
                                                                    <div className='card mt-5'>
                                                                        <div className='card-header'>
                                                                            <div className='card-title'>
                                                                                {
                                                                                    salary?.NameOfEmployer
                                                                                }
                                                                                (
                                                                                {
                                                                                    salary?.TANofEmployer
                                                                                }
                                                                                )
                                                                            </div>
                                                                        </div>
                                                                        <div className='card-body'>
                                                                            <h5>
                                                                                Employer Details
                                                                            </h5>
                                                                            <DataList
                                                                                fields={[
                                                                                    {
                                                                                        value: 'AddrDetail',
                                                                                        label: 'Address Line',
                                                                                    },
                                                                                    {
                                                                                        value: 'CityOrTownOrDistrict',
                                                                                        label: 'City/Town/District',
                                                                                    },
                                                                                    {
                                                                                        value: 'StateCode',
                                                                                        label: 'State Code',
                                                                                    },
                                                                                    {
                                                                                        value: 'PinCode',
                                                                                        label: 'Pincode',
                                                                                    },
                                                                                ]}
                                                                                details={
                                                                                    salary.AddressDetail
                                                                                }
                                                                            />
                                                                            <div className='separator my-3'></div>
                                                                            <h5>Salary Details</h5>
                                                                            <DataList
                                                                                fields={[
                                                                                    {
                                                                                        value: 'GrossSalary',
                                                                                        label: 'Gross Salary',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'Salary',
                                                                                        label: 'Salary',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'ValueOfPerquisites',
                                                                                        label: 'Value of Perquisites',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'ProfitsinLieuOfSalary',
                                                                                        label: 'Profits in Lieu of Salary',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'IncomeNotified89A',
                                                                                        label: 'Income Notified (89A)',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'IncomeNotifiedOther89A',
                                                                                        label: 'Income Notified Other(89A)',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                    {
                                                                                        value: 'IncomeNotifiedPrYr89A',
                                                                                        label: 'IncomeNotified Pr Yr (89A)',
                                                                                        transform:
                                                                                            'amount',
                                                                                    },
                                                                                ]}
                                                                                details={
                                                                                    salary.Salarys
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                                <div className='card mt-5'>
                                                    <div className='card-header'>
                                                        <div className='card-title'>Deductions</div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DataList
                                                            details={
                                                                itrRow?.IncomeDeductions
                                                                    ?.UsrDeductUndChapVIA ||
                                                                itrRow?.ScheduleVIA
                                                                    ?.UsrDeductUndChapVIA
                                                            }
                                                            fields={[
                                                                {
                                                                    value: `Section80C`,
                                                                    label: 'Section 80C',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80CCC`,
                                                                    label: 'Section 80CCC',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80CCDEmployeeOrSE`,
                                                                    label: 'Section 80CCD Employee or SE',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80CCD1B`,
                                                                    label: 'Section 80CCD 1B',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80CCDEmployer`,
                                                                    label: 'Section 80CCD Employer',
                                                                },
                                                                {
                                                                    value: `Section80D`,
                                                                    label: 'Section 80D',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80DD`,
                                                                    label: 'Section 80DD',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80DDB`,
                                                                    label: 'Section 80DDB',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80E`,
                                                                    label: 'Section 80E',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80EE`,
                                                                    label: 'Section 80EE',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80EEA`,
                                                                    label: 'Section 80EEA',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80EEB`,
                                                                    label: 'Section 80EEB',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80G`,
                                                                    label: 'Section 80G',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80GG`,
                                                                    label: 'Section 80GG',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80GGC`,
                                                                    label: 'Section 80GGC',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80U`,
                                                                    label: 'Section 80U',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80TTA`,
                                                                    label: 'Section 80TTA',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `Section80TTB`,
                                                                    label: 'Section 80TTB',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `AnyOthSec80CCH`,
                                                                    label: 'Any Other Section 80CCH',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `TotalChapVIADeductions`,
                                                                    label: 'Total Chapter VIA Deductions',
                                                                    transform: 'amount',
                                                                },
                                                                {
                                                                    value: `TotPartCAandDchapterVIA`,
                                                                    label: 'Total Part C, A and D Chapter VIA',
                                                                    transform: 'amount',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                {itrRow?.TaxComputation && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Tax Computations
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                fields={[
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.TotalTaxPayable`,
                                                                        label: 'Total Tax Payable',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.Rebate87A`,
                                                                        label: 'Rebate 87A',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.TaxPayableOnRebate`,
                                                                        label: 'Tax Payable on Rebate',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.EducationCess`,
                                                                        label: 'Education Cess',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.GrossTaxLiability`,
                                                                        label: 'Gross Tax Liability',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.Section89`,
                                                                        label: 'Section 89',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.NetTaxLiability`,
                                                                        label: 'Net Tax Liability',
                                                                        transform: 'amount',
                                                                    },
                                                                    {
                                                                        value: `ITR.${itrName}.TaxComputation.TotTaxPlusIntrstPay`,
                                                                        label: 'Total Tax Plus Interest Payable',
                                                                        transform: 'amount',
                                                                    },
                                                                ]}
                                                                details={row}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {itrRow?.PartB_TTI?.ComputationOfTaxLiability && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Tax Computations
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                details={
                                                                    itrRow?.PartB_TTI
                                                                        ?.ComputationOfTaxLiability
                                                                }
                                                                fields={[
                                                                    {
                                                                        value: 'TaxPayableOnTI.TaxAtNormalRatesOnAggrInc',
                                                                        transform: 'amount',
                                                                        label: 'Tax at Normal Rates on Aggregate Income',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.TaxAtSpecialRates',
                                                                        transform: 'amount',
                                                                        label: 'Tax at Special Rates',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.RebateOnAgriInc',
                                                                        transform: 'amount',
                                                                        label: 'Rebate on Agricultural Income',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.TaxPayableOnTotInc',
                                                                        transform: 'amount',
                                                                        label: 'Tax Payable on Total Income',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.Rebate87A',
                                                                        transform: 'amount',
                                                                        label: 'Rebate under Section 87A',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.TaxPayableOnRebate',
                                                                        transform: 'amount',
                                                                        label: 'Tax Payable After Rebate',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.TotalSurcharge',
                                                                        transform: 'amount',
                                                                        label: 'Total Surcharge',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.Surcharge25ofSI',
                                                                        transform: 'amount',
                                                                        label: 'Surcharge (25% of Specified Income)',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.EducationCess',
                                                                        transform: 'amount',
                                                                        label: 'Education Cess',
                                                                    },
                                                                    {
                                                                        value: 'TaxPayableOnTI.GrossTaxLiability',
                                                                        transform: 'amount',
                                                                        label: 'Gross Tax Liability',
                                                                    },
                                                                    {
                                                                        value: 'NetTaxLiability',
                                                                        transform: 'amount',
                                                                        label: 'Net Tax Liability',
                                                                    },
                                                                    {
                                                                        value: 'GrossTaxPayable',
                                                                        transform: 'amount',
                                                                        label: 'Gross Tax Payable',
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {itrRow?.PartB_TTI?.TaxPaid && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Tax Paid
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                details={itrRow?.PartB_TTI?.TaxPaid}
                                                                fields={[
                                                                    {
                                                                        value: `TaxesPaid.AdvanceTax`,
                                                                        transform: 'amount',
                                                                        label: 'Advance Tax',
                                                                    },
                                                                    {
                                                                        value: `TaxesPaid.TDS`,
                                                                        transform: 'amount',
                                                                        label: 'TDS',
                                                                    },
                                                                    {
                                                                        value: `TaxesPaid.TCS`,
                                                                        transform: 'amount',
                                                                        label: 'TCS',
                                                                    },
                                                                    {
                                                                        value: `TaxesPaid.SelfAssessmentTax`,
                                                                        transform: 'amount',
                                                                        label: 'Self Assessment Tax',
                                                                    },
                                                                    {
                                                                        value: `TaxesPaid.TotalTaxesPaid`,
                                                                        transform: 'amount',
                                                                        label: 'Total Tax Paid',
                                                                    },
                                                                    {
                                                                        value: `BalTaxPayable`,
                                                                        transform: 'amount',
                                                                        label: 'Balance Tax Payable',
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {row?.ITR?.[itrName]?.Refund && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Refunds
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                fields={[
                                                                    {
                                                                        value: `ITR.${itrName}.Refund.RefundDue`,
                                                                        label: 'Refund Due',
                                                                        transform: 'amount',
                                                                    },
                                                                ]}
                                                                details={row}
                                                            />
                                                            {row?.ITR?.[
                                                                itrName
                                                            ]?.Refund?.BankAccountDtls?.AddtnlBankDetails?.map(
                                                                (businessRow) => {
                                                                    return (
                                                                        <DataList
                                                                            fields={[
                                                                                {
                                                                                    value: `IFSCCode`,
                                                                                    label: 'IFSC Code',
                                                                                },
                                                                                {
                                                                                    value: `BankName`,
                                                                                    label: 'Bank Name',
                                                                                },
                                                                                {
                                                                                    value: `BankAccountNo`,
                                                                                    label: 'Bank Account Number',
                                                                                },
                                                                                {
                                                                                    value: `AccountType`,
                                                                                    label: 'Account Type',
                                                                                },
                                                                            ]}
                                                                            details={businessRow}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {itrRow?.PartB_TTI?.Refund && (
                                                    <div className='card mt-5'>
                                                        <div className='card-header'>
                                                            <div className='card-title'>
                                                                Refunds
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <DataList
                                                                details={itrRow?.PartB_TTI?.Refund}
                                                                fields={[
                                                                    {
                                                                        value: `RefundDue`,
                                                                        label: 'Refund Due',
                                                                        transform: 'amount',
                                                                    },
                                                                ]}
                                                            />
                                                            {itrRow?.PartB_TTI?.Refund?.BankAccountDtls?.AddtnlBankDetails?.map(
                                                                (businessRow) => {
                                                                    return (
                                                                        <DataList
                                                                            fields={[
                                                                                {
                                                                                    value: `IFSCCode`,
                                                                                    label: 'IFSC Code',
                                                                                },
                                                                                {
                                                                                    value: `BankName`,
                                                                                    label: 'Bank Name',
                                                                                },
                                                                                {
                                                                                    value: `BankAccountNo`,
                                                                                    label: 'Bank Account Number',
                                                                                },
                                                                                {
                                                                                    value: `AccountType`,
                                                                                    label: 'Account Type',
                                                                                },
                                                                            ]}
                                                                            details={businessRow}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
        </>
    );
};
export default SignzyItrPullAnalytics;
