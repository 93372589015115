import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import DataList from '../../../components/organisms/DataList';
import { date } from '../../../helpers/datetime';
import { amount, beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { getWarehouseDetails } from '../_rego.service';
import DocumentDownload from '../Documents/DocumentDownload';

const FinboxBankSummary = () => {
    const { report_id } = useParams();
    const [searchParams] = useSearchParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        setReport(data);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, [report_id]);
    return (
        <>
            {searchParams.get('xlsx_id') && (
                <div className='mb-2 text-end'>
                    <DocumentDownload
                        config={{
                            label: 'Download Report',
                            position: 'start',
                        }}
                        documents={[
                            {
                                storage_id: searchParams.get('xlsx_id'),
                            },
                        ]}
                    />
                </div>
            )}
            {report?.accounts?.map((account, key) => {
                return (
                    <div
                        className='card'
                        key={account?.data?.account_details?.account_number || `account_${key}`}>
                        <div className='card-header'>
                            <div className='card-title'>
                                {account?.data?.account_details?.ifsc} -
                                {account?.data?.account_details?.account_number} (
                                {account?.data?.account_details?.bank} )
                            </div>
                            <h3 className='card-toolbar'>
                                {date(
                                    account?.data?.account_details?.transaction_date_range
                                        ?.from_date
                                )}{' '}
                                to{' '}
                                {date(
                                    account?.data?.account_details?.transaction_date_range?.to_date
                                )}
                            </h3>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>Account Details</h4>
                                    <DataList
                                        details={account?.data?.account_details}
                                        fields={[
                                            'name',
                                            'account_number',
                                            'bank',
                                            'ifsc',
                                            'micr',
                                            'account_category',
                                            'account_opening_date',
                                            'credit_limit',
                                            'od_limit',
                                            'dob',
                                            'email',
                                            'pan_number',
                                            'phone_number',
                                            'address',
                                        ]}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <h4>Observations</h4>
                                    <DataList
                                        details={account?.data?.predictors}
                                        fields={[
                                            { value: 'credits', transform: 'amount' },
                                            { value: 'debits', transform: 'amount' },
                                            { value: 'total_income', transform: 'amount' },
                                            { value: 'total_expense', transform: 'amount' },
                                            {
                                                value: 'avg_bank_balance',
                                                label: 'Average Bank Balance',
                                            },
                                            {
                                                value: 'avg_business_credit_per_month',
                                                label: 'Average Business Credits per Month',
                                                transform: 'amount',
                                            },
                                            {
                                                value: 'avg_daily_closing_balance',
                                                transform: 'amount',
                                            },
                                            {
                                                value: 'avg_balance_of_5th_15th_20th_25th_30th_of_last_6_months',
                                                label: 'Average Balance on 5/15/20/25/30th of last 6 months',
                                                transform: 'amount',
                                            },
                                            { value: 'total_cash_withdrawal', transform: 'amount' },
                                            { value: 'cash_withdrawals', transform: 'amount' },
                                            { value: 'author_fraud', transform: 'amount' },
                                            { value: 'date_fraud', transform: 'amount' },
                                            { value: 'transaction_fraud', transform: 'amount' },
                                            { value: 'transaction_fraud_v2', transform: 'amount' },
                                            {
                                                value: 'inconsistent_transaction',
                                                transform: 'amount',
                                            },
                                            {
                                                value: 'more_cash_deposits_than_salary',
                                                transform: 'amount',
                                            },
                                            {
                                                value: 'salary_remains_unchanged',
                                                transform: 'amount',
                                            },
                                            { value: 'salary_1000_multiple', transform: 'amount' },
                                            {
                                                value: 'mostly_cash_transactions',
                                                transform: 'amount',
                                            },
                                            { value: 'equal_credit_debit', transform: 'amount' },
                                            { value: 'negative_balance', transform: 'amount' },
                                            {
                                                value: 'cash_deposit_bank_holiday_transaction',
                                                label: 'Cash deposit on Bank Holiday',
                                                transform: 'amount',
                                            },
                                            {
                                                value: 'chq_bank_holiday_transaction',
                                                label: 'Cheque transactions on Bank Holiday',
                                                transform: 'amount',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <Accordion>
                                {['debit_transactions', 'credit_transactions'].map((type) => {
                                    return (
                                        <Accordion.Item
                                            eventKey={type}
                                            key={`recurring_${type}`}>
                                            <Accordion.Header>
                                                <h4 className='m-0'>Recurring {beautify(type)}</h4>
                                            </Accordion.Header>
                                            <Accordion.Body className='p-0'>
                                                <table className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                                    <thead>
                                                        <tr>
                                                            <th className='fw-bold'>
                                                                Transaction Channel
                                                            </th>
                                                            <th className='fw-bold'>
                                                                Amount (Median)
                                                            </th>
                                                            <th className='fw-bold'>
                                                                Transaction Note
                                                            </th>
                                                            <th className='fw-bold'>Start Date</th>
                                                            <th className='fw-bold'>End Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {account?.data?.recurring_transactions?.[
                                                            type
                                                        ]?.map((row, rowIndex) => {
                                                            return (
                                                                <tr
                                                                    key={`${type}_${rowIndex}_${row.clean_transaction_note}`}>
                                                                    <td>
                                                                        {row.transaction_channel}
                                                                    </td>
                                                                    <td
                                                                        className={clsx(
                                                                            'fw-bold fs-5',
                                                                            {
                                                                                'text-danger':
                                                                                    type ===
                                                                                    'debit_transactions',
                                                                                'text-success':
                                                                                    type ===
                                                                                    'credit_transactions',
                                                                            }
                                                                        )}>
                                                                        {amount(row.median)}
                                                                    </td>
                                                                    <td>
                                                                        {row.clean_transaction_note}
                                                                    </td>
                                                                    <td>{date(row.start_date)}</td>
                                                                    <td>{date(row.end_date)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                                {account?.data?.top_credits_debits?.map((credits_debits, index) => {
                                    return (
                                        <Accordion.Item
                                            eventKey={credits_debits.type}
                                            key={`credits_debits_${credits_debits?.type}_${index}`}>
                                            <Accordion.Header>
                                                <h4 className='m-0'>
                                                    {beautify(credits_debits?.type)}
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body className='p-0'>
                                                <table className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                                    <thead>
                                                        <tr>
                                                            <th>Month</th>
                                                            <th>Amount</th>
                                                            <th>Category</th>
                                                            <th>Transaction Channel</th>
                                                            <th>Date</th>
                                                            <th>Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {credits_debits?.data?.map(
                                                            (row, rowIndex) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={`${credits_debits?.type}_${row.month}_${rowIndex}`}>
                                                                        <tr>
                                                                            <td className='fw-bold'>
                                                                                {row.month}
                                                                            </td>
                                                                        </tr>
                                                                        {row?.data?.map(
                                                                            (item, itemIndex) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={`${credits_debits?.type}_${row.month}_${item.transaction_note}_${itemIndex}`}>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {amount(
                                                                                                item.amount
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                item.category
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                item
                                                                                                    .metadata
                                                                                                    .transaction_channel
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                item.date
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {amount(
                                                                                                item.balance
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                                <Accordion.Item eventKey='monthly_analysis'>
                                    <Accordion.Header>
                                        <h4 className='m-0'>Monthly Analysis</h4>
                                    </Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <table className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                            <thead>
                                                <tr className='bg-dark text-white'>
                                                    <td></td>
                                                    {Object.keys(
                                                        Object.values(
                                                            account?.data?.monthly_analysis
                                                        )?.[0]
                                                    ).map((month, monthIndex) => {
                                                        return (
                                                            <td
                                                                className='fw-bold'
                                                                key={`monthly_analysis_${month}_${monthIndex}`}>
                                                                {month}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(
                                                    account?.data?.monthly_analysis
                                                ).map(([type, items]) => {
                                                    // console.log(type, items);
                                                    return (
                                                        <tr key={`${type}`}>
                                                            <td>{beautify(type)}</td>
                                                            {Object.values(items)?.map(
                                                                (item, itemKey) => {
                                                                    return (
                                                                        <td
                                                                            key={`${type}_${itemKey}`}>
                                                                            {typeof item !==
                                                                            'object'
                                                                                ? item
                                                                                : ''}
                                                                        </td>
                                                                    );
                                                                }
                                                            )}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='month_on_month'>
                                    <Accordion.Header>
                                        <h4 className='m-0'>Month on Month Details</h4>
                                    </Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <table className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                            <tbody>
                                                {[
                                                    { key: 'month', label: 'Month' },
                                                    {
                                                        key: 'total_salary',
                                                        label: 'Total Salary',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'income',
                                                        label: 'Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'debits',
                                                        label: 'Debits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'credits',
                                                        label: 'Credits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'expense',
                                                        label: 'Expense',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'avg_balance',
                                                        label: 'Average Balance',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'total_inward_chq_bounces_insuff_fund',
                                                        label: 'Total Inward Cheque Bounces Insufficient Fund',
                                                    },
                                                    {
                                                        key: 'debitless_charges_fund',
                                                        label: 'Debitless Charges Fund',
                                                    },
                                                    {
                                                        key: 'bal_last',
                                                        label: 'Balance Last',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'expense_to_income_ratio',
                                                        label: 'Expense To Income Ratio',
                                                    },
                                                    {
                                                        key: 'balance_on_1st',
                                                        label: 'Balance On 1st',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_5th',
                                                        label: 'Balance On 5th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_10th',
                                                        label: 'Balance On 10th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_15th',
                                                        label: 'Balance On 15th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_20th',
                                                        label: 'Balance On 20th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_25th',
                                                        label: 'Balance On 25th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_on_30th',
                                                        label: 'Balance On 30th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_1st',
                                                        label: 'Balance Net Off On 1st',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_5th',
                                                        label: 'Balance Net Off On 5th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_10th',
                                                        label: 'Balance Net Off On 10th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_15th',
                                                        label: 'Balance Net Off On 15th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_20th',
                                                        label: 'Balance Net Off On 20th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_25th',
                                                        label: 'Balance Net Off On 25th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'balance_net_off_on_30th',
                                                        label: 'Balance Net Off On 30th',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'avg_balance_without_loan_credit_multipleof5',
                                                        label: 'Average Balance Without Loan Credit Multiple Of 5',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'max_balance',
                                                        label: 'Max Balance',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'min_balance',
                                                        label: 'Min Balance',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'total_inward_payment_bounce',
                                                        label: 'Total Inward Payment Bounce',
                                                    },
                                                    {
                                                        key: 'total_outward_chq_bounces_insuff_fund',
                                                        label: 'Total Outward Cheque Bounces Insufficient Fund',
                                                    },
                                                    { key: 'cnt_income', label: 'Count Income' },
                                                    {
                                                        key: 'total_emi_bounce',
                                                        label: 'Total EMI Bounce',
                                                    },
                                                    {
                                                        key: 'number_of_transactions',
                                                        label: 'Number Of Transactions',
                                                    },
                                                    {
                                                        key: 'inward_chq_bounces_insuff_fund',
                                                        label: 'Inward Cheque Bounces Insufficient Fund',
                                                    },
                                                    {
                                                        key: 'outward_chq_bounces_insuff_fund',
                                                        label: 'Outward Cheque Bounces Insufficient Fund',
                                                    },
                                                    {
                                                        key: 'amt_loan_credits',
                                                        label: 'Amount Loan Credits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'chq_dep_isto_chq_issues',
                                                        label: 'Cheque Deposit To Cheque Issues',
                                                    },
                                                    {
                                                        key: 'avg_balance_without_loan_credit_adjusted',
                                                        label: 'Average Balance Without Loan Credit Adjusted',
                                                        transform: 'amount',
                                                    },
                                                    { key: 'emi_bounce', label: 'EMI Bounce' },
                                                    {
                                                        key: 'total_creditcard_payment',
                                                        label: 'Total Credit Card Payment',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_credits_wo_bounce',
                                                        label: 'Amount Credits Without Bounce',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_credits_wo_bounce',
                                                        label: 'Count Credits Without Bounce',
                                                    },
                                                    {
                                                        key: 'amt_debits_wo_bounce',
                                                        label: 'Amount Debits Without Bounce',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_upi_credits',
                                                        label: 'Amount UPI Credits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_upi_debits',
                                                        label: 'Amount UPI Debits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_upi_credits',
                                                        label: 'Count UPI Credits',
                                                    },
                                                    {
                                                        key: 'cnt_upi_debits',
                                                        label: 'Count UPI Debits',
                                                    },
                                                    {
                                                        key: 'amt_minimum_balance_chrg',
                                                        label: 'Amount Minimum Balance Charge',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_minimum_balance_chrg',
                                                        label: 'Count Minimum Balance Charge',
                                                    },
                                                    {
                                                        key: 'amt_cash_deposit',
                                                        label: 'Amount Cash Deposit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_cash_deposit',
                                                        label: 'Count Cash Deposit',
                                                    },
                                                    {
                                                        key: 'amt_business_credit',
                                                        label: 'Amount Business Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'perc_salary_amt_debit_within5_days',
                                                        label: 'Percentage Salary Amount Debit Within 5 Days',
                                                    },
                                                    {
                                                        key: 'cnt_eod_bal_lt_500',
                                                        label: 'Count EOD Balance Less Than 500',
                                                    },
                                                    {
                                                        key: 'cnt_debit_transaction',
                                                        label: 'Count Debit Transaction',
                                                    },
                                                    {
                                                        key: 'min_eod_bal_2_10_15_24_28th_day',
                                                        label: 'Minimum EOD Balance On 2/10/15/24/28th Day',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_cash_withdrawl_debit',
                                                        label: 'Count Cash Withdrawal Debit',
                                                    },
                                                    {
                                                        key: 'cnt_times_account_overdrawn',
                                                        label: 'Count Times Account Overdrawn',
                                                    },
                                                    {
                                                        key: 'cnt_credit_transaction',
                                                        label: 'Count Credit Transaction',
                                                    },
                                                    {
                                                        key: 'cnt_revolving_transaction',
                                                        label: 'Count Revolving Transaction',
                                                    },
                                                    {
                                                        key: 'amt_revolving_transaction',
                                                        label: 'Amount Revolving Transaction',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_emi_bounce_tech_reason_credit',
                                                        label: 'Count EMI Bounce Technical Reason Credit',
                                                    },
                                                    {
                                                        key: 'amt_emi_bounce_tech_reason_credit',
                                                        label: 'Amount EMI Bounce Technical Reason Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_emi_bounce_non_tech_reason_credit',
                                                        label: 'Count EMI Bounce Non-Technical Reason Credit',
                                                    },
                                                    {
                                                        key: 'amt_emi_bounce_non_tech_reason_credit',
                                                        label: 'Amount EMI Bounce Non-Technical Reason Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_inward_cheque_bounce_tech_reason_credit',
                                                        label: 'Amount Inward Cheque Bounce Technical Reason Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_inward_cheque_bounce_tech_reason_credit',
                                                        label: 'Count Inward Cheque Bounce Technical Reason Credit',
                                                    },
                                                    {
                                                        key: 'amt_inward_cheque_bounce_non_tech_reason_credit',
                                                        label: 'Amount Inward Cheque Bounce Non-Technical Reason Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_inward_cheque_bounce_non_tech_reason_credit',
                                                        label: 'Count Inward Cheque Bounce Non-Technical Reason Credit',
                                                    },
                                                    {
                                                        key: 'max_overdraft_utilization',
                                                        label: 'Max Overdraft Utilization',
                                                    },
                                                    {
                                                        key: 'avg_amt_overdraft_utilization',
                                                        label: 'Average Amount Overdraft Utilization',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'bal_avgof_6dates',
                                                        label: 'Balance Average Of 6 Dates',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_emi_debit',
                                                        label: 'Count EMI Debit',
                                                    },
                                                    {
                                                        key: 'amt_credit_excluding_loan_credit_and_self_credit',
                                                        label: 'Amount Credit Excluding Loan Credit And Self Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_credit_excluding_loan_credit_and_self_credit',
                                                        label: 'Count Credit Excluding Loan Credit And Self Credit',
                                                    },
                                                    {
                                                        key: 'cnt_days_account_overdrawn',
                                                        label: 'Count Days Account Overdrawn',
                                                    },
                                                    {
                                                        key: 'employer_name',
                                                        label: 'Employer Name',
                                                    },
                                                    {
                                                        key: 'cnt_business_credit',
                                                        label: 'Count Business Credit',
                                                    },
                                                    { key: 'cnt_salary', label: 'Count Salary' },
                                                    { key: 'obligation', label: 'Obligation' },
                                                    {
                                                        key: 'avg_amt_overdraft_utilization_perc',
                                                        label: 'Average Amount Overdraft Utilization Percentage',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'number_of_days_account_overdrawn',
                                                        label: 'Number Of Days Account Overdrawn',
                                                    },
                                                    {
                                                        key: 'number_of_days_cc_limit_breached',
                                                        label: 'Number Of Days Credit Card Limit Breached',
                                                    },
                                                    {
                                                        key: 'number_of_days_cc_limit_breached_and_chq_bounced',
                                                        label: 'Number Of Days Credit Card Limit Breached And Cheque Bounced',
                                                    },
                                                    {
                                                        key: 'amt_netting_credits',
                                                        label: 'Amount Netting Credits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_netting_credits',
                                                        label: 'Count Netting Credits',
                                                    },
                                                    {
                                                        key: 'amt_netting_debits',
                                                        label: 'Amount Netting Debits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_netting_debits',
                                                        label: 'Count Netting Debits',
                                                    },
                                                    {
                                                        key: 'cnt_loan_credits',
                                                        label: 'Count Loan Credits',
                                                    },
                                                    {
                                                        key: 'max_amount_loan_credits',
                                                        label: 'Max Amount Loan Credits',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_loan_credits_lt_100k',
                                                        label: 'Count Loan Credits Less Than 100k',
                                                    },
                                                    { key: 'salary_date', label: 'Salary Date' },
                                                    {
                                                        key: 'amt_self_transfer_credit',
                                                        label: 'Amount Self Transfer Credit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_self_transfer_credit',
                                                        label: 'Count Self Transfer Credit',
                                                    },
                                                    {
                                                        key: 'cnt_loan_credits_above_5k',
                                                        label: 'Count Loan Credits Above 5k',
                                                    },
                                                    {
                                                        key: 'cnt_loan_credits_above_10k',
                                                        label: 'Count Loan Credits Above 10k',
                                                    },
                                                    {
                                                        key: 'amt_business_debit',
                                                        label: 'Amount Business Debit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_business_debit',
                                                        label: 'Count Business Debit',
                                                    },
                                                    {
                                                        key: 'amt_self_transfer_debit',
                                                        label: 'Amount Self Transfer Debit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_self_transfer_debit',
                                                        label: 'Count Self Transfer Debit',
                                                    },
                                                    {
                                                        key: 'amt_cash_withdrawl_debit',
                                                        label: 'Amount Cash Withdrawal Debit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_emi_debit',
                                                        label: 'Amount EMI Debit',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_entertainment',
                                                        label: 'Amount Debit Entertainment',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_fuel',
                                                        label: 'Amount Debit Fuel',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_medical',
                                                        label: 'Amount Debit Medical',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_online_shopping',
                                                        label: 'Amount Debit Online Shopping',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_alcohol',
                                                        label: 'Amount Debit Alcohol',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_debit_mobile_bill',
                                                        label: 'Amount Debit Mobile Bill',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'amt_credits_lt_10k',
                                                        label: 'Amount Credits Less Than 10k',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        key: 'cnt_days_credits',
                                                        label: 'Count Days Credits',
                                                    },
                                                    {
                                                        key: 'cnt_emi_ach_bounce_charge',
                                                        label: 'Count EMI ACH Bounce Charge',
                                                    },
                                                    {
                                                        key: 'amt_emi_ach_bounce_charge_1',
                                                        label: 'Amount EMI ACH Bounce Charge 1',
                                                        transform: 'amount',
                                                    },
                                                ].map((row, index) => {
                                                    return (
                                                        <tr key={`${row?.key}_index`}>
                                                            <td
                                                                className={clsx({
                                                                    'bg-dark text-white':
                                                                        row.key === 'month',
                                                                })}>
                                                                {row.label}
                                                            </td>
                                                            {Array.from({ length: 12 }, (_, i) => (
                                                                <React.Fragment
                                                                    key={`${row.key}_${i}`}>
                                                                    {account?.data?.predictors?.[
                                                                        `month_${i}`
                                                                    ] && (
                                                                        <td
                                                                            className={clsx({
                                                                                'bg-dark text-white':
                                                                                    row.key ===
                                                                                    'month',
                                                                            })}>
                                                                            {row.transform ? (
                                                                                transform(
                                                                                    row.transform,
                                                                                    account?.data
                                                                                        ?.predictors?.[
                                                                                        `${row.key}_${i}`
                                                                                    ]
                                                                                )
                                                                            ) : (
                                                                                <span
                                                                                    className={clsx(
                                                                                        {
                                                                                            'badge badge-primary':
                                                                                                row.key.indexOf(
                                                                                                    'cnt'
                                                                                                ) !==
                                                                                                -1,
                                                                                        }
                                                                                    )}>
                                                                                    {
                                                                                        account
                                                                                            ?.data
                                                                                            ?.predictors?.[
                                                                                            `${row.key}_${i}`
                                                                                        ]
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='transactions'>
                                    <Accordion.Header>
                                        <h4 className='m-0'>Transactions</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <table className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                            <thead>
                                                <tr className='bg-dark fw-bold text-white'>
                                                    <th>Date</th>
                                                    <th>Transaction Note</th>
                                                    <th>Debit Amount</th>
                                                    <th>Credit Amount</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {account?.data?.transactions?.map((transaction) => {
                                                    return (
                                                        <tr key={transaction?.chq_num}>
                                                            <td>{date(transaction?.date)}</td>
                                                            <td>{transaction?.transaction_note}</td>
                                                            <td>
                                                                {transaction?.transaction_type ===
                                                                'debit'
                                                                    ? amount(transaction?.amount)
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                {transaction?.transaction_type ===
                                                                'credit'
                                                                    ? amount(transaction?.amount)
                                                                    : ''}
                                                            </td>
                                                            <td>{amount(transaction?.balance)}</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr></tr>
                                            </tbody>
                                        </table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
export default FinboxBankSummary;
