import { amount } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import {
    collateralTypes,
    loanOwnership,
    loanType,
    months,
    paymentFrequency,
} from './_cibil-report.consts';

const CibilAccounts = ({ details }) => {
    const parseDpds = (history1, startDate) => {
        startDate = transform('date', startDate, 'YYYY-MM-DD', 'DDMMYYYY');
        let history = history1;
        if (!history) {
            return [];
        }
        const dpds = [];
        for (let i = 0; i < history.length; i += 3) {
            const value = history.substr(i, 3);
            const date = new Date(
                new Date(startDate).setMonth(new Date(startDate).getMonth() - i / 3)
            );
            dpds.push({
                value,
                month: months[date.getMonth()],
                year: date.getFullYear(),
                class: parseInt(value, 10) > 0 ? 'text-danger kt-font-boldest' : 'text-default',
            });
        }
        return dpds;
    };

    return (
        <>
            {details &&
                details.map((account, index) => {
                    return (
                        <div
                            className='card card-custom mt-5'
                            key={index}>
                            <div className='card-header'>
                                <div className='card-title d-flex flex-column py-5'>
                                    <span className='fs-1 fw-bold me-2'>
                                        {amount(account.currentBalance)}
                                        &nbsp;<small>Current Balance</small>
                                    </span>
                                    <span className='fs-6'>
                                        {transform('amount', account.highCreditAmount)}{' '}
                                        <small>(High Credit/Sanctioned Amount)</small>
                                    </span>
                                </div>
                                {(account.emiAmount ||
                                    account.interestRate ||
                                    account.paymentTenure ||
                                    account.paymentFrequency) && (
                                    <div className='card-title d-flex flex-column py-5'>
                                        <span className='fs-3 fw-bold me-2'>Details</span>
                                        {account.emiAmount && (
                                            <div className='fs-6'>
                                                <span className='text-muted'>EMI: </span>
                                                {amount(account.emiAmount)}
                                            </div>
                                        )}
                                        {account.interestRate && (
                                            <div className='fs-6'>
                                                <span className='text-muted'>Interest Rate: </span>
                                                {account.interestRate}%
                                            </div>
                                        )}
                                        {account.paymentTenure && (
                                            <div className='fs-6'>
                                                <span className='text-muted'>Tenure: </span>
                                                {account.paymentTenure} months
                                            </div>
                                        )}
                                        {account.paymentFrequency && (
                                            <span className='fs-6'>
                                                <span className='text-muted'>
                                                    Payment Frequency:{' '}
                                                </span>
                                                {paymentFrequency[account.paymentFrequency]}
                                            </span>
                                        )}
                                    </div>
                                )}
                                <div className='card-title d-flex flex-column py-5'>
                                    <span className='fs-3 fw-bold me-2'>
                                        {loanType[account.accountType]}
                                    </span>
                                    {account.collateralValue && (
                                        <span className='fs-6'>
                                            <span className='text-muted'>
                                                Collateral Value (
                                                {collateralTypes[account.collateralType]}):{' '}
                                            </span>
                                            {amount(account.collateralValue)}
                                        </span>
                                    )}
                                    <span className='fs-6'>{account.memberShortName} Member</span>
                                    <span className='fs-6'>
                                        <span className='text-muted'>Ownership: </span>
                                        {loanOwnership[account.ownershipIndicator]}
                                    </span>
                                </div>
                                <div className='card-title d-flex flex-column py-5 fs-8'>
                                    <table className='table gy-1 gx-1'>
                                        <tbody>
                                            <tr>
                                                <td className='text-muted'>Opened/Disbursed</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.dateOpened,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-muted'>Reported</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.dateReported,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-muted'>First Payment</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.paymentStartDate,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-muted'>Last Payment</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.lastPaymentDate,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-muted'>Payment End Date</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.paymentEndDate,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-muted'>Date Closed</td>
                                                <td>
                                                    {transform(
                                                        'date',
                                                        account.dateClosed,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='card-body'>
                                <ul className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex px-5 my-1'>
                                    {account.paymentHistory &&
                                        parseDpds(
                                            account.paymentHistory,
                                            account.paymentStartDate
                                        ).map((dpd, dpdIndex) => {
                                            return (
                                                <li
                                                    className='nav-item p-0 ms-0 me-5 mb-2'
                                                    key={dpdIndex}>
                                                    <span
                                                        className={[
                                                            'nav-link btn d-flex flex-column rounded-pill min-w-45px py-4 px-3 active',
                                                            ['STD', '000', 'XXX'].indexOf(
                                                                dpd.value
                                                            ) === -1
                                                                ? 'btn-active-danger'
                                                                : 'btn-active-success',
                                                        ].join(' ')}>
                                                        <span className='fs-7 mb-2 fw-bold'>
                                                            {dpd.value}
                                                        </span>
                                                        <span className='fs-8'>{dpd.month}</span>
                                                        <span className='fs-8'>{dpd.year}</span>
                                                    </span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default CibilAccounts;
