import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CibilReportV2 from '../../Bureau/CibilReportV2';
import { getWarehouseDetails } from '../_rego.service';

const TransunionCibilReportV2 = () => {
    const { report_id } = useParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        setReport(data);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, []);
    return <>{report && <CibilReportV2 details={report} />}</>;
};
export default TransunionCibilReportV2;
