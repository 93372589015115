import clsx from 'clsx';
import React, { FC } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROUTE_URLS } from '../../../../app/consts/route_urls';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu, Search } from '../../../partials';
import { useAuth } from '../../../../_mApp/auth';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        role='button'
        className='symbol symbol-35px symbol-circle ms-3'
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        ref={ref}>
        {children}
    </span>
));

const Topbar: FC = () => {
    const { currentUser } = useAuth();
    return (
        <div className='d-flex flex-shrink-0'>
            <div className='topbar d-flex align-items-center flex-shrink-0'>
                {currentUser.user_type !== 'borrower' && (
                    <>
                        <Search />
                        <OverlayTrigger
                            placement='left'
                            overlay={<Tooltip>Background Tasks</Tooltip>}>
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle}>
                                    <i className='spin symbol-label fs-2 bi bi-gear-wide-connected bg-danger text-light'></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link to={ROUTE_URLS.tasks_lms}>
                                            LMS Background Tasks
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={ROUTE_URLS.emi_utils}>EMI Utils</Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </OverlayTrigger>
                    </>
                )}

                <div
                    className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                    id='kt_header_user_menu_toggle'>
                    {/* begin::Toggle */}
                    <div
                        className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'>
                        <img
                            className='h-30px w-30px rounded-circle'
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                            alt='crego'
                        />
                    </div>
                    <HeaderUserMenu />
                    {/* end::Toggle */}
                </div>
                {/* end::User */}
            </div>
        </div>
    );
};

export { Topbar };
