import DesignEditor from '../DesignEditor';

const FlowEditor = () => {
    return (
        <>
            <DesignEditor />
        </>
    );
};
export default FlowEditor;
