import { Route, Routes } from 'react-router-dom';
import TemplatesV2 from '../TemplatesV2';
import FlowEditor from './RegoStudio/FlowEditor';
import FinboxBankSummary from './Widgets/FinboxBankSummary';
import SignzyGstAnalytics from './Widgets/SignzyGstAnalytics';
import SignzyItrPullAnalytics from './Widgets/SignzyItrPullAnalytics';
import SignzyItrReport from './Widgets/SignzyItrReport';
import StoragePreview from './Widgets/StoragePreview';
import TransunionCibilReport from './Widgets/TransunionCibilReport';
import TransunionCibilReportV2 from './Widgets/TransunionCibilReportV2';

const Rego = () => {
    return (
        <Routes>
            <Route
                path='/templates/*'
                element={<TemplatesV2 />}
            />
            <Route
                path='/flows/:flow_id'
                element={<FlowEditor />}
            />
            <Route
                path='/signzy-gst-analytics/:report_id'
                element={<SignzyGstAnalytics />}
            />
            <Route
                path='/storage/:storage_id'
                element={<StoragePreview />}
            />
            <Route
                path='/cibil-report/:report_id'
                element={<TransunionCibilReport />}
            />
            <Route
                path='/cibil-report-v2/:report_id'
                element={<TransunionCibilReportV2 />}
            />
            <Route
                path='/signzy-itr/:report_id'
                element={<SignzyItrPullAnalytics />}
            />
            <Route
                path='/signzy-itr-fetch/:report_id'
                element={<SignzyItrReport />}
            />
            <Route
                path='/finbox-bank-summary/:report_id'
                element={<FinboxBankSummary />}
            />
        </Routes>
    );
};
export default Rego;
