import { amount } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { loanType } from './_cibil-report.consts';

const CibilEnquiries = ({ details }) => {
    return (
        <>
            <div className='d-flex flex-wrap gap-5'>
                {details &&
                    details.map((row, index) => {
                        return (
                            <div
                                className='card card-custom me-5'
                                key={index}>
                                <div className='card-body'>
                                    <div className='d-flex align-banks-center me-3'>
                                        <div className='flex-grow-1'>
                                            <a
                                                href='#'
                                                className='text-gray-800 text-hover-primary fs-5 fw-bold lh-0'>
                                                {amount(row.enquiryAmount)}
                                            </a>
                                            <span className='text-gray-600 fw-semibold d-block fs-6'>
                                                {loanType[row.enquiryPurpose]}
                                            </span>
                                            <small className='text-gray-400 fw-semibold d-block'>
                                                {row.memberShortName} Member
                                            </small>
                                        </div>
                                    </div>
                                    <div className='separator separator-dashed my-3'></div>
                                    <div className='d-flex justify-content-between'>
                                        <span className='badge badge-light-info'>
                                            {transform(
                                                'date',
                                                row.enquiryDate,
                                                'Do MMM YYYY',
                                                'DDMMYYYY'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default CibilEnquiries;
