import { Handle, Position } from '@xyflow/react';
import React from 'react';
import { beautify } from '../../../../helpers/str';
import { statusBadge } from '../../../../helpers/utils';

const NodeApiRequest = ({ id, data, deletable, dragHandle, isConnectable, type }) => {
    return (
        <>
            <Handle
                type='target'
                id='left'
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='top'
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='bottom'
                position={Position.Bottom}
                isConnectable={isConnectable}
            />
            <Handle
                type='source'
                position={Position.Right}
                id='right'
                isConnectable={isConnectable}
            />
            <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-flush bg-light-primary border-primary w-100'>
                    <div className='card-header p-3 min-h-1px min-w-200px'>
                        <div className='card-title m-0 fs-6'>{id}</div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='p-2'>
                            <span className='me-2'>
                                {statusBadge(data?.node?.config?.method, 'p-1', false)}
                            </span>
                            <span className='fs-8 text-primary'>{data?.node?.config?.url}</span>
                        </div>
                        {['body', 'headers', 'params'].map((type) => {
                            return (
                                <React.Fragment key={type}>
                                    {data?.node?.config?.[type] &&
                                        Object.keys(data?.node?.config?.[type])?.length > 0 && (
                                            <>
                                                <h6 className='mt-5 fs-8 bg-primary py-2 ps-2 text-white'>
                                                    {beautify(type)}
                                                </h6>
                                                <div className='fs-8 p-2 pt-0'>
                                                    <div className='row pb-2'>
                                                        <div className='col-6 fw-bold'>Key</div>
                                                        <div className='col-6 fw-bold'>Value</div>
                                                    </div>
                                                    {Object.entries(data?.node?.config?.[type]).map(
                                                        ([key, value]) => {
                                                            return (
                                                                <div
                                                                    className='row'
                                                                    key={`${type}_${key}`}>
                                                                    <div className='col-6'>
                                                                        {key}
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        {typeof value === 'object'
                                                                            ? 'Object'
                                                                            : value}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className='card-footer p-3 d-flex justify-content-between'>
                        <span className='text-primary cursor-pointer me-2'>Edit</span>
                        {deletable && (
                            <span role='button'>
                                <i className='bi bi-trash text-danger'></i>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default NodeApiRequest;
