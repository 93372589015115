export const SAMPLE_DESIGN = {
    _id: 'fe5afb29-8578-4ff6-b777-7a60c821fe1c',
    created_at: {
        $date: '2025-03-07T08:31:56.563Z',
    },
    created_by: 'eceac2ca-281e-4f2a-9b98-1f89a52e0d70',
    default_permissions: ['read', 'write', 'delete'],
    flow: '116dd6be-0739-4fe8-bb21-83bc45a7a0d7',
    graph: {
        nodes: {
            init: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                applicant: {
                                    mobile_no:
                                        "${STORE.applicant.mobile_no or (USER.user_id if len(USER.user_id) == 10 else '')}",
                                    pan: '${STORE.applicant.pan}',
                                },
                            },
                            schema: {
                                title: 'Enter PAN to get Started',
                                type: 'object',
                                properties: {
                                    applicant: {
                                        type: 'object',
                                        properties: {
                                            pan: {
                                                type: 'string',
                                                title: 'PAN',
                                                pattern:
                                                    '^[a-zA-Z]{3}[Pp][a-zA-Z][0-9]{4}[a-zA-Z]$',
                                            },
                                            mobile_no: {
                                                type: 'string',
                                                title: 'Mobile Number',
                                                pattern: '^[0-9]{10}$',
                                            },
                                        },
                                        required: ['pan'],
                                    },
                                },
                            },
                            ui: {
                                applicant: {
                                    'ui:label': false,
                                    mobile_no: {
                                        'ui:widget':
                                            "${'hidden' if (STORE.applicant.mobile_no or (len(USER.user_id) == 10 if USER.user_id else False)) else 'text'}",
                                    },
                                    pan: {
                                        errors: {
                                            pattern:
                                                'Please enter a valid PAN number in the format: AAAAA0000A',
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'dedupe',
                    },
                ],
            },
            dedupe: {
                type: 'task',
                config: {
                    name: 'dedupe',
                    params: {
                        'applicant.mobile_no': '${data.applicant.mobile_no}',
                        'applicant.pan': '${data.applicant.pan}',
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['auto'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'init',
                        condition: {
                            template: '${ data.dedupe_exists }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: [
                                        'A lead with this mobile number or PAN already exist.',
                                    ],
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'test_node',
                        condition: {
                            template: '${ data.dedupe_exists }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: [
                                        'A lead with this mobile number or PAN already exist.',
                                    ],
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'outgoing_webhook_lead_created',
                        condition: {
                            template: '${ not data.dedupe_exists }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.applicant.pan',
                                        value: '${NODES.init.data.applicant.pan}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.mobile_no',
                                        value: '${NODES.init.data.applicant.mobile_no}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            outgoing_webhook_lead_created: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'lead_created',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: '${STORE}.safe_eval',
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'befisc_pan_all_in_one',
                    },
                ],
            },
            befisc_pan_all_in_one: {
                type: 'api_request',
                config: {
                    url: '${SECRET.befisc.pan_all_in_one_host}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        authkey: '${SECRET.befisc.api_key}',
                    },
                    body: {
                        pan: '${STORE.applicant.pan}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data.status == 1}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            template: {
                                applicant: {
                                    name: '${data.result.full_name}',
                                    masked_aadhaar: '${data.result.masked_aadhaar}',
                                    address: {
                                        address_line:
                                            "${' '.join([data.result.address.line_1, data.result.address.line_2, data.result.address.street_name]).strip()}",
                                        pincode: '${data.result.address.zip}',
                                        city: '${data.result.address.city}',
                                        state: "${upper(str(data.result.address.state)) if data.result.address.state else ''}",
                                    },
                                    gender: '${data.result.gender}',
                                    dob: '${data.result.dob}',
                                    is_director: '${data.result.is_director}.safe_eval',
                                    is_sole_proprietor:
                                        '${data.result.is_sole_proprietor}.safe_eval',
                                    father_name: '${data.result.fname}',
                                    din: '${data.result.din}',
                                    company_list: '${data.result.company_list}.safe_eval',
                                },
                                verification: {
                                    applicant: {
                                        name: {
                                            source: "${'BeFiSc' if data.result.full_name else '' }",
                                        },
                                        gender: {
                                            source: "${'BeFiSc' if data.result.gender else '' }",
                                        },
                                        dob: {
                                            source: "${'BeFiSc' if data.result.dob else '' }",
                                        },
                                        father_name: {
                                            source: "${'BeFiSc' if data.result.fname else '' }",
                                        },
                                        address: {
                                            address_line: {
                                                source: "${'BeFiSc' if (data.result.address.line_1 or data.result.address.line_2 or data.result.address.street_name) else '' }",
                                            },
                                            pincode: {
                                                source: "${'BeFiSc' if data.result.address.zip else '' }",
                                            },
                                            city: {
                                                source: "${'BeFiSc' if data.result.address.city else '' }",
                                            },
                                            state: {
                                                source: "${'BeFiSc' if data.result.address.state else '' }",
                                            },
                                        },
                                    },
                                },
                            },
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data.status != 1}.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.message}.safe_eval'],
                                },
                                parser: 'mako',
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            decentro_ckyc_prefill: {
                type: 'api_request',
                config: {
                    url: '${SECRET.decentro.host}/synchronous/hyperstream-executor',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        client_id: '${SECRET.decentro.client_id}',
                        client_secret: '${SECRET.decentro.client_secret}',
                        module_secret: '${SECRET.decentro.module_secret}',
                    },
                    body: {
                        reference_id: '${uuid4_hex()}',
                        consent: true,
                        consent_purpose: 'For Loan application KYC',
                        hyperstream: 'CKYC_PREFILL',
                        initial_input: {
                            full_name: '${STORE.applicant.name}',
                            mobile_number: '9920828104',
                        },
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.applicant.name',
                                        value: '${STORE.applicant.name or data.data.DATA_PULL.full_name}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.dob',
                                        value: '${STORE.applicant.dob or data.data.DATA_PULL.auth_factor}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.passport',
                                        value: "${data.data.DATA_PULL.identity_details.PASSPORT[0].identity_number or ''}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.ckyc.search',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            decentro_ckyc_search: {
                type: 'api_request',
                config: {
                    url: '${SECRET.decentro.host}/v2/kyc/ckyc/search',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        client_id: '${SECRET.decentro.client_id}',
                        client_secret: '${SECRET.decentro.client_secret}',
                        module_secret: '${SECRET.decentro.module_secret}',
                    },
                    body: {
                        reference_id: '${uuid4_hex()}',
                        document_type: 'PAN',
                        id_number: '${STORE.applicant.pan}',
                        consent: true,
                        consent_purpose: 'For Loan application KYC',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'decentro_ckyc_download',
                        condition: {
                            template: '${ data._status_code == 200}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.ckyc.search',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'decentro_ckyc_download',
                        condition: {
                            template: '${ data._status_code != 200}.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.message}'],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            decentro_ckyc_download: {
                type: 'api_request',
                config: {
                    url: '${SECRET.decentro.host}/v2/kyc/ckyc/download',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        client_id: '${SECRET.decentro.client_id}',
                        client_secret: '${SECRET.decentro.client_secret}',
                        module_secret: '${SECRET.decentro.module_secret}',
                    },
                    body: {
                        reference_id: '${uuid4_hex()}',
                        id_number: '${STORE.ckyc.search.data.kycResult.ckycId}',
                        auth_factor_type: 1,
                        auth_factor: '${STORE.applicant.dob}',
                        consent: true,
                        consent_purpose: 'For Loan application KYC',
                        perform_ocr: false,
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data._status_code == 200}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.ckyc.download',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data._status_code != 200}.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.message}'],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            aadhaar_verification: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            schema: {
                                title: 'Enter your Aadhaar',
                                type: 'object',
                                properties: {
                                    applicant: {
                                        type: 'object',
                                        properties: {
                                            aadhaar: {
                                                type: 'string',
                                                title: 'Aadhaar Number',
                                                description: '12 Digit UID (123412341234)',
                                                pattern: '^[0-9]{12}$',
                                            },
                                        },
                                        required: ['aadhaar'],
                                    },
                                },
                            },
                            ui: {
                                'ui:submitButtonOptions': {
                                    submitText: 'Send Aadhaar OTP',
                                    skipTo: 'applicant_info',
                                    forceShowSkip: true,
                                    skipText: 'UIDAI might be experiencing issues, Skip this Step!',
                                    skipAfter: 90,
                                },
                                applicant: {
                                    'ui:label': false,
                                    aadhaar: {
                                        errors: {
                                            pattern:
                                                'Please enter a valid Aadhaar number in the format: 123412341234',
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.applicant.aadhaar',
                                        value: '${data.applicant.aadhaar}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'transbnk_aadhaar_initiate',
                    },
                ],
            },
            transbnk_aadhaar_initiate: {
                type: 'api_request',
                config: {
                    url: '${SECRET.transbnk.host}/okyc-initiate-otp',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.transbnk.api_key}',
                    },
                    body: {
                        uid: '${STORE.applicant.aadhaar}',
                        uniqueId: '${uuid4_hex()}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'aadhaar_otp',
                        condition: {
                            template: '${ data.code == "200" }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.aadhaar.init_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data.code != "200" }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.result.msg or data.message}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            aadhaar_otp: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                aadhaar: '${STORE.applicant.aadhaar}',
                            },
                            schema: {
                                title: 'Enter the OTP',
                                type: 'object',
                                properties: {
                                    aadhaar: {
                                        type: 'string',
                                        title: 'Aadhaar Number',
                                        description: '12 Digit UID (123412341234)',
                                    },
                                    otp: {
                                        type: 'string',
                                        title: 'Aadhaar OTP',
                                        description:
                                            'Enter the 6 digit OTP received on your Aadhaar Linked Mobile Number',
                                    },
                                    resend: {
                                        type: 'string',
                                    },
                                },
                            },
                            ui: {
                                aadhaar: {
                                    'ui:disabled': true,
                                },
                                resend: {
                                    'ui:widget': 'page_item',
                                    'ui:label': false,
                                    'ui:element': {
                                        className: 'mt-2 fs-7 text-primary',
                                        type: 'link',
                                        action: 'navigate',
                                        target_node: 'transbnk_aadhaar_resend_otp',
                                        text: 'Resend OTP',
                                        wait: 90,
                                        waitText: 'Resend OTP in ',
                                    },
                                },
                                'ui:submitButtonOptions': {
                                    submitText: 'Verify OTP',
                                    skipTo: 'applicant_info',
                                    skipText: 'UIDAI might be experiencing issues, Skip this Step!',
                                    skipAfter: 90,
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'navigate',
                        target_node: 'aadhaar_verification',
                    },
                    {
                        action: 'submit',
                        target_node: 'transbnk_aadhaar_validate_otp',
                    },
                ],
            },
            transbnk_aadhaar_resend_otp: {
                type: 'api_request',
                config: {
                    url: '${SECRET.transbnk.host}/okyc-resend-otp',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.transbnk.api_key}',
                    },
                    body: {
                        fwdp: '${STORE.aadhaar.init_response.model.fwdp}',
                        transactionId: '${STORE.aadhaar.init_response.model.transactionId}',
                        uid: '${STORE.applicant.aadhaar}',
                        uniqueId: '${uuid4_hex()}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'aadhaar_otp',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                alert: {
                                    type: 'success',
                                    title: 'Success!',
                                    message: 'OTP has been resent successfully',
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'aadhaar_verification',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            transbnk_aadhaar_validate_otp: {
                type: 'api_request',
                config: {
                    url: '${SECRET.transbnk.host}/okyc-submit-otp',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.transbnk.api_key}',
                    },
                    body: {
                        codeVerifier: '${STORE.aadhaar.init_response.model.codeVerifier}',
                        fwdp: '${STORE.aadhaar.init_response.model.fwdp}',
                        otp: '${data.otp}',
                        shareCode: '1234',
                        transactionId: '${STORE.aadhaar.init_response.model.transactionId}',
                        validateXml: true,
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'applicant_info',
                        condition: {
                            template: '${ data.code == "200" }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.aadhaar.details',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.aadhaar.person_photo',
                                        value: "${upload_file_to_storage_service(data.model.image, 's3', 'image/jpeg', 'jpg')['document_id'] if data.model.image else ''}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.person_photo.source',
                                        value: "${'Aadhaar' if data.model.image else ''}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.aadhaar.xml_link',
                                        value: "${data.model.link if data.model.link else ''}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.name.aadhaar_match',
                                        value: "${compare_string(STORE.applicant.name, data.model.name) if data.model.name else '-'}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.gender.aadhaar_match',
                                        value: "${'Match' if (data.model.name and STORE.applicant.gender == data.model.gender) else 'Mismatch' if data.model.name else '-'}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.dob.aadhaar_match',
                                        value: "${'Match' if (data.model.dob and STORE.applicant.dob == strftime(strptime(data.model.dob, '%d-%m-%Y'), '%Y-%m-%d')) else 'Mismatch' if data.model.dob else '-'}",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'aadhaar_otp',
                        condition: {
                            template: '${ data.code != "200" }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.result.msg}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            applicant_info: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                applicant: '${STORE.applicant}.safe_eval',
                            },
                            schema: {
                                title: "Let's get Started",
                                type: 'object',
                                properties: {
                                    applicant: {
                                        type: 'object',
                                        properties: {
                                            pan: {
                                                type: 'string',
                                                title: 'PAN',
                                            },
                                            name: {
                                                type: 'string',
                                                title: 'Name (as per PAN)',
                                            },
                                            dob: {
                                                type: 'string',
                                                title: 'Date of Birth',
                                                format: 'date',
                                            },
                                            employment_type: {
                                                type: 'string',
                                                enum: ['Salaried', 'Self Employed'],
                                            },
                                            company: {
                                                type: 'string',
                                                title: 'Employer/Company Name',
                                            },
                                            profession: {
                                                type: 'string',
                                                title: 'Select your Profession',
                                                enum: '${PARAMS.professions}.safe_eval',
                                            },
                                            email: {
                                                type: 'string',
                                                title: 'E-Mail',
                                            },
                                            address: {
                                                title: 'Address of Installation',
                                                type: 'object',
                                                properties: {
                                                    same_as_aadhaar: {
                                                        default:
                                                            '${True if STORE.aadhaar.details.model.address.pc else False}.safe_eval',
                                                        type: 'boolean',
                                                    },
                                                },
                                                allOf: [
                                                    {
                                                        if: {
                                                            properties: {
                                                                same_as_aadhaar: {
                                                                    const: false,
                                                                },
                                                            },
                                                        },
                                                        then: {
                                                            properties: {
                                                                pincode: {
                                                                    type: 'string',
                                                                    title: 'Pincode',
                                                                    pattern: '^[0-9]{6}$',
                                                                },
                                                                address_line: {
                                                                    type: 'string',
                                                                    title: 'Address',
                                                                },
                                                                city: {
                                                                    type: 'string',
                                                                    title: 'City',
                                                                    pattern: "^[A-Za-z -.0-9']*$",
                                                                },
                                                                state: {
                                                                    type: 'string',
                                                                    title: 'State',
                                                                    enum: '${PARAMS.states}.safe_eval',
                                                                },
                                                            },
                                                            required: [
                                                                'address_line',
                                                                'city',
                                                                'state',
                                                                'pincode',
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        required: [
                                            'name',
                                            'pan',
                                            'dob',
                                            'profession',
                                            'employment_type',
                                            'email',
                                        ],
                                    },
                                    documents: {
                                        type: 'object',
                                        properties: {
                                            pan: {
                                                type: 'string',
                                                title: 'Upload PAN',
                                            },
                                            aadhaar: {
                                                type: 'string',
                                                title: 'Upload Aadhaar',
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                applicant: {
                                    'ui:label': false,
                                    name: {
                                        'ui:disabled': '${bool(STORE.applicant.name)}.safe_eval',
                                    },
                                    dob: {
                                        'ui:disabled': '${bool(STORE.applicant.dob)}.safe_eval',
                                    },
                                    email: {
                                        'ui:disabled': '${bool(STORE.applicant.email)}.safe_eval',
                                    },
                                    pan: {
                                        'ui:disabled': true,
                                    },
                                    address: {
                                        same_as_aadhaar: {
                                            cols: 12,
                                            'ui:label': false,
                                            'ui:widget':
                                                "${'rich_checkbox' if STORE.aadhaar.details.model.address.pc else 'hidden'}.safe_eval",
                                            'ui:layout': {
                                                body: [
                                                    {
                                                        elements: [
                                                            {
                                                                tag: 'h5',
                                                                className: 'me-1 mb-0',
                                                                text: 'Same as Aadhaar?',
                                                            },
                                                            {
                                                                tag: 'p',
                                                                className: 'me-1 mb-0',
                                                                text: "${' '.join([STORE.aadhaar.details.model.address.house or '', STORE.aadhaar.details.model.address.street or '', STORE.aadhaar.details.model.address.landmark or '', STORE.aadhaar.details.model.address.loc or '', STORE.aadhaar.details.model.address.po or '', STORE.aadhaar.details.model.address.dist or '', STORE.aadhaar.details.model.address.state or '', STORE.aadhaar.details.model.address.pc or ''])}",
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                },
                                documents: {
                                    pan: {
                                        'ui:widget': 'file',
                                        'ui:fileType': 'pan',
                                    },
                                    aadhaar: {
                                        'ui:widget': 'file',
                                        'ui:fileType': 'aadhaar',
                                    },
                                },
                                'ui:validations': [
                                    {
                                        field: 'applicant.dob',
                                        operator: '>=',
                                        transform: 'age',
                                        value: 18,
                                        valueSuffix: 'years',
                                    },
                                ],
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        condition: {
                            template:
                                "${str(data.applicant.address.pincode)[0:2] in (['18', '37'])}.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'applicant_info',
                        update_data: {
                            template: {
                                alert: {
                                    type: 'error',
                                    title: 'Error!',
                                    message: 'Pincode not serviceable',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                        target_node: 'validate_address',
                    },
                    {
                        action: 'navigate',
                        target_node: 'aadhaar_verification',
                    },
                ],
            },
            validate_address: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.applicant.address.address_line',
                                        value: "${' '.join([STORE.aadhaar.details.model.address.house or '', STORE.aadhaar.details.model.address.street or '', STORE.aadhaar.details.model.address.landmark or '', STORE.aadhaar.details.model.address.loc or '', STORE.aadhaar.details.model.address.po or '', STORE.aadhaar.details.model.address.dist or ''])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.address.address_line.source',
                                        value: 'Aadhaar',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.address.city',
                                        value: '${STORE.aadhaar.details.model.address.dist}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.address.city.source',
                                        value: 'Aadhaar',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.address.state',
                                        value: "${upper(str(STORE.aadhaar.details.model.address.state or '' ))}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.address.state.source',
                                        value: 'Aadhaar',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.applicant.address.pincode',
                                        value: '${STORE.aadhaar.details.model.address.pc}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.applicant.address.pincode.source',
                                        value: 'Aadhaar',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        condition: {
                            template: '${STORE.applicant.address.same_as_aadhaar}.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'requirement',
                    },
                    {
                        action: 'auto',
                        target_node: 'requirement',
                    },
                ],
            },
            requirement: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                requirement: {
                                    income: '${STORE.financials.income}.safe_eval',
                                    loan_amount: '${STORE.requirement.loan_amount}.safe_eval',
                                    project_cost: '${STORE.project.project_cost}.safe_eval',
                                    sanctioned_load: '${STORE.project.sanctioned_load}.safe_eval',
                                },
                                electricity_bill: '${STORE.documents.electricity_bill}.safe_eval',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    requirement: {
                                        title: 'Requirement',
                                        type: 'object',
                                        properties: {
                                            project_cost: {
                                                type: 'number',
                                                title: 'Total Installation Cost (₹)',
                                                description:
                                                    'This should be as per proforma invoice',
                                            },
                                            loan_amount: {
                                                type: 'number',
                                                title: 'Required Loan Amount',
                                                description:
                                                    'Required loan amount must be a minimum of ₹50,000',
                                            },
                                            sanctioned_load: {
                                                type: 'number',
                                                title: 'Sanctioned Load (in kW)',
                                            },
                                            income: {
                                                type: 'number',
                                                title: 'Annual Income (as per ITR)',
                                            },
                                        },
                                        required: ['income', 'project_cost', 'loan_amount'],
                                    },
                                    electricity_bill: {
                                        title: 'Electricity Bill',
                                        type: 'object',
                                        properties: {
                                            discom: {
                                                type: 'string',
                                                title: 'DISCOM (Select or Type to add Other)',
                                            },
                                            cid: {
                                                type: 'string',
                                                title: 'Consumer ID on Electricity Bill',
                                            },
                                            bill_amount: {
                                                type: 'number',
                                                title: 'Bill Amount',
                                            },
                                            bill_name_on_applicant_name: {
                                                type: 'boolean',
                                                title: 'Name on Electricity Bill',
                                                oneOf: [
                                                    {
                                                        const: true,
                                                        title: 'Bill is on my Name',
                                                    },
                                                    {
                                                        const: false,
                                                        title: "Bill is on someone else's name who is willing to be a co-applicant",
                                                    },
                                                ],
                                            },
                                            consent: {
                                                type: 'boolean',
                                                title: 'I hereby declare that above details provided are correct. Your annual income will be validated with your annual income as per ITR/Bank statement',
                                            },
                                        },
                                        required: [
                                            'bill_amount',
                                            'bill_name_on_applicant_name',
                                            'consent',
                                        ],
                                    },
                                },
                            },
                            ui: {
                                electricity_bill: {
                                    discom: {
                                        'ui:widget': 'select2',
                                        'ui:placeholder': 'Select DISCOM',
                                        'ui:select2': {
                                            enumSource: 'api',
                                            enumUrl:
                                                '/faker/discoms/${STORE.applicant.address.state}.json',
                                            creatable: true,
                                        },
                                    },
                                    bill_name_on_applicant_name: {
                                        'ui:widget': 'radio',
                                    },
                                },
                                'ui:validations': [
                                    {
                                        field: 'requirement.loan_amount',
                                        operator: '<=',
                                        refField: 'perc(requirement.project_cost, 100)',
                                    },
                                ],
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.financials.income',
                                        value: '${data.requirement.income}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.project.project_cost',
                                        value: '${data.requirement.project_cost}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.requirement.loan_amount',
                                        value: '${data.requirement.loan_amount}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.requirement.sanctioned_load',
                                        value: '${data.requirement.sanctioned_load}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.discom',
                                        value: '${data.electricity_bill.discom}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.cid',
                                        value: '${data.electricity_bill.cid}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.consent',
                                        value: '${data.electricity_bill.consent}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.bill_amount',
                                        value: '${data.electricity_bill.bill_amount}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.bill_name_on_applicant_name',
                                        value: '${data.electricity_bill.bill_name_on_applicant_name}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'signzy_electricity_bill_fetch',
                    },
                    {
                        action: 'navigate',
                        target_node: 'applicant_info',
                    },
                ],
            },
            signzy_electricity_bill_fetch: {
                type: 'api_request',
                config: {
                    url: '${SECRET.signzy.host}/electricitybills/fetch',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: '${SECRET.signzy.api_key}',
                    },
                    body: {
                        electricityProvider: '${STORE.documents.electricity_bill.discom}',
                        consumerNo: '${STORE.documents.electricity_bill.cid}',
                        mobileNo: '${STORE.applicant.mobile_no}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'cibil_report_v2_pull',
                        condition: {
                            template:
                                "${STORE.applicant.pan != 'BAQPA7442D' and STORE.applicant.pan != 'DBNPS8818B' and STORE.applicant.pan != 'ANFPK0428A'}.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.fetch_response',
                                        value: '${data.result}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.bill_amount',
                                        value: '${data.result.billAmount if data.result.billAmount else STORE.documents.electricity_bill.bill_amount}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'cibil_report_pull',
                        condition: {
                            template:
                                "${STORE.applicant.pan == 'BAQPA7442D' or STORE.applicant.pan == 'DBNPS8818B' or STORE.applicant.pan == 'ANFPK0428A'}.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.fetch_response',
                                        value: '${data.result}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.documents.electricity_bill.bill_amount',
                                        value: '${data.result.billAmount if data.result.billAmount else STORE.documents.electricity_bill.bill_amount}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            cibil_report_v2_pull_dummy_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            schema: {
                                title: 'Enter Sample Data to Fetch CIBIL',
                                type: 'object',
                                properties: {
                                    firstName: {
                                        type: 'string',
                                    },
                                    middleName: {
                                        type: 'string',
                                    },
                                    lastName: {
                                        type: 'string',
                                    },
                                    birthDate: {
                                        type: 'string',
                                    },
                                    gender: {
                                        type: 'string',
                                    },
                                    pan: {
                                        type: 'string',
                                    },
                                    address: {
                                        type: 'string',
                                    },
                                    pinCode: {
                                        type: 'string',
                                    },
                                    stateCode: {
                                        type: 'string',
                                    },
                                },
                            },
                            ui: {
                                applicant: {
                                    'ui:label': false,
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        condition: {
                            template: '${ not STORE.cibil_v2.score }.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'cibil_report_v2_pull',
                    },
                    {
                        action: 'submit',
                        condition: {
                            template: '${ STORE.cibil_v2.score }.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'cibil_report_v2_pull',
                    },
                    {
                        action: 'navigate',
                        target_node: 'bre_score',
                    },
                ],
            },
            cibil_report_v2_pull: {
                type: 'api_request',
                config: {
                    url: '${SECRET.transunion_cibil.host}/acquire/credit-assessment/v1/consumer-cir-cv',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'member-ref-id': '${SECRET.transunion_cibil.member_ref_id}',
                        'cust-ref-id': '${uuid4_hex()}',
                        apikey: '${SECRET.transunion_cibil.api_key}',
                        'client-secret': '${SECRET.transunion_cibil.client_secret}',
                    },
                    body: {
                        serviceCode: 'CN1CAS0004',
                        monitoringDate: '08102020',
                        consumerInputSubject: {
                            tuefHeader: {
                                headerType: 'TUEF',
                                version: '12',
                                memberRefNo: '${SECRET.transunion_cibil.member_ref_id}',
                                gstStateCode: '01',
                                enquiryMemberUserId: '${SECRET.transunion_cibil.enquiry_user_id}',
                                enquiryPassword: '${SECRET.transunion_cibil.enquiry_password}',
                                enquiryPurpose: '06',
                                enquiryAmount: '000049500',
                                scoreType: '16',
                                outputFormat: '03',
                                responseSize: '1',
                                ioMedia: 'CC',
                                authenticationMethod: 'L',
                            },
                            names: [
                                {
                                    index: 'N01',
                                    firstName:
                                        '${STORE.applicant.name.split()[0] if STORE.applicant.name else ""}',
                                    middleName:
                                        '${" ".join(STORE.applicant.name.split()[1:-1]) if len(STORE.applicant.name.split()) > 2 else ""}',
                                    lastName:
                                        '${STORE.applicant.name.split()[-1] if len(STORE.applicant.name.split()) > 1 else ""}',
                                    birthDate:
                                        "${strftime(strptime(STORE.applicant.dob, '%Y-%m-%d'), '%d%m%Y')}",
                                    gender: "${'1' if STORE.applicant.gender == 'F' else '2'}",
                                },
                            ],
                            ids: [
                                {
                                    index: 'I01',
                                    idNumber: '${STORE.applicant.pan}',
                                    idType: '01',
                                },
                            ],
                            telephones: [
                                {
                                    index: 'T01',
                                    telephoneNumber: '',
                                    telephoneType: '01',
                                },
                            ],
                            addresses: [
                                {
                                    index: 'A01',
                                    line1: '${str(STORE.applicant.address.address_line)[0:30]}',
                                    line2: '${STORE.applicant.address.city}',
                                    line3: '',
                                    line4: '',
                                    line5: '',
                                    stateCode: '${PARAMS.statesMap[STORE.applicant.address.state]}',
                                    pinCode: '${STORE.applicant.address.pincode}',
                                    addressCategory: '01',
                                    residenceCode: '01',
                                },
                            ],
                            enquiryAccounts: [
                                {
                                    index: 'I01',
                                    accountNumber: '',
                                },
                            ],
                        },
                    },
                    ssl: {
                        cert: '${SECRET.transunion_cibil.cert}',
                        key: '${SECRET.transunion_cibil.key}',
                        region: '${SECRET.transunion_cibil.region}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.cibil_v2.report_id',
                                        value: "${update_warehouse(data, ['cibil_v2'])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.cibil_v2.score',
                                        value: "${int(data.consumerCreditData[0].scores[0].score) if (data.consumerCreditData[0].scores[0].score and '-' not in data.consumerCreditData[0].scores[0].score) else -1}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'bre_score',
                    },
                ],
            },
            cibil_report_pull: {
                type: 'api_request',
                config: {
                    url: '${PARAMS[PARAMS.environment].rego_host}/faker/transunion-cibil-v2.json',
                    method: 'get',
                    params: {},
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.cibil_v2.report_id',
                                        value: "${update_warehouse(data, ['cibil_v2'])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.cibil_v2.score',
                                        value: "${int(data.consumerCreditData[0].scores[0].score) if (data.consumerCreditData[0].scores[0].score and '-' not in data.consumerCreditData[0].scores[0].score) else -1}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'bre_score',
                    },
                ],
            },
            bre_score: {
                type: 'expression',
                config: {
                    model: 'gonogo',
                    expression: {
                        rules: [
                            {
                                key: 'applicant.bureau-score',
                                type: 'number',
                                value: 660,
                                operator: '>',
                            },
                            {
                                key: 'applicant.bureau-score',
                                type: 'number',
                                value: 300,
                                operator: '<',
                            },
                        ],
                        combinator: 'or',
                        reason: 'Bureau score less than 680',
                    },
                    data: {
                        applicant: {
                            bureau_data: {
                                response_data: {
                                    CreditReport:
                                        '${get_warehouse_data(STORE.cibil_v2.report_id)}.safe_eval',
                                },
                                vendor: 'transunion-cibil',
                            },
                        },
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'bre_dpd_last_3_months',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bre.score.status',
                                        value: '${data.status}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bre.score.reason',
                                        value: '${data.reason}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            bre_dpd_last_3_months: {
                type: 'expression',
                config: {
                    model: 'gonogo',
                    expression: {
                        rules: [
                            {
                                key: 'applicant.bureau-dpds',
                                label: 'DPDs',
                                type: 'number',
                                operator: '<=',
                                value: 30,
                                data_existence_check: 'raise',
                                filters: [
                                    {
                                        key: 'active_account',
                                        label: 'Active Account',
                                        type: 'select',
                                        output: {
                                            value: 'active_account',
                                            data_existence_check: 'raise',
                                        },
                                    },
                                    {
                                        key: 'period',
                                        label: 'Period',
                                        type: 'period',
                                        output: {
                                            value: 3,
                                            operator: '<=',
                                            period_type: 'months',
                                            data_existence_check: 'raise',
                                        },
                                    },
                                    {
                                        key: 'report_date',
                                        label: 'Report Date',
                                        type: 'select',
                                        output: {
                                            value: 'reported_date',
                                            data_existence_check: 'raise',
                                        },
                                        is_required: true,
                                    },
                                    {
                                        key: 'aggregation',
                                        label: 'Aggregation',
                                        type: 'select',
                                        output: {
                                            value: 'max',
                                        },
                                        is_required: true,
                                    },
                                ],
                            },
                        ],
                        reason: 'More than 30DPDs found in last 3 months',
                    },
                    data: {
                        applicant: {
                            bureau_data: {
                                response_data: {
                                    CreditReport:
                                        '${get_warehouse_data(STORE.cibil_v2.report_id)}.safe_eval',
                                },
                                vendor: 'transunion-cibil',
                            },
                        },
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'bre_dpd_last_12_months',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bre.dpd_last_3_months.status',
                                        value: '${data.status}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bre.dpd_last_3_months.reason',
                                        value: '${data.reason}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            bre_dpd_last_12_months: {
                type: 'expression',
                config: {
                    model: 'gonogo',
                    expression: {
                        rules: [
                            {
                                key: 'applicant.bureau-dpds',
                                label: 'DPDs',
                                type: 'number',
                                operator: '<=',
                                value: 60,
                                data_existence_check: 'raise',
                                filters: [
                                    {
                                        key: 'active_account',
                                        label: 'Active Account',
                                        type: 'select',
                                        output: {
                                            value: 'active_account',
                                            data_existence_check: 'raise',
                                        },
                                    },
                                    {
                                        key: 'period',
                                        label: 'Period',
                                        type: 'period',
                                        output: {
                                            value: 12,
                                            operator: '<=',
                                            period_type: 'months',
                                            data_existence_check: 'raise',
                                        },
                                    },
                                    {
                                        key: 'report_date',
                                        label: 'Report Date',
                                        type: 'select',
                                        output: {
                                            value: 'reported_date',
                                            data_existence_check: 'raise',
                                        },
                                        is_required: true,
                                    },
                                    {
                                        key: 'aggregation',
                                        label: 'Aggregation',
                                        type: 'select',
                                        output: {
                                            value: 'max',
                                        },
                                        is_required: true,
                                    },
                                ],
                            },
                        ],
                        reason: 'More than 60DPDs found in last 12 months',
                    },
                    data: {
                        applicant: {
                            bureau_data: {
                                response_data: {
                                    CreditReport:
                                        '${get_warehouse_data(STORE.cibil_v2.report_id)}.safe_eval',
                                },
                                vendor: 'transunion-cibil',
                            },
                        },
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'bre_suit_filed',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bre.dpd_last_12_months.status',
                                        value: '${data.status}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bre.dpd_last_12_months.reason',
                                        value: '${data.reason}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            bre_suit_filed: {
                type: 'expression',
                config: {
                    model: 'gonogo',
                    expression: {
                        rules: [
                            {
                                key: 'applicant.bureau-accounts',
                                label: 'Loan Accounts',
                                type: 'number',
                                value: 0,
                                operator: '=',
                                filters: [
                                    {
                                        key: 'aggregation',
                                        type: 'select',
                                        label: 'Aggregation',
                                        is_required: true,
                                        output: {
                                            value: 'sum',
                                        },
                                    },
                                    {
                                        key: 'field_name',
                                        label: 'Field Name',
                                        type: 'select',
                                        is_required: true,
                                        output: {
                                            value: 'suit_filed',
                                        },
                                    },
                                ],
                            },
                        ],
                        reason: 'Suit filing found',
                    },
                    data: {
                        applicant: {
                            bureau_data: {
                                response_data: {
                                    CreditReport:
                                        '${get_warehouse_data(STORE.cibil_v2.report_id)}.safe_eval',
                                },
                                vendor: 'transunion-cibil',
                            },
                        },
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'bre_default_outstanding',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bre.suit_filed.status',
                                        value: '${data.status}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bre.suit_filed.reason',
                                        value: '${data.reason}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            bre_default_outstanding: {
                type: 'expression',
                config: {
                    model: 'gonogo',
                    expression: {
                        rules: [
                            {
                                key: 'applicant.bureau-accounts',
                                label: 'Loan Accounts',
                                type: 'number',
                                filters: [
                                    {
                                        key: 'aggregation',
                                        type: 'select',
                                        label: 'Aggregation',
                                        is_required: true,
                                        value: 'max',
                                    },
                                    {
                                        key: 'field_name',
                                        label: 'Field Name',
                                        type: 'select',
                                        is_required: true,
                                        value: 'overdue_amount',
                                    },
                                ],
                                value: 0,
                                operator: '<=',
                            },
                        ],
                        reason: 'Default Outstanding Found',
                    },
                    data: {
                        applicant: {
                            bureau_data: {
                                response_data: {
                                    CreditReport:
                                        '${get_warehouse_data(STORE.cibil_v2.report_id)}.safe_eval',
                                },
                                vendor: 'transunion-cibil',
                            },
                        },
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'cibil_report_analysis',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bre.default_outstanding.status',
                                        value: '${data.status}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bre.default_outstanding.reason',
                                        value: '${data.reason}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            cibil_report_analysis: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        condition: {
                            template:
                                '${STORE.bre.score.status == False or (STORE.requirement.loan_amount > 300000 and (STORE.bre.dpd_last_12_months.status == False or  STORE.bre.default_outstanding.status == False))}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            template: {
                                status: 'REJECTED',
                                reject_reason:
                                    '${list(STORE.bre.score.reason) + list(STORE.bre.dpd_last_3_months.reason) + list(STORE.bre.dpd_last_12_months.reason) + list(STORE.bre.suit_filed.reason) + list(STORE.bre.default_outstanding.reason)}.safe_eval',
                            },
                            parser: 'mako',
                        },
                        target_node: 'application_rejected',
                    },
                    {
                        action: 'auto',
                        condition: {
                            template:
                                "${not STORE.documents.electricity_bill.bill_name_on_applicant_name or days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365 > 65 or ((STORE.documents.electricity_bill.fetch_response and STORE.documents.electricity_bill.fetch_response.name and compare_string(STORE.applicant.name, STORE.documents.electricity_bill.fetch_response.name) < 85) and (STORE.aadhaar.details.model.name and compare_string(STORE.applicant.name, STORE.aadhaar.details.model.name) < 85))}.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant.required',
                                        value: true,
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.co_applicant.name',
                                        value: "${STORE.documents.electricity_bill.fetch_response.name if STORE.documents.electricity_bill.fetch_response else ''}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.co_applicant.reason',
                                        value: "${'It appears that your age is more than 65. To proceed with your application, please add a co-applicant.' if days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365 > 65 else 'It appears that the property is not registered in your name. To proceed with your application, please add the home owner as a co-applicant.'}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.co_applicant.age',
                                        value: "${days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.electricity_bill.name.input_match',
                                        value: '${STORE.documents.electricity_bill.fetch_response and compare_string(STORE.applicant.name, STORE.documents.electricity_bill.fetch_response.name)}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.electricity_bill.name.aadhaar_match',
                                        value: '${STORE.documents.electricity_bill.fetch_response and compare_string(STORE.applicant.name, STORE.aadhaar.details.model.name)}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'co_applicant_form',
                    },
                    {
                        action: 'auto',
                        condition: {
                            template:
                                '${STORE.cibil_v2.score >= 300 and ((STORE.requirement.loan_amount <= 200000 and STORE.cibil_v2.score >= 750) or (200000 < STORE.requirement.loan_amount <= 300000 and STORE.cibil_v2.score >= 800) or (STORE.financials.salary and STORE.financials.salary > 0))}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: {},
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'calculate_offers',
                    },
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: {},
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        condition: {
                            template:
                                "${STORE.applicant.employment_type == 'Salaried' and (not STORE.financials.salary or STORE.financials.salary == 0)}.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'finbox_bank_connect_form',
                    },
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: {},
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        condition: {
                            template: "${STORE.applicant.employment_type != 'Salaried'}.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'itr_form',
                    },
                ],
            },
            co_applicant_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: '${STORE.co_applicant}.safe_eval',
                            schema: {
                                title: 'Co-Applicant',
                                description: '${STORE.co_applicant.reason}',
                                type: 'object',
                                properties: {
                                    name: {
                                        type: 'string',
                                        title: 'Name of the Co-Applicant',
                                    },
                                    mobile_no: {
                                        type: 'string',
                                        title: 'Mobile Number',
                                    },
                                    email: {
                                        type: 'string',
                                        title: 'E-Mail',
                                    },
                                    relation_with_applicant: {
                                        type: 'string',
                                        enum: [
                                            'Father',
                                            'Mother',
                                            'Spouse',
                                            'Sibling',
                                            'Relative',
                                            'Friend',
                                        ],
                                    },
                                },
                                required: ['name', 'mobile_no', 'email', 'relation_with_applicant'],
                            },
                            ui: {
                                'ui:submitButtonOptions': {
                                    submitText: 'Add as Co-Applicant',
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        condition: {
                            template: '${data.mobile_no == STORE.applicant.mobile_no}.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: [
                                        "Co-Applicant mobile number can't be same as applicant's mobile number.",
                                    ],
                                },
                            },
                            parser: 'none',
                        },
                        target_node: 'co_applicant_form',
                    },
                    {
                        action: 'submit',
                        condition: {
                            template: '${data.email == STORE.applicant.email}.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: [
                                        "Co-Applicant email can't be same as applicant's email.",
                                    ],
                                },
                            },
                            parser: 'none',
                        },
                        target_node: 'co_applicant_form',
                    },
                    {
                        action: 'submit',
                        condition: {
                            template:
                                '${STORE.cibil_v2.score >= 300 and ((STORE.requirement.loan_amount <= 200000 and STORE.cibil_v2.score >= 750) or (200000 < STORE.requirement.loan_amount <= 300000 and STORE.cibil_v2.score >= 800) or (STORE.financials.salary and STORE.financials.salary > 0))}.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'calculate_offers',
                    },
                    {
                        action: 'submit',
                        condition: {
                            template:
                                "${STORE.applicant.employment_type == 'Salaried' and (not STORE.financials.salary or STORE.financials.salary == 0)}.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'finbox_bank_connect_form',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        condition: {
                            template: "${STORE.applicant.employment_type != 'Salaried'}.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'itr_form',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'navigate',
                        target_node: 'requirement',
                    },
                ],
            },
            itr_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                itr: {
                                    credentials: {
                                        username: '${STORE.applicant.pan}',
                                    },
                                },
                            },
                            schema: {
                                title: 'ITR Pull',
                                type: 'object',
                                properties: {
                                    itr: {
                                        type: 'object',
                                        properties: {
                                            credentials: {
                                                type: 'object',
                                                properties: {
                                                    username: {
                                                        type: 'string',
                                                        title: 'Username',
                                                    },
                                                    password: {
                                                        type: 'string',
                                                        title: 'Password',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                itr: {
                                    'ui:label': false,
                                    credentials: {
                                        'ui:label': false,
                                        username: {
                                            'ui:placeholder': 'Income Tax Portal Username',
                                        },
                                        password: {
                                            'ui:widget': 'password',
                                            'ui:placeholder': 'Income Tax Portal Password',
                                        },
                                    },
                                },
                                'ui:submitButtonOptions': {
                                    submitText: 'Pull ITR Data',
                                },
                            },
                        },
                        {
                            type: 'page',
                            layout: [
                                {
                                    tag: 'hr',
                                    className: 'mt-4',
                                },
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            action: 'navigate',
                                            target_node: 'itr_form_upload',
                                            icon: 'bi-upload',
                                            iconBg: 'bg-primary text-white',
                                            label: 'or, Upload ITR Manually',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'signzy_itr_pull_init',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                    },
                    {
                        action: 'navigate',
                        target_node: 'requirement',
                    },
                ],
            },
            itr_form_upload: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            condition: '${ not STORE.itr.ocr_init_response.requestId }.safe_eval',
                            action: 'submit',
                            form_data: {
                                itr: {
                                    file: '${STORE.itr.file}.safe_eval',
                                },
                            },
                            schema: {
                                title: 'ITR Pull',
                                type: 'object',
                                properties: {
                                    itr: {
                                        type: 'object',
                                        properties: {
                                            file: {
                                                type: 'string',
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                itr: {
                                    'ui:label': false,
                                    file: {
                                        'ui:widget': 'file',
                                        is_public: true,
                                    },
                                },
                                'ui:submitButtonOptions': {
                                    submitText: 'Upload File & Pull ITR Data',
                                },
                            },
                        },
                        {
                            condition: '${ not STORE.itr.ocr_init_response.requestId }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    tag: 'hr',
                                    className: 'mt-4',
                                },
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            action: 'navigate',
                                            target_node: 'itr_form',
                                            icon: 'bi-upload',
                                            iconBg: 'bg-primary text-white',
                                            label: 'or, Fetch using ITR Login Credentials',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            type: 'page',
                            condition: '${ STORE.itr.ocr_init_response.requestId }.safe_eval',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Processing your ITR',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Your ITR has been uploaded successfully. Please wait until we process your ITR.',
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Re-Check Processing Status',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'signzy_itr_ocr_pull_init',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                    },
                    {
                        action: 'api_request',
                        target_node: 'signzy_itr_ocr_pull_refresh',
                    },
                    {
                        action: 'navigate',
                        target_node: 'requirement',
                    },
                ],
            },
            signzy_itr_pull_init: {
                type: 'api_request',
                config: {
                    url: '${SECRET.signzy.host}/itr/itr-pull',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: '${SECRET.signzy.api_key}',
                    },
                    body: {
                        username: '${STORE.itr.credentials.username}',
                        password: '${STORE.itr.credentials.password}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'calculate_offers',
                        condition: {
                            template: '${ data.sessionId }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.itr.report_id',
                                        value: "${update_warehouse(data, ['itr_pull', STORE.itr.credentials.username])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.salary',
                                        value: "${data.result[list(data.result.keys())[0]].ITR[list(data.result[list(data.result.keys())[0]].ITR.keys())[0]]['PartB-TI'].TotalGrossSalary or data.result[list(data.result.keys())[0]].ITR[list(data.result[list(data.result.keys())[0]].ITR.keys())[0]].ScheduleS.TotalGrossSalary or data.result[list(data.result.keys())[0]].ITR[list(data.result[list(data.result.keys())[0]].ITR.keys())[0]].IncomeDeductions.GrossSalary}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.financials.salary.source',
                                        value: 'ITR',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'itr_form',
                        condition: {
                            template: '${ not data.sessionId }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.error.message}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            signzy_itr_ocr_pull_init: {
                type: 'api_request',
                config: {
                    url: '${SECRET.signzy.host}/itr/itr-ocr-itr-pull',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: '${SECRET.signzy.api_key}',
                    },
                    body: {
                        panNumber: '${STORE.applicant.pan}',
                        files: [
                            {
                                fileUrl: '${get_file_urls(STORE.itr.file)[0]}',
                            },
                        ],
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'itr_form_upload',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.itr.report_id',
                                        value: "${update_warehouse(data, ['itr_pull', 'itr_ocr', STORE.itr.credentials.username])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.itr.ocr_init_response',
                                        value: '${data.result}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'itr_form_upload',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.error.message}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            signzy_itr_ocr_pull_refresh: {
                type: 'api_request',
                config: {
                    url: '${SECRET.signzy.host}/itr/itr-ocr-fetch-reports',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: '${SECRET.signzy.api_key}',
                    },
                    body: {
                        requestId: '${STORE.itr.ocr_init_response.requestId}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'signzy_itr_ocr_pull_download',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.itr.ocr_details',
                                        value: '${data.result}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            signzy_itr_ocr_pull_download: {
                type: 'api_request',
                config: {
                    url: '${STORE.itr.ocr_details.jsonDataUrl}',
                    method: 'get',
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'calculate_offers',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.itr.ocr_report_id',
                                        value: "${update_warehouse(data, ['itr_ocr_pull', STORE.applicant.pan])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.salary',
                                        value: "${data.ITR['Tax Calculation']['Tax Calculation'][0]['Gross Total Income'] or STORE.financials.salary or 0}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.financials.salary.source',
                                        value: 'ITR OCR',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            finbox_bank_connect_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className:
                                                        'bi bi-file-earmark-spreadsheet text-primary',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Salary Account Bank Statement',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Please fetch or upload the bank statement for your salary account for the last 6 months.',
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Fetch/Upload Bank Statements',
                                                    skip: {
                                                        skipTo: 'itr_form',
                                                        skipText:
                                                            'Skip this Step, Upload ITR instead',
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'finbox_bank_connect_session',
                    },
                    {
                        action: 'navigate',
                        target_node: 'requirement',
                    },
                ],
            },
            finbox_bank_connect_session: {
                type: 'api_request',
                config: {
                    url: '${SECRET.finbox.host}/bank-connect/v1/session/',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        api_key: '${SECRET.finbox.api_key}',
                        link_id: '${STORE.applicant.email}',
                        from_date: "${strftime(timedelta(now(), {'months': -6}), '%d/%m/%Y')}",
                        to_date: "${strftime(timedelta(now(), {'days': -1}), '%d/%m/%Y')}",
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'finbox_bank_connect_widget',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bank_connect.session',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'finbox_bank_connect_form',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.error.message}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            finbox_bank_connect_widget: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'iframe',
                                                    title: 'Bank Connect Widget',
                                                    link: '${STORE.bank_connect.session.redirect_url}',
                                                    watch: [
                                                        {
                                                            condition: 'status:success',
                                                            target_node:
                                                                'finbox_bank_connect_initiate_processing',
                                                        },
                                                        {
                                                            condition: 'status:exit',
                                                            target_node:
                                                                'finbox_bank_connect_upload_status',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'navigate',
                        target_node: 'finbox_bank_connect_form',
                    },
                ],
            },
            finbox_bank_connect_initiate_processing: {
                type: 'api_request',
                config: {
                    url: '${SECRET.finbox.host}/bank-connect/v1/session_data/${STORE.bank_connect.session.session_id}/initiate_processing/',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.finbox.api_key}',
                        'server-hash': '${SECRET.finbox.server_hash}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'finbox_bank_connect_upload_status',
                        condition: {
                            template: '${ data._status_code == 202 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bank_connect.initiate_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'finbox_bank_connect_widget',
                        condition: {
                            template: '${ data._status_code != 202 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            finbox_bank_connect_upload_status: {
                type: 'api_request',
                config: {
                    url: '${SECRET.finbox.host}/bank-connect/v1/session_data/${STORE.bank_connect.session.session_id}/session_upload_status/',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.finbox.api_key}',
                        'server-hash': '${SECRET.finbox.server_hash}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'finbox_bank_connect_summary',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bank_connect.upload_status',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'finbox_bank_connect_widget',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            finbox_bank_connect_summary: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Processing your Bank Statement',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Bank Statement has been uploaded successfully. Please wait until we process your bank statement(s).',
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Re-Check Processing Status',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'finbox_bank_connect_refresh_insights',
                    },
                    {
                        action: 'navigate',
                        target_node: 'finbox_bank_connect_form',
                    },
                ],
            },
            finbox_bank_connect_refresh_insights: {
                type: 'api_request',
                config: {
                    url: '${SECRET.finbox.host}/bank-connect/v1/session_data/${STORE.bank_connect.session.session_id}/insights/',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '${SECRET.finbox.api_key}',
                        'server-hash': '${SECRET.finbox.server_hash}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'finbox_bank_connect_summary',
                        condition: {
                            template:
                                '${ data._status_code == 200 and len(list(data.accounts)) == 0 }.safe_eval',
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'salary_slips',
                        condition: {
                            template:
                                "${ data._status_code == 200 and STORE.applicant.employment_type == 'Salaried' and len(list(data.accounts)) > 0 and len(list(data.accounts[0].data.monthly_analysis.total_amount_of_salary.values())) > 0 }.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bank_connect.insights_id',
                                        value: "${update_warehouse(data, ['finbox_bank_connect'])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bank_connect.pan',
                                        value: '${data.accounts[0].data.account_details.pan_number}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.bank_connect.report_id',
                                        value: "${create_document_from_file_url(data.aggregate_xlsx_report_url, 's3', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx')['document_id']}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.salary',
                                        value: '${(float(str(next((v for v in data.accounts[0].data.monthly_analysis.total_amount_of_salary.values() if isinstance(v, (int, float)) and v != 0), 0.0))) * 12)}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.financials.salary.source',
                                        value: 'Bank Statement',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.emis',
                                        value: '${data.accounts[0].data.predictors.avg_emi_txn}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.abb',
                                        value: '${data.accounts[0].data.predictors.adjusted_abb}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.verification.financials.abb.source',
                                        value: "${'Bank Statement' if data.accounts[0].data.predictors.adjusted_abb else ''}",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'salary_form',
                        condition: {
                            template:
                                '${ data._status_code == 200 and len(list(data.accounts)) > 0 and len(list(data.accounts[0].data.monthly_analysis.total_amount_of_salary.values())) == 0 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bank_connect.insights_id',
                                        value: "${update_warehouse(data, ['finbox_bank_connect'])}",
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.financials.abb',
                                        value: '${data.accounts[0].data.predictors.adjusted_abb}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'finbox_bank_connect_summary',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ['${data.error.message}'],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            salary_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            type: 'page',
                            layout: [
                                {
                                    type: 'heading',
                                    tag: 'h1',
                                    text: 'Salary Not Found',
                                },
                                {
                                    type: 'paragraph',
                                    tag: 'p',
                                    text: 'We could not find your salary from the uploaded bank statement. Please try with another bank account or fetch income from ITR',
                                },
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            icon: 'bi-filetype-pdf',
                                            iconBg: 'bg-primary text-white',
                                            label: 'Re-upload Salary Bank Statement',
                                            action: 'navigate',
                                            target_node: 'finbox_bank_connect_session',
                                        },
                                        {
                                            icon: 'bi-filetype-pdf',
                                            iconBg: 'bg-success text-white',
                                            label: 'Fetch Salary using ITR',
                                            action: 'navigate',
                                            target_node: 'itr_form',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [],
            },
            salary_slips: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                salary_slips: '${STORE.documents.salary_slips}',
                            },
                            schema: {
                                title: 'Upload Salary Slips',
                                type: 'object',
                                properties: {
                                    salary_slips: {
                                        type: 'string',
                                        title: 'Salary Slips',
                                    },
                                },
                            },
                            ui: {
                                salary_slips: {
                                    'ui:widget': 'file',
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.documents.salary_slips',
                                        value: '${data.salary_slips}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'calculate_offers',
                    },
                ],
            },
            calculate_offers: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.verification.financials.salary.comparison',
                                        value: '${(STORE.financials.salary or 0) / STORE.financials.income}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.offers',
                                        value: "<%loan_amount = STORE.requirement.loan_amount; credit_score = STORE.cibil_v2.score; salary = (STORE.financials.salary or 0) / 12 if STORE.financials.salary else 0; income = STORE.financials.income / 12 if STORE.financials.income else 0; salary = salary if salary < income and salary != 0 else income; salary = salary if STORE.applicant.employment_type == 'Salaried' else salary * 1.3; bill_amount = STORE.documents.electricity_bill.bill_amount if STORE.documents.electricity_bill.bill_amount else 0; liabilities = STORE.financials.emis if STORE.financials.emis else salary * 0.15;      assessment_value = (salary - liabilities) / 2; offers = [{'months': 36, 'pf': 0, 'interest_rate': 7.9}, {'months': 48, 'pf': 0, 'interest_rate': 7.9}, {'months': 60, 'pf': 0, 'interest_rate': 7.9}     ]; emis = [round((loan_amount + ((loan_amount * o['interest_rate'] * o['months']) / (36500/30))) / o['months'], 0) for o in offers]; final_offers = [{'tenure': o['months'], 'tenure_type': 'months', 'pf': o['pf'], 'interest_rate': o['interest_rate'], 'emi': emis[i]} for i, o in enumerate(offers) if (              (loan_amount <= 300000 and credit_score > 720) or (loan_amount > 300000 and credit_score > 720 and (emis[i] <= 1.25 * bill_amount or emis[i] <= assessment_value)) or (loan_amount > 300000 and credit_score <= 720 and credit_score > 660 and (emis[i] <= 1.25 * bill_amount and emis[i] <= assessment_value)))];%>${final_offers}.safe_eval",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'offers_list',
                    },
                ],
            },
            offers_list: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            condition:
                                '${not STORE.offers or len(list(STORE.offers)) == 0}.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    className: 'text-center',
                                    elements: [
                                        {
                                            type: 'icon',
                                            className: 'bi bi-check-circle text-success',
                                        },
                                        {
                                            type: 'heading',
                                            tag: 'h5',
                                            text: 'Application Submitted Successfully!',
                                        },
                                        {
                                            type: 'paragraph',
                                            tag: 'p',
                                            text: 'Thank you for completing your application! Our team will now conduct quality checks, and once everything is verified, we will send you further details.',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            condition: '${len(list(STORE.offers)) > 0}.safe_eval',
                            form_data: {
                                selected_offer: '${STORE.selected_offer}.safe_eval',
                            },
                            schema: {
                                title: 'Select an EMI Plan',
                                description:
                                    'Congratulations, we have a few exciting offers exclusively for you',
                                type: 'object',
                                properties: {
                                    selected_offer: {
                                        type: 'number',
                                    },
                                },
                            },
                            ui: {
                                selected_offer: {
                                    'ui:label': false,
                                    'ui:widget': 'rich_radio',
                                    'ui:enumNamesLayout': {
                                        enum: '${STORE.offers}.safe_eval',
                                        avatar: {
                                            path: 'tenure',
                                        },
                                        body: [
                                            {
                                                float: true,
                                                elements: [
                                                    {
                                                        tag: 'h5',
                                                        className: 'me-1 mb-0',
                                                        path: 'emi',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        tag: 'h5',
                                                        prefix: 'x',
                                                        className: 'me-1 mb-0',
                                                        path: 'tenure',
                                                    },
                                                    {
                                                        tag: 'h5',
                                                        className: 'mb-0',
                                                        path: 'tenure_type',
                                                    },
                                                ],
                                            },
                                            {
                                                elements: [
                                                    {
                                                        tag: 'small',
                                                        path: 'interest_rate',
                                                        prefix: 'Interest Rate',
                                                        suffix: ' (Flat)',
                                                        transform: 'perc',
                                                    },
                                                    {
                                                        tag: 'small',
                                                        path: 'pf',
                                                        prefix: 'Processing Fee: ',
                                                        transform: 'perc',
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.requirement.selected_offer',
                                        value: '${STORE.offers[data.selected_offer]}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'outgoing_webhook_offer_selected',
                    },
                    {
                        action: 'navigate',
                        target_node: 'requirement',
                    },
                ],
            },
            outgoing_webhook_offer_selected: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'offer_selected',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: {
                            project: '${STORE.project}.safe_eval',
                            loan_amount: '${STORE.requirement.loan_amount}.safe_eval',
                            generated_offers: '${STORE.offers}.safe_eval',
                            selected_offer: '${STORE.requirement.selected_offer}.safe_eval',
                            bureau_summary: '${STORE.bureau_summary}.safe_eval',
                        },
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'epc_details',
                    },
                ],
            },
            epc_details: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                documents: '${STORE.documents}.safe_eval',
                                project: '${STORE.project}.safe_eval',
                            },
                            schema: {
                                title: 'One Last Step',
                                type: 'object',
                                properties: {
                                    project: {
                                        type: 'object',
                                        properties: {
                                            epc: {
                                                type: 'string',
                                                title: 'Select EPC',
                                            },
                                            proforma_invoice: {
                                                type: 'string',
                                                title: 'Upload Proforma Invoice',
                                            },
                                        },
                                        required: ['epc', 'proforma_invoice'],
                                    },
                                    documents: {
                                        type: 'object',
                                        properties: {
                                            electricity_bill: {
                                                type: 'object',
                                                properties: {
                                                    file: {
                                                        type: 'string',
                                                        title: 'Upload Electricity Bill',
                                                    },
                                                },
                                                required: ['file'],
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                documents: {
                                    'ui:label': false,
                                    electricity_bill: {
                                        'ui:label': false,
                                        file: {
                                            'ui:widget': 'file',
                                        },
                                    },
                                },
                                project: {
                                    'ui:label': false,
                                    epc: {
                                        'ui:widget': 'async_select',
                                        creatable: true,
                                        'ui:placeholder': 'Start typing to search EPC',
                                        'ui:async_select': {
                                            runner_id: '${RUNNER_ID}',
                                            options: {
                                                node: 'solfin_epc_list',
                                                value: 'epcCode',
                                                label: 'epcName',
                                            },
                                            valueDetails: {
                                                node: 'solfin_epc_details',
                                                label: 'epcName',
                                            },
                                        },
                                    },
                                    proforma_invoice: {
                                        'ui:widget': 'file',
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                        target_node: 'epc_bank',
                    },
                    {
                        action: 'navigate',
                        target_node: 'offers_list',
                        action_name: 'Offers List',
                    },
                ],
            },
            solfin_epc_details: {
                type: 'api_request',
                config: {
                    url: '${SECRET.solfin.host}/epc/${QUERY.epc}/bank-details',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.solfin.authorization}',
                    },
                    body: {},
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [],
            },
            solfin_epc_list: {
                type: 'api_request',
                config: {
                    url: '${SECRET.solfin.host}/epc/search?searchString=${QUERY.q}',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.solfin.authorization}',
                    },
                    body: {},
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [],
            },
            epc_bank: {
                type: 'api_request',
                config: {
                    url: '${SECRET.solfin.host}/epc/${STORE.project.epc}/bank-details',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.solfin.authorization}',
                    },
                    body: {},
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.epc_details',
                                        value: '${data.data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.disbursal_bank',
                                        value: {
                                            ifsc: '${data.data.ifscCode}',
                                            account_no: '${data.data.accountNumber}',
                                            account_holder_name: '${data.data.epcName}',
                                            tags: ['disbursement'],
                                        },
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        pre_sanction_checklist: [
                                            {
                                                label: 'Installation Size (as per PI)?',
                                                type: 'boolean',
                                            },
                                            {
                                                label: 'Price per KW',
                                                type: 'string',
                                            },
                                            {
                                                label: 'Income',
                                                type: 'string',
                                                value: '${STORE.financials.income}',
                                                is_verified:
                                                    '${STORE.financials.income == STORE.financials.salary}.safe_eval',
                                            },
                                            {
                                                label: 'Physical Site Verification',
                                                type: 'string',
                                            },
                                            {
                                                label: 'Is EPC added as a payee in the system?',
                                                type: 'boolean',
                                            },
                                            {
                                                label: 'Has EPC approved PI',
                                                type: 'boolean',
                                            },
                                            {
                                                label: 'VCIP Completed?',
                                                type: 'boolean',
                                            },
                                            {
                                                label: 'AML Check',
                                                type: 'boolean',
                                            },
                                            {
                                                label: 'CKYC Fetch',
                                                type: 'boolean',
                                            },
                                        ],
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'approved',
                    },
                ],
            },
            approved: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.approvals.underwriting.status',
                                        value: 'pending',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        condition: {
                            template:
                                '${STORE.financials.salary and STORE.verification.financials.salary.comparison < 0.9 or STORE.verification.financials.salary.comparison > 1.1}.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'pending_underwriting',
                    },
                    {
                        action: 'auto',
                        target_node: 'check_co_applicant',
                    },
                ],
            },
            check_co_applicant: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        condition: {
                            template: '${STORE.co_applicant.required}.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'generate_co_applicant_link',
                    },
                    {
                        action: 'auto',
                        condition: {
                            template: '${not STORE.co_applicant.required}.safe_eval',
                            parser: 'mako',
                        },
                        target_node: 'sanction_letter_esign_form',
                    },
                ],
            },
            pending_underwriting: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            type: 'page',
                            action: '',
                            condition: "${ 'approve' not in USER.allowed_actions }.safe_eval",
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    id: '8bd80cbb-09f6-4811-8b93-3c5ed75bdc2d',
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Under Review',
                                                },
                                                {
                                                    id: '8bd80cbb-09f6-4811-8b93-3c5ed75bdc2d',
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Your application has been sent for review. We will notify you once your case is approved.',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'form',
                            action: 'approve',
                            condition: "${ 'approve' in USER.allowed_actions }.safe_eval",
                            schema: {
                                type: 'object',
                                title: 'Approve Application?',
                                properties: {
                                    status: {
                                        type: 'string',
                                        enum: ['approved', 'rejected'],
                                    },
                                    comment: {
                                        type: 'string',
                                    },
                                },
                                required: ['status'],
                            },
                            ui: {
                                status: {
                                    'ui:placeholder': 'Select Status',
                                },
                                comment: {
                                    'ui:widget': 'textarea',
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['Admin (Default)'],
                        actions: ['approve', 'reject'],
                    },
                ],
                edges: [
                    {
                        action: 'approve',
                        condition: {
                            template: "${ data.status == 'approved' }.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'check_co_applicant',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.approvals.underwriting.status',
                                        value: 'approved',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.approvals.underwriting.comment',
                                        value: '${data.comment}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'approve',
                        condition: {
                            template: "${ data.status == 'rejected' }.safe_eval",
                            parser: 'mako',
                        },
                        target_node: 'application_rejected',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.approvals.underwriting.status',
                                        value: 'pending',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.approvals.underwriting.comment',
                                        value: '${data.comment}',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.status',
                                        value: 'REJECTED',
                                    },
                                    parser: 'mako',
                                },
                                {
                                    template: {
                                        path: 'STORE.reject_reason',
                                        value: '${data.comment}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                ],
            },
            generate_co_applicant_link: {
                type: 'flow_connector',
                config: {
                    flow_id: '${PARAMS[PARAMS.environment].co_applicant_flow_id}',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'navigate',
                        target_node: 'send_email_to_co_applicant',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.co_applicant.runner_id',
                                        value: '${data.id}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                target_node: 'sanction_letter_esign_form',
                                params: {
                                    link: '${PARAMS[PARAMS.environment].rego_host}/auth/?username=${STORE.co_applicant.mobile_no}&send_otp=true&runner_id=${data.id}&flow_id=${PARAMS[PARAMS.environment].co_applicant_flow_id}',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            send_email_to_co_applicant: {
                type: 'task',
                config: {
                    name: 'send_email',
                    params: {
                        from: 'support@solfin.co.in',
                        to: ['${STORE.co_applicant.email}'],
                        cc: [],
                        bcc: [],
                        template_id: '${PARAMS[PARAMS.environment].co_applicant_email_template_id}',
                        subject:
                            'Complete your KYC as Co-Applicant | Dhanvikas Fiscal Services Pvt. Ltd.',
                    },
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'navigate',
                        target_node: 'sanction_letter_esign_form',
                    },
                ],
            },
            two_way_video_kyc_initiate: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            type: 'page',
                            layout: [
                                {
                                    type: 'heading',
                                    tag: 'h1',
                                    text: 'Video KYC',
                                },
                                {
                                    type: 'paragraph',
                                    tag: 'p',
                                    text: 'Click the button below to start Video KYC',
                                },
                                {
                                    type: 'action',
                                    text: 'Start Video KYC',
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'digio_2_way_video_kyc_initiate',
                    },
                    {
                        action: 'navigate',
                        target_node: 'epc_details',
                    },
                ],
            },
            digio_2_way_video_kyc_initiate: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/client/kyc/v2/request/with_template',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    body: {
                        customer_identifier: '${STORE.applicant.email}',
                        customer_name: '${STORE.applicant.name}',
                        template_name: '${PARAMS[PARAMS.environment].vcip_flow}',
                        notify_customer: true,
                        generate_access_token: true,
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.video_kyc.init_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                alert: {
                                    type: 'success',
                                    title: 'Success!',
                                    message:
                                        'Video KYC has been initiated successfully. Please ask the applicant to click the link in email to start video kyc',
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            two_way_video_kyc_summary: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            type: 'page',
                            layout: [
                                {
                                    className: 'text-center',
                                    elements: [
                                        {
                                            type: 'icon',
                                            className: 'bi bi-check-circle text-success',
                                        },
                                        {
                                            type: 'heading',
                                            tag: 'h1',
                                            text: 'Video KYC link sent',
                                        },
                                        {
                                            type: 'digio_web_sdk',
                                            enviornment: 'sandbox',
                                            rootUrl: 'https://ext-gateway.digio.in',
                                            options: {
                                                id: '${STORE.video_kyc.init_response.id}',
                                                token: '${STORE.video_kyc.init_response.access_token.id}',
                                                identifier:
                                                    '${STORE.video_kyc.init_response.customer_identifier}',
                                            },
                                            target_node: 'digio_2_way_video_kyc_refresh',
                                        },
                                        {
                                            type: 'paragraph',
                                            tag: 'p',
                                            text: 'Please click the link received in your email to complete Video KYC',
                                        },
                                        {
                                            type: 'action',
                                            text: 'Refresh Video KYC Status',
                                            skip: {
                                                skipTo: 'sanction_letter_esign_form',
                                                skipText: "I'll do it later",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'digio_2_way_video_kyc_refresh',
                    },
                ],
            },
            digio_2_way_video_kyc_refresh: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/client/kyc/v2/${STORE.video_kyc.init_response.id}/response',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.video_kyc.vkyc_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            sanction_letter_esign_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            condition:
                                "${ not STORE.sanction_letter.sanction_letter_id and 'sanction_letter_generate' not in USER.allowed_actions }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Application Submitted Successfully!',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Thank you for completing your application! Our team will now conduct quality checks, and once everything is verified, we will send you the sanction details.',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'We appreciate your patience and look forward to serving you!',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            condition:
                                "<% data = STORE.pre_sanction_checklist; all_verified = all(item.get('is_verified', False) for item in data) %>${not STORE.sanction_letter.sanction_letter_id and 'sanction_letter_generate' in USER.allowed_actions and not all_verified}.safe_eval",
                            action: 'submit',
                            form_data: {
                                pre_sanction_checklist: '${STORE.pre_sanction_checklist}.safe_eval',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    pre_sanction_checklist: {
                                        title: 'Pre Sanction Checklist',
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                label: {
                                                    type: 'string',
                                                },
                                                type: {
                                                    type: 'string',
                                                    enum: ['string', 'boolean', 'number'],
                                                },
                                                value: {
                                                    type: ['string', 'boolean', 'number'],
                                                },
                                                comments: {
                                                    type: 'array',
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                'ui:submitButtonOptions': {
                                    submitText: 'Save',
                                },
                                pre_sanction_checklist: {
                                    'ui:widget': 'checklist',
                                },
                            },
                        },
                        {
                            action: 'sanction_letter_generate',
                            condition:
                                "<% data = STORE.pre_sanction_checklist; all_verified = all(item.get('is_verified', False) for item in data) %>${not STORE.sanction_letter.sanction_letter_id and 'sanction_letter_generate' in USER.allowed_actions and all_verified and (STORE.co_applicant.status == 'completed' or not STORE.co_applicant.required)}.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className:
                                                        'bi bi-file-earmark-text text-primary',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Application Completed',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Your application has been completed.',
                                                },
                                                {
                                                    type: 'action',
                                                    text: 'Generate Sanction Letter',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'sanction_letter_generate',
                            condition:
                                "<% data = STORE.pre_sanction_checklist; all_verified = all(item.get('is_verified', False) for item in data) %>${not STORE.sanction_letter.sanction_letter_id and 'sanction_letter_generate' in USER.allowed_actions and all_verified and (STORE.co_applicant.status != 'completed' and STORE.co_applicant.required)}.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className:
                                                        'bi bi-exclamation-circle text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Co-Applicant KYC Pending',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Co-Applicant KYC needs to be done before a sanction letter can be generated',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'digio_sanction_letter_esign_initiate',
                            condition:
                                '${ check_condition(STORE.sanction_letter.sanction_letter_id) }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'document_preview',
                                                    document_id:
                                                        '${STORE.sanction_letter.sanction_letter_id}',
                                                },
                                                {
                                                    type: 'action',
                                                    text: 'Initiate eSign',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['digio_sanction_letter_esign_initiate', 'navigate'],
                    },
                    {
                        roles: ['Admin (Default)'],
                        actions: ['sanction_letter_generate', 'submit'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'sanction_letter_esign_form',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                    },
                    {
                        action: 'sanction_letter_generate',
                        target_node: 'sanction_letter_generate_ref',
                        action_name: 'Generate Sanction Letter',
                    },
                    {
                        action: 'digio_sanction_letter_esign_initiate',
                        target_node: 'digio_sanction_letter_esign_initiate',
                        action_name: 'Initiate esign',
                    },
                ],
            },
            sanction_letter_generate_ref: {
                type: 'edge_operation',
                config: {},
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.ref_id',
                                        value: "SL/RESI/AUTO/FY25/${(str(count_stores_path('sanction_letter.sanction_letter_id', [FLOW_ID]) + 1)).zfill(4)}",
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'sanction_letter_generate',
                    },
                ],
            },
            sanction_letter_generate: {
                type: 'task',
                config: {
                    name: 'generate_pdf',
                    template_id: '${PARAMS[PARAMS.environment].sanction_letter_template_id}',
                    vendor: '${SECRET.storage.vendor}',
                    params: {
                        footer_right: '[page] of [topage]',
                        footer_font_size: 8,
                        minimum_font_size: 9,
                        page_size: 'A4',
                        dpi: 96,
                    },
                    paraer: 'jinja',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'sanction_letter_esign_form',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.sanction_letter_id',
                                        value: '${data.document_id}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                message: 'Sanction Letter has been generated successfully',
                            },
                            parser: 'none',
                        },
                    },
                ],
            },
            digio_sanction_letter_esign_initiate: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/uploadpdf',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    body: {
                        sequential: true,
                        signers:
                            "<% result = [{'identifier': STORE.applicant.mobile_no, 'name': STORE.applicant.name, 'sign_type': 'aadhaar', 'reason': 'Primary Applicant eSign for Loan Sanction Letter'}] + ([{'identifier': STORE.co_applicant.mobile_no, 'name': STORE.co_applicant.name, 'sign_type': 'aadhaar', 'reason': 'Co-Applicant eSign For Loan Sanction Letter'}] if STORE.co_applicant.required else []) + [{'identifier': 'credit@dhanvikas.com', 'name': 'Dhanvikas Fiscal Services Private Limited', 'sign_type': 'aadhaar', 'reason': 'eSign on the behalf of Dhanvikas Fiscal Services Private Limited for Loan Sanction Letter'}];%>${result}.safe_eval",
                        signature_verification:
                            "<% result = {STORE.applicant.mobile_no: {'abort_on_fail': True, 'max_attempt': 3, 'rules': [{'operation': 'AND', 'conditions': [{'field': 'aadhaar', 'match_type': 'exact', 'value': STORE.applicant.aadhaar[-4:]}]}]}} %>${result if STORE.applicant.aadhaar else '{}'}.safe_eval",
                        expire_in_days: 10,
                        display_on_page: 'all',
                        notify_signers: true,
                        send_sign_link: true,
                        generate_access_token: true,
                        file_name: 'Solfin_Sanction_Letter.pdf',
                        file_data:
                            '${get_pdf_file_as_base64(STORE.sanction_letter.sanction_letter_id)}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'sanction_letter_esign',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                message: 'eSign has been initiated successfully',
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'sanction_letter_esign_form',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            sanction_letter_esign: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            condition:
                                '${ not bool(STORE.sanction_letter.signed_sanction_letter_id) }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-hourglass-split text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Sanction Letter ${capitalize(str(STORE.sanction_letter.esign_response.signing_parties[0].status))}',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: "${'Awaiting eSign from Co-Applicant' if STORE.co_applicant.required and STORE.sanction_letter.esign_response.signing_parties[1].status != 'signed' else 'Please click the link in email and eSign the document to proceed'}",
                                                },
                                                {
                                                    type: 'vertical_nav',
                                                    items: [
                                                        {
                                                            href: 'https://app.digio.in/#/gateway/login/${STORE.sanction_letter.esign_response.id}/${uuid4_hex()}/${STORE.sanction_letter.esign_response.signing_parties[0].identifier}',
                                                            icon: 'bi-filetype-pdf',
                                                            iconBg: 'bg-primary text-white',
                                                            label: 'eSign Now',
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Refresh eSign Status',
                                                    runOnWindowBlur: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            condition:
                                '${ bool(STORE.sanction_letter.signed_sanction_letter_id) }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h3',
                                                    text: 'Sanction Letter Signed Successfully',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Register mandate now to continue',
                                                },
                                                {
                                                    className: 'mb-2',
                                                    type: 'document_link',
                                                    text: 'View Signed Document',
                                                    document_id:
                                                        '${STORE.sanction_letter.signed_sanction_letter_id}',
                                                },
                                                {
                                                    type: 'buttons_list',
                                                    className: 'my-3',
                                                    items: [
                                                        {
                                                            action: 'navigate',
                                                            className: 'btn btn-danger me-2',
                                                            target_node:
                                                                'digio_sanction_letter_esign_refresh',
                                                            text: 'Refresh eSign Status',
                                                        },
                                                        {
                                                            action: 'submit',
                                                            target_node: 'enach_form',
                                                            className: 'btn btn-info',
                                                            text: 'Proceed to Mandate Registration',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'digio_sanction_letter_esign_refresh',
                    },
                    {
                        action: 'submit',
                        target_node: 'enach_form',
                        condition: {
                            template:
                                "${ STORE.sanction_letter.esign_response.signing_parties[0].status == 'signed' }.safe_eval",
                            parser: 'mako',
                        },
                    },
                ],
            },
            digio_sanction_letter_esign_refresh: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/${STORE.sanction_letter.esign_response.id}',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'digio_sanction_letter_esign_download',
                        condition: {
                            template:
                                "${ data._status_code == 200 and (next((p for p in data.signing_parties if p.signing_index == 0), {}).get('status') == 'signed' and (not STORE.co_applicant.required or next((p for p in data.signing_parties if p.signing_index == 1), {}).get('status') == 'signed')) }.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            digio_sanction_letter_esign_download: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/download?document_id=${STORE.sanction_letter.esign_response.id}',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    vendor: '${SECRET.storage.vendor}',
                    response_type: 'file',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'outgoing_webhook_sanction_letter_signed',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.sanction_letter.signed_sanction_letter_id',
                                        value: '${data.document_id}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            outgoing_webhook_sanction_letter_signed: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'sanction_letter_signed',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: {
                            signed_sanction_letter_id:
                                '${STORE.sanction_letter.signed_sanction_letter_id}',
                        },
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: {
                            template: '${LAST_BLOCKING_NODE.name}',
                            parser: 'mako',
                        },
                    },
                ],
            },
            enach_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                enach: {
                                    maximum_amount: '${STORE.requirement.loan_amount}',
                                    auth_mode: '${STORE.enach.auth_mode}',
                                    bank: {
                                        account_type: '${STORE.enach.bank.account_type}.safe_eval',
                                        ifsc: '${STORE.enach.bank.ifsc}.safe_eval',
                                        bank_name: '${STORE.enach.bank.bank_name}.safe_eval',
                                        account_holder_name:
                                            '${STORE.enach.bank.account_holder_name or STORE.applicant.name}.safe_eval',
                                    },
                                },
                            },
                            schema: {
                                title: 'Mandate',
                                type: 'object',
                                properties: {
                                    enach: {
                                        type: 'object',
                                        properties: {
                                            maximum_amount: {
                                                type: 'string',
                                                title: 'Maximum Amount of Mandate',
                                            },
                                            auth_mode: {
                                                type: 'string',
                                                title: 'Register Mandate using',
                                                enum: ['esign', 'api'],
                                            },
                                            bank: {
                                                type: 'object',
                                                properties: {
                                                    account_type: {
                                                        type: 'string',
                                                        title: 'Account Type',
                                                        enum: ['savings', 'current', 'cc', 'other'],
                                                    },
                                                    ifsc: {
                                                        type: 'string',
                                                        title: 'IFSC',
                                                    },
                                                    branch_name: {
                                                        type: 'string',
                                                    },
                                                    bank_name: {
                                                        type: 'string',
                                                        title: 'Bank Name',
                                                    },
                                                    account_holder_name: {
                                                        type: 'string',
                                                        title: 'Account Holder Name',
                                                    },
                                                },
                                                required: [
                                                    'account_type',
                                                    'ifsc',
                                                    'bank_name',
                                                    'account_holder_name',
                                                ],
                                            },
                                        },
                                        required: [],
                                    },
                                },
                            },
                            ui: {
                                'ui:submitButtonOptions': {
                                    submitText: 'Initiate eNACH',
                                    skipTo: 'loan_agreement_esign_form',
                                    skipText: 'Skip this Step!',
                                },
                                enach: {
                                    'ui:label': false,
                                    maximum_amount: {
                                        'ui:disabled': true,
                                    },
                                    auth_mode: {
                                        'ui:placeholder': 'Select Auth Mode',
                                        'ui:enumNames': ['Aadhaar Based', 'Net Banking/Debit Card'],
                                    },
                                    bank: {
                                        'ui:label': false,
                                        bank_name: {
                                            'ui:widget': 'select2',
                                            'ui:select2': {
                                                creatable: true,
                                                enum: [
                                                    'AU SMALL FINANCE BANK',
                                                    'AXIS BANK',
                                                    'BANK OF BARODA',
                                                    'BANK OF INDIA',
                                                    'BANK OF MAHARASHTRA',
                                                    'CANARA BANK',
                                                    'CENTRAL BANK OF INDIA',
                                                    'CITY UNION BANK LTD',
                                                    'CSB Bank Limited',
                                                    'DCB BANK LTD',
                                                    'DEUTSCHE BANK AG',
                                                    'DHANALAXMI BANK',
                                                    'FEDERAL BANK',
                                                    'HDFC BANK LTD',
                                                    'ICICI BANK LTD',
                                                    'IDBI BANK',
                                                    'IDFC FIRST BANK LTD',
                                                    'INDIAN BANK',
                                                    'INDIAN OVERSEAS BANK',
                                                    'INDUSIND BANK',
                                                    'KARNATAKA BANK LTD',
                                                    'KARUR VYSA BANK',
                                                    'KOTAK MAHINDRA BANK LTD',
                                                    'ORIENTAL BANK OF COMMERCE',
                                                    'PAYTM PAYMENTS BANK LTD',
                                                    'PUNJAB NATIONAL BANK',
                                                    'RBL BANK LIMITED',
                                                    'SHREE KADI NAGARIK SAHAKARI BANK LTD',
                                                    'NAGARIK SAHAKARI BANK LTD</option>',
                                                    'STANDARD CHARTERED BANK',
                                                    'THE ADINATH CO OP BANK LTD',
                                                    'HONGKONG AND SHANGHAI BANKING CORPORATION LTD',
                                                    'THE SOUTH INDIAN BANK LIMITED',
                                                    'UCO BANK',
                                                    'UNION BANK OF INDIA',
                                                    'YES BANK',
                                                ],
                                            },
                                        },
                                        branch_name: {
                                            'ui:widget': 'hidden',
                                        },
                                        ifsc: {
                                            'ui:widget': 'ifsc',
                                            autofill: {
                                                'enach.bank.bank_name': 'BANK',
                                                'enach.bank.branch_name': 'BRANCH',
                                                'enach.bank.bank_address': 'ADDRESS',
                                            },
                                        },
                                        account_type: {
                                            'ui:placeholder': 'Select Account Type',
                                            'ui:enumNames': [
                                                'Savings',
                                                'Current',
                                                'Cash Credit',
                                                'Other',
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                        target_node: 'digio_enach_initiate',
                    },
                ],
            },
            digio_enach_initiate: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v3/client/mandate/create_form',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    body: {
                        customer_identifier: '${STORE.applicant.mobile_no}',
                        auth_mode: '${STORE.enach.auth_mode}',
                        mandate_type: 'create',
                        corporate_config_id: '${SECRET.digio.corporate_config_id}',
                        notify_customer: true,
                        generate_access_token: true,
                        include_authentication_url: true,
                        mandate_data: {
                            maximum_amount: '${STORE.enach.maximum_amount}',
                            instrument_type: 'debit',
                            first_collection_date: "${strftime(now(), '%Y-%m-%d')}",
                            is_recurring: true,
                            frequency: 'Adhoc',
                            management_category: 'L001',
                            customer_name: '${STORE.enach.bank.account_holder_name}',
                            customer_account_number: '${STORE.enach.bank.account_no}',
                            destination_bank_id: '${STORE.enach.bank.ifsc}',
                            destination_bank_name: '${STORE.enach.bank.bank_name}',
                            customer_account_type: '${STORE.enach.bank.account_type}',
                            customer_ref_number: '${STORE.applicant.mobile_no}',
                            scheme_ref_number: '${STORE.applicant.mobile_no}',
                        },
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'enach',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.enach.mandate_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                message: 'Mandate registration has been initiated successfully',
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'enach_form',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            enach: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            condition:
                                "${ (STORE.enach.mandate_response.bank_details.state == 'transfer_success' or STORE.enach.mandate_response.bank_details.state == 'awaiting_res' or STORE.enach.mandate_response.bank_details.state == 'auth_success') and 'navigate' not in USER.allowed_actions }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-hourglass-split text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Awaiting Mandate Status from Bank',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Please check back later for loan agreement signing',
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Refresh Mandate Status',
                                                    runOnWindowBlur: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            condition:
                                "${ (STORE.enach.mandate_response.bank_details.state == 'transfer_success' or STORE.enach.mandate_response.bank_details.state == 'awaiting_res' or STORE.enach.mandate_response.bank_details.state == 'auth_success') and 'navigate' in USER.allowed_actions }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-hourglass-split text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Awaiting Mandate Status from Bank',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Please click the link below to register Mandate',
                                                },
                                                {
                                                    type: 'vertical_nav',
                                                    items: [
                                                        {
                                                            action: 'navigate',
                                                            target_node:
                                                                'loan_agreement_esign_form',
                                                            label: 'Initiate Loan Agreement Signing',
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Refresh Mandate Status',
                                                    runOnWindowBlur: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            condition:
                                "${ STORE.enach.mandate_response.bank_details.state != 'register_success' and STORE.enach.mandate_response.bank_details.state != 'transfer_success' and STORE.enach.mandate_response.bank_details.state != 'awaiting_res' and STORE.enach.mandate_response.bank_details.state != 'auth_success' }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-hourglass-split text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Mandate Pending',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Please click the link below to register Mandate',
                                                },
                                                {
                                                    type: 'vertical_nav',
                                                    items: [
                                                        {
                                                            href: '${STORE.enach.mandate_response.authentication_url}',
                                                            icon: 'bi-filetype-pdf',
                                                            iconBg: 'bg-primary text-white',
                                                            label: 'Register Mandate',
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Refresh Mandate Status',
                                                    runOnWindowBlur: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['api_request', 'submit'],
                    },
                    {
                        roles: ['Admin (Default)'],
                        actions: ['navigate'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'digio_enach_refresh',
                    },
                ],
            },
            digio_enach_refresh: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v3/client/mandate/${STORE.enach.mandate_response.id}',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'outgoing_webhook_enach_registered',
                        condition: {
                            template:
                                "${ data._status_code == 200 and data.bank_details.state == 'register_success' }.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.enach.mandate_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                alert: {
                                    type: 'success',
                                    title: 'Success!',
                                    message: 'Mandate status has been refreshed successfully',
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'enach',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.enach.mandate_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                alert: {
                                    type: 'success',
                                    title: 'Success!',
                                    message: 'Mandate status has been refreshed successfully',
                                },
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'enach',
                        condition: {
                            template:
                                "${ data._status_code == 200 and data.state == 'auth_success' }.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.enach.mandate_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'enach',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            outgoing_webhook_enach_registered: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'enach_registered',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: {
                            mandate_id: '${STORE.enach.mandate_response.mandate_id}',
                            umrn: '${STORE.enach.mandate_response.umrn}',
                            bank_details: '${STORE.enach.mandate_response.bank_details}.safe_eval',
                        },
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'loan_agreement_esign_form',
                    },
                ],
            },
            loan_agreement_esign_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            condition:
                                "${ not STORE.loan_agreement.loan_agreement_id and 'loan_agreement_generate' not in USER.allowed_actions }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Mandate Registered Successfully!',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Mandate has been registered successfully. Our team will now generate the loan agreement, we will send you the loan agreement signing details.',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'We appreciate your patience and look forward to serving you!',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'loan_agreement_generate',
                            condition:
                                "${ not STORE.loan_agreement.loan_agreement_id and 'loan_agreement_generate' in USER.allowed_actions }.safe_eval",
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className:
                                                        'bi bi-file-earmark-text text-primary',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Mandate Registered Successfully',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Applicant has registered mandate successfully',
                                                },
                                                {
                                                    type: 'action',
                                                    text: 'Generate Loan Agreement',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'digio_loan_agreement_esign_initiate',
                            condition:
                                '${ check_condition(STORE.loan_agreement.loan_agreement_id) }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'document_preview',
                                                    document_id:
                                                        '${STORE.loan_agreement.loan_agreement_id}',
                                                },
                                                {
                                                    type: 'action',
                                                    text: 'Initiate eSign',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['digio_loan_agreement_esign_initiate', 'navigate'],
                    },
                    {
                        roles: ['Admin (Default)'],
                        actions: ['loan_agreement_generate'],
                    },
                ],
                edges: [
                    {
                        action: 'loan_agreement_generate',
                        target_node: 'loan_agreement_generate',
                        action_name: 'Generate Loan Agreement',
                    },
                    {
                        action: 'digio_loan_agreement_esign_initiate',
                        target_node: 'digio_loan_agreement_esign_initiate',
                        action_name: 'Initiate esign',
                    },
                    {
                        action: 'navigate',
                        target_node: 'epc_details',
                    },
                ],
            },
            loan_agreement_generate: {
                type: 'task',
                config: {
                    name: 'generate_pdf',
                    template_id: '${PARAMS[PARAMS.environment].loan_agreement_template_id}',
                    vendor: '${SECRET.storage.vendor}',
                    params: {
                        footer_right: '[page] of [topage]',
                        footer_font_size: 8,
                        minimum_font_size: 9,
                        page_size: 'A4',
                        dpi: 96,
                    },
                    paraer: 'jinja',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'loan_agreement_esign_form',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan_agreement.loan_agreement_id',
                                        value: '${data.document_id}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                message: 'Loan Agreement has been generated successfully',
                            },
                            parser: 'none',
                        },
                    },
                ],
            },
            digio_loan_agreement_esign_initiate: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/uploadpdf',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    body: {
                        sequential: true,
                        signers:
                            "<% result = [{'identifier': STORE.applicant.mobile_no, 'name': STORE.applicant.name, 'sign_type': 'aadhaar', 'reason': 'Primary Applicant eSign for Loan Agreement'}] + ([{'identifier': STORE.co_applicant.mobile_no, 'name': STORE.co_applicant.name, 'sign_type': 'aadhaar', 'reason': 'Co-Applicant eSign For Loan Agreement'}] if STORE.co_applicant.required else []) + [{'identifier': 'credit@dhanvikas.com', 'name': 'Dhanvikas Fiscal Services Private Limited', 'sign_type': 'aadhaar', 'reason': 'eSign on the behalf of Dhanvikas Fiscal Services Private Limited for Loan Agreement'}];%>${result}.safe_eval",
                        signature_verification:
                            "<% result = {STORE.applicant.mobile_no: {'abort_on_fail': True, 'max_attempt': 3, 'rules': [{'operation': 'AND', 'conditions': [{'field': 'aadhaar', 'match_type': 'exact', 'value': STORE.applicant.aadhaar[-4:]}]}]}} %>${result if STORE.applicant.aadhaar else '{}'}.safe_eval",
                        expire_in_days: 10,
                        display_on_page: 'all',
                        notify_signers: true,
                        send_sign_link: true,
                        generate_access_token: true,
                        file_name: 'Solfin_loan_agreement.pdf',
                        file_data:
                            '${get_pdf_file_as_base64(STORE.loan_agreement.loan_agreement_id)}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'loan_agreement_esign',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan_agreement.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                message: 'eSign has been initiated successfully',
                            },
                            parser: 'none',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'loan_agreement_esign_form',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                    result: '${data}.safe_eval',
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            loan_agreement_esign: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            condition:
                                '${ not STORE.loan_agreement.signed_loan_agreement_id }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-hourglass-split text-warning',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h1',
                                                    text: 'Loan Agreement ${capitalize(str(STORE.loan_agreement.esign_response.signing_parties[0].status))}',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Please click the link in email and eSign the document to proceed',
                                                },
                                                {
                                                    type: 'vertical_nav',
                                                    items: [
                                                        {
                                                            href: 'https://app.digio.in/#/gateway/login/${STORE.loan_agreement.esign_response.id}/${uuid4_hex()}/${STORE.loan_agreement.esign_response.signing_parties[0].identifier}',
                                                            icon: 'bi-filetype-pdf',
                                                            iconBg: 'bg-primary text-white',
                                                            label: 'eSign Now',
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'api_request',
                                                    text: 'Refresh eSign Status',
                                                    runOnWindowBlur: true,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            action: 'submit',
                            condition:
                                '${ STORE.loan_agreement.signed_loan_agreement_id }.safe_eval',
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            className: 'text-center',
                                            elements: [
                                                {
                                                    type: 'icon',
                                                    className: 'bi bi-check-circle text-success',
                                                },
                                                {
                                                    type: 'heading',
                                                    tag: 'h3',
                                                    text: 'Loan Agreement Signed Successfully',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Your application is being processed. We will notify you once you are ready for disbursal.',
                                                },
                                                {
                                                    className: 'mb-2',
                                                    type: 'document_link',
                                                    text: 'View Signed Document',
                                                    document_id:
                                                        '${STORE.loan_agreement.signed_loan_agreement_id}',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'api_request',
                        target_node: 'digio_loan_agreement_esign_refresh',
                    },
                    {
                        action: 'submit',
                        target_node: 'enach_form',
                        condition: {
                            template:
                                "${ STORE.loan_agreement.esign_response.signing_parties[0].status == 'signed' }.safe_eval",
                            parser: 'mako',
                        },
                    },
                ],
            },
            digio_loan_agreement_esign_refresh: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/${STORE.loan_agreement.esign_response.id}',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'digio_loan_agreement_esign_download',
                        condition: {
                            template:
                                "${ data._status_code == 200 and (next((p for p in data.signing_parties if p.signing_index == 0), {}).get('status') == 'signed' and (not STORE.co_applicant.required or next((p for p in data.signing_parties if p.signing_index == 1), {}).get('status') == 'signed')) }.safe_eval",
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan_agreement.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'auto',
                        target_node: 'loan_agreement_esign',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan_agreement.esign_response',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'loan_agreement_esign',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            digio_loan_agreement_esign_download: {
                type: 'api_request',
                config: {
                    url: '${SECRET.digio.host}/v2/client/document/download?document_id=${STORE.loan_agreement.esign_response.id}',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Basic ${SECRET.digio.authorization}',
                    },
                    vendor: '${SECRET.storage.vendor}',
                    response_type: 'file',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'outgoing_webhook_loan_agreement_signed',
                        condition: {
                            template: '${ data._status_code == 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan_agreement.signed_loan_agreement_id',
                                        value: '${data.document_id}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                    },
                    {
                        action: 'submit',
                        target_node: 'loan_agreement_esign',
                        condition: {
                            template: '${ data._status_code != 200 }.safe_eval',
                            parser: 'mako',
                        },
                        update_data: {
                            template: {
                                errors: {
                                    __errors: ["${data['message']}"],
                                },
                            },
                            parser: 'mako',
                        },
                    },
                ],
            },
            outgoing_webhook_loan_agreement_signed: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'loan_agreement_signed',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: {
                            signed_loan_agreement_id:
                                '${STORE.loan_agreement.signed_loan_agreement_id}',
                        },
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'generate_auth_token',
                    },
                ],
            },
            generate_auth_token: {
                type: 'api_request',
                config: {
                    url: "${SECRET['crego']['host']}/v1/identity/auth/token/",
                    method: 'post',
                    data: {
                        username: "${SECRET['crego']['username']}",
                        password: "${SECRET['crego']['password']}",
                    },
                    params: {},
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'create_lead',
                    },
                ],
            },
            create_lead: {
                type: 'api_request',
                config: {
                    url: "${SECRET['crego']['host']}/v1/crm/leads/",
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ${data.access}',
                    },
                    body: {
                        runner_id: '${RUNNER_ID}',
                        program_id: '${PARAMS[PARAMS.environment].program_id}',
                        primary_mobile: '${STORE.applicant.mobile_no}',
                        applicants: [
                            {
                                applicant_type: 'person',
                                is_primary: true,
                                is_authorized_signatory: true,
                                name: '${STORE.applicant.name}',
                                pan_no: '${STORE.applicant.pan}',
                                primary_mobile: '${STORE.applicant.mobile_no}',
                                addresses: [
                                    {
                                        tags: ['current', 'registered'],
                                        address_line: '${STORE.applicant.address.address_line}',
                                        pincode: '${STORE.applicant.address.pincode}',
                                        city: '${STORE.applicant.address.city}',
                                        state: '${STORE.applicant.address.state}',
                                    },
                                ],
                                banks: ['${STORE.disbursal_bank}.safe_eval'],
                            },
                        ],
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'update_loan_params',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.crm',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                        },
                    },
                ],
            },
            update_loan_params: {
                type: 'api_request',
                config: {
                    url: "${SECRET['crego']['host']}/v1/lms/accounts/update_loan_params/",
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ${NODES.generate_auth_token.data.access}',
                    },
                    body: {
                        lead_id: '${STORE.crm._id}',
                        program_id: '${PARAMS[PARAMS.environment].program_id}',
                        line_detail: {
                            approved_line_amount: '${STORE.requirement.loan_amount}',
                        },
                        loan_data: {
                            emi_count: '${STORE.requirement.selected_offer.tenure}',
                            flat_roi_value: '${STORE.requirement.selected_offer.interest_rate}',
                            pf_value: '${STORE.requirement.selected_offer.pf}',
                            pf_value_type: 'percentage',
                        },
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'initiate_disbursement',
                    },
                ],
            },
            initiate_disbursement: {
                type: 'api_request',
                config: {
                    url: "${SECRET['crego']['host']}/v1/lms/accounts/initiate_disbursement/",
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ${NODES.generate_auth_token.data.access}',
                    },
                    body: {
                        crm_lead_id: '${STORE.crm._id}',
                        program_id: '${PARAMS[PARAMS.environment].program_id}',
                        product_type: 'term_loan',
                        ref_id: '${STORE.crm.ref_id}',
                    },
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'outgoing_webhook_loan_created',
                        condition: {
                            template:
                                '${ data._status_code == 200 or data._status_code == 201 }.safe_eval',
                            parser: 'mako',
                        },
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.loan',
                                        value: '${data}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                        },
                    },
                ],
            },
            outgoing_webhook_loan_created: {
                type: 'api_request',
                config: {
                    url: '${SECRET.webhooks.root}',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        event: 'loan_created',
                        timestamp: '${now()}',
                        application_id: '${RUNNER_ID}',
                        data: '${STORE}.safe_eval',
                    },
                    async: true,
                    response_type: 'json',
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'auto',
                        target_node: 'applicant_dashboard',
                    },
                ],
            },
            applicant_dashboard: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            type: 'page',
                            layout: [
                                {
                                    elements: [
                                        {
                                            tag: 'h5',
                                            text: 'Your monthly EMI is',
                                        },
                                        {
                                            tag: 'h1',
                                            text: '${STORE.requirement.selected_offer.emi}',
                                            transform: 'amount',
                                            className: 'mb-3',
                                        },
                                        {
                                            type: 'stats',
                                            title: 'My Loan Details',
                                            items: [
                                                {
                                                    text: 'Sanctioned Amount',
                                                    prefix: '₹',
                                                    value: '${humanize_number(STORE.requirement.loan_amount)}',
                                                },
                                                {
                                                    text: 'Tenure (in ${STORE.requirement.selected_offer.tenure_type})',
                                                    value: '${STORE.requirement.selected_offer.tenure}',
                                                },
                                                {
                                                    text: 'Flat Interest Rate',
                                                    value: '${STORE.requirement.selected_offer.interest_rate}',
                                                    transform: 'perc',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [],
            },
            application_rejected: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            type: 'page',
                            layout: [
                                {
                                    className: 'text-center',
                                    elements: [
                                        {
                                            type: 'icon',
                                            className: 'bi bi-x-circle text-danger',
                                        },
                                        {
                                            type: 'heading',
                                            tag: 'h5',
                                            text: 'Rejected!',
                                        },
                                        {
                                            type: 'paragraph',
                                            tag: 'p',
                                            text: '${STORE.reject_reason}. The application has been rejected.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [],
            },
            underwriting: {
                type: 'web_form',
                config: {
                    screen_layout: [
                        {
                            className: 'row',
                            elements: [
                                {
                                    className: 'col-md-6',
                                    elements: [
                                        {
                                            form_id: 'bureau_score',
                                            type: 'card',
                                            title: 'Bureau Score: ${STORE.cibil_v2.score}',
                                        },
                                        {
                                            form_id: 'bureau_report_api',
                                        },
                                        {
                                            form_id: 'bureau_report_uploaded',
                                        },
                                        {
                                            form_id: 'bureau_report_upload_form',
                                        },
                                        {
                                            form_id: 'itr_analytics',
                                        },
                                        {
                                            type: 'card',
                                            title: 'Financial Summary',
                                            form_id: 'financial_summary',
                                        },
                                        {
                                            type: 'card',
                                            title: 'Selected Loan Offer',
                                            form_id: 'selected_offer',
                                        },
                                    ],
                                },
                                {
                                    className: 'col-md-6',
                                    elements: [
                                        {
                                            type: 'card',
                                            bodyClass: 'p-0',
                                            title: 'BRE Reults',
                                            form_id: 'bre_result',
                                        },
                                        {
                                            type: 'card',
                                            title: 'Loan Offers Calculation Parameters',
                                            form_id: 'loan_offers_calculation',
                                        },
                                        {
                                            type: 'card',
                                            title: 'Generated Loan Offers',
                                            form_id: 'offers',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    forms: [
                        {
                            id: 'bureau_report_upload_form',
                            type: 'page',
                            condition:
                                '${ not STORE.cibil.pdf_id and not STORE.cibil_v2.report_id }.safe_eval',
                            layout: [
                                {
                                    className: 'card shadow shadow-sm',
                                    elements: [
                                        {
                                            className: 'card-body text-center py-20',
                                            elements: [
                                                {
                                                    type: 'heading',
                                                    tag: 'h5',
                                                    text: 'Bureau Report not Available',
                                                },
                                                {
                                                    type: 'paragraph',
                                                    tag: 'p',
                                                    text: 'Fetch CIBIL report automatically or upload a Bureau Report PDF below.',
                                                },
                                                {
                                                    type: 'buttons_list',
                                                    className: 'my-3',
                                                    items: [
                                                        {
                                                            action: 'navigate',
                                                            className: 'btn btn-danger me-2',
                                                            target_node: 'cibil_report_v2_pull',
                                                            text: 'Ping Bureau',
                                                        },
                                                        {
                                                            action: 'navigate',
                                                            className: 'btn btn-info',
                                                            target_node: 'cibil_report_upload_form',
                                                            text: 'Upload Bureau Report',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'bureau_score',
                            type: 'page',
                            condition: '${STORE.cibil_v2.score}',
                            layout: [
                                {
                                    type: 'score',
                                    value: '${STORE.cibil_v2.score}.safe_eval',
                                    height: '150',
                                },
                            ],
                        },
                        {
                            id: 'bureau_report_api',
                            type: 'page',
                            condition: '${STORE.cibil_v2.report_id}',
                            layout: [
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            href: '${PARAMS[PARAMS.environment].jupiter_host}/rego/cibil-report-v2/${STORE.cibil_v2.report_id}',
                                            condition: '${bool(STORE.cibil_v2)}.safe_eval',
                                            icon: 'bi-pie-chart',
                                            iconBg: 'bg-primary text-white',
                                            label: 'Applicant Bureau Report',
                                        },
                                        {
                                            href: '${PARAMS[PARAMS.environment].jupiter_host}/rego/cibil-report-v2/${STORE.co_applicant_cibil_v2.report_id}',
                                            condition:
                                                '${bool(STORE.co_applicant_cibil_v2)}.safe_eval',
                                            icon: 'bi-pie-chart',
                                            iconBg: 'bg-primary text-white',
                                            label: 'Co-Applicant Bureau Report',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'bureau_report_uploaded',
                            type: 'page',
                            condition: '${STORE.gst_analytics.report_id}',
                            layout: [
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            href: '${PARAMS[PARAMS.environment].jupiter_host}/rego/storage/${STORE.cibil.pdf_id}',
                                            icon: 'bi-filetype-pdf',
                                            iconBg: 'bg-primary text-white',
                                            label: 'Bureau Report PDF',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'itr_analytics',
                            type: 'page',
                            condition: '${STORE.itr.report_id}',
                            layout: [
                                {
                                    type: 'vertical_nav',
                                    items: [
                                        {
                                            href: '${PARAMS[PARAMS.environment].jupiter_host}/rego/signzy-itr/${STORE.itr.report_id}',
                                            icon: 'bi-clipboard-data',
                                            iconBg: 'bg-success text-white',
                                            label: 'ITR',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'financial_summary',
                            action: 'submit',
                            form_data: {
                                financials: '${STORE.financials}.safe_eval',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    financials: {
                                        type: 'object',
                                        properties: {
                                            income: {
                                                type: 'number',
                                                title: 'Declared Income (per annum)',
                                            },
                                            abb: {
                                                type: 'number',
                                                title: 'Average Bank Balance ',
                                            },
                                        },
                                        required: ['income', 'abb'],
                                    },
                                },
                            },
                            ui: {
                                financials: {
                                    'ui:label': false,
                                    income: {
                                        cols: 6,
                                    },
                                    abb: {
                                        cols: 6,
                                    },
                                },
                            },
                        },
                        {
                            id: 'bre_result',
                            type: 'page',
                            layout: [
                                {
                                    type: 'table',
                                    className: 'table-bordered',
                                    heads: ['Rule', 'Expected Value', 'Value', 'Result', 'Reason'],
                                    data: [
                                        {
                                            Rule: 'CIBIL Score',
                                            'Expected Value': '>= 680',
                                            Value: '${STORE.cibil_v2.score}',
                                            Result: "${'PASS' if STORE.bre.score.status == True else 'FAIL' if STORE.bre.score.status == False else '-'}",
                                            Reason: '${STORE.bre.score.reason}',
                                        },
                                        {
                                            Rule: 'DPDs in last 3 months',
                                            'Expected Value': '< 30',
                                            Result: "${'PASS' if STORE.bre.dpd_last_3_months.status == True else 'FAIL' if STORE.bre.dpd_last_3_months.status == False else '-'}",
                                            Value: "${'< 30' if STORE.bre.dpd_last_3_months.status else '> 30'}",
                                            Reason: '${STORE.bre.dpd_last_3_months.reason}',
                                        },
                                        {
                                            Rule: 'DPDs in last 12 months',
                                            'Expected Value': '< 60',
                                            Value: "${'< 60' if STORE.bre.dpd_last_12_months.status else '> 60'}",
                                            Result: "${'PASS' if STORE.bre.dpd_last_12_months.status == True else 'FAIL' if STORE.bre.dpd_last_12_months.status == False else '-'}",
                                            Reason: '${STORE.bre.dpd_last_12_months.reason}',
                                        },
                                        {
                                            Rule: 'Suit Filing',
                                            'Expected Value': 'None',
                                            Result: "${'PASS' if STORE.bre.suit_filed.status == True else 'FAIL' if STORE.bre.suit_filed.status == False else '-'}",
                                            Reason: '${STORE.bre.suit_filed.reason}',
                                        },
                                        {
                                            Rule: 'Default Outstanding',
                                            'Expected Value': '0',
                                            Result: "${'PASS' if STORE.bre.default_outstanding.status == True else 'FAIL' if STORE.bre.default_outstanding.status == False else '-'}",
                                            Reason: '${STORE.bre.default_outstanding.reason}',
                                        },
                                        {
                                            Rule: 'Age',
                                            'Expected Value': '18-65',
                                            Value: "${int(days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365) if STORE.applicant.dob else '-'}",
                                            Result: "${'Co-Applicant Required' if days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365 > 65 else 'PASS'}",
                                            Reason: "${'Age > 65' if days_diff(strptime(STORE.applicant.dob, '%Y-%m-%d'), now())/365 > 65 else '-'}",
                                        },
                                        {
                                            Rule: 'Profession',
                                            'Expected Value': 'should not be in Negative list',
                                            Value: '${STORE.applicant.profession}',
                                            Result: "${'PASS' if (STORE.applicant.profession != 'Lawyer/Judge' and STORE.applicant.profession != 'Arms & Ammunition' and STORE.applicant.profession != 'Police' and STORE.applicant.profession != 'Broker/ Real Estate' and STORE.applicant.profession != 'DSA/ DST/ Commission Agent/ Money Lender/ Private Financing' and STORE.applicant.profession != 'Liquor/ Wine Shop'and STORE.applicant.profession != 'Gym' and STORE.applicant.profession != 'Politician/ Political Relation'and STORE.applicant.profession != 'Collection/ Recovery Agent' and STORE.applicant.profession != 'Religious Institution') else 'FAIL'}",
                                        },
                                        {
                                            Rule: 'Geography',
                                            'Expected Value': 'except J&K & Sikkim',
                                            Value: '${STORE.applicant.address.pincode}',
                                            Result: "${'FAIL' if (str(data.applicant.address.pincode)[0:2] in (['18', '37'])) else 'PASS'}",
                                        },
                                        {
                                            Rule: 'Income',
                                            'Expected Value':
                                                "${STORE.financials.salary if STORE.financials.salary else '-'}",
                                            Value: '${STORE.financials.income}',
                                            Result: "${'Approval Required' if (STORE.financials.salary and STORE.verification.financials.salary.comparison and (STORE.verification.financials.salary.comparison < 0.9 or STORE.verification.financials.salary.comparison > 1.1))  else 'PASS'}",
                                            Reason: "${'Mismatch' if (STORE.financials.salary and STORE.verification.financials.salary.comparison and (STORE.verification.financials.salary.comparison < 0.9 or STORE.verification.financials.salary.comparison > 1.1))  else 'PASS'}",
                                        },
                                        {
                                            Rule: 'Income Source',
                                            Value: "${STORE.verification.financials.salary.source if STORE.verification.financials.salary.source else '-'}",
                                        },
                                        {
                                            Rule: 'PAN <> Bank Statement Match',
                                            'Expected Value': '${STORE.applicant.pan}',
                                            Value: '${STORE.bank_connect.pan}',
                                            Result: "${'PASS' if STORE.applicant.pan == STORE.bank_connect.pan else '-' if not STORE.bank_connect.pan else 'FAIL'}",
                                        },
                                        {
                                            Rule: 'Installation address: Same as aadhaar?',
                                            'Expected Value':
                                                "${' '.join([STORE.aadhaar.details.model.address.house or '', STORE.aadhaar.details.model.address.street or '', STORE.aadhaar.details.model.address.landmark or '', STORE.aadhaar.details.model.address.loc or '', STORE.aadhaar.details.model.address.po or '', STORE.aadhaar.details.model.address.dist or '', STORE.aadhaar.details.model.address.state or '', STORE.aadhaar.details.model.address.pc or ''])}",
                                            Value: "${' '.join([STORE.applicant.address.address_line or '', STORE.applicant.address.city or '', STORE.applicant.address.state or '', STORE.applicant.address.pincode or ''])}",
                                            Result: "${'YES' if STORE.applicant.address.same_as_aadhaar else 'NO'}",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'loan_offers_calculation',
                            type: 'page',
                            layout: [
                                {
                                    type: 'data_list',
                                    items: [
                                        {
                                            value: '${STORE.requirement.loan_amount}.safe_eval',
                                            label: 'Required Loan Amount',
                                            transform: 'amount',
                                        },
                                        {
                                            value: '${STORE.financials.income}.safe_eval',
                                            label: 'Declared Income (per Annum)',
                                            transform: 'amount',
                                        },
                                        {
                                            value: '${STORE.financials.salary if STORE.financials.salary else 0}',
                                            label: 'Salary from Bank Statement/ITR',
                                            transform: 'amount',
                                        },
                                        {
                                            value: '${STORE.financials.abb}.safe_eval',
                                            label: 'Average Bank Balance',
                                            transform: 'amount',
                                        },
                                        {
                                            value: '${STORE.financials.rd}.safe_eval',
                                            label: 'Recurring Deposit',
                                            transform: 'amount',
                                        },
                                        {
                                            value: '${STORE.financials.fd}.safe_eval',
                                            label: 'Fixed Deposit',
                                            transform: 'amount',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'offers',
                            form_data: {
                                selected_offer: '${STORE.selected_offer}.safe_eval',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    selected_offer: {
                                        type: 'number',
                                    },
                                },
                            },
                            ui: {
                                selected_offer: {
                                    'ui:label': false,
                                    'ui:widget': 'rich_radio',
                                    viewOnly: true,
                                    'ui:enumNamesLayout': {
                                        enum: '${STORE.offers}.safe_eval',
                                        avatar: {
                                            path: 'tenure',
                                        },
                                        body: [
                                            {
                                                float: true,
                                                elements: [
                                                    {
                                                        tag: 'h5',
                                                        className: 'me-1 mb-0',
                                                        path: 'emi',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        tag: 'h5',
                                                        prefix: 'x',
                                                        className: 'me-1 mb-0',
                                                        path: 'tenure',
                                                    },
                                                    {
                                                        tag: 'h5',
                                                        className: 'mb-0',
                                                        path: 'tenure_type',
                                                    },
                                                ],
                                            },
                                            {
                                                elements: [
                                                    {
                                                        tag: 'small',
                                                        path: 'interest_rate',
                                                        prefix: 'Interest Rate',
                                                        suffix: ' (Flat)',
                                                        transform: 'perc',
                                                    },
                                                    {
                                                        tag: 'small',
                                                        path: 'pf',
                                                        prefix: 'Processing Fee: ',
                                                        transform: 'perc',
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                        {
                            id: 'selected_offer',
                            action: 'submit',
                            form_data: {
                                requirement: {
                                    selected_offer: '${STORE.requirement.selected_offer}.safe_eval',
                                },
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    requirement: {
                                        type: 'object',
                                        properties: {
                                            type: 'object',
                                            selected_offer: {
                                                type: 'object',
                                                properties: {
                                                    tenure: {
                                                        type: 'number',
                                                    },
                                                    tenure_type: {
                                                        type: 'string',
                                                    },
                                                    pf: {
                                                        type: 'number',
                                                        title: 'Processing Fee',
                                                    },
                                                    interest_rate: {
                                                        type: 'number',
                                                    },
                                                },
                                                required: [
                                                    'tenure',
                                                    'tenure_type',
                                                    'pf',
                                                    'interest_rate',
                                                ],
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                requirement: {
                                    'ui:label': false,
                                    selected_offer: {
                                        'ui:label': false,
                                        tenure_type: {
                                            'ui:readonly': true,
                                        },
                                    },
                                },
                            },
                        },
                        {
                            id: 'cam_comments',
                            schema: {
                                type: 'object',
                                properties: {
                                    cam: {
                                        title: 'CAM Comments',
                                        type: 'object',
                                        properties: {
                                            cam_comments: {
                                                type: 'string',
                                                title: 'Underwriter CAM Comments',
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                cam: {
                                    'ui:label': false,
                                    cam_comments: {
                                        'ui:widget': 'ckeditor',
                                        'ui:label': false,
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['Admin (Default)'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'underwriting',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                    },
                ],
            },
            ops: {
                type: 'web_form',
                config: {
                    screen_layout: [
                        {
                            className: 'row',
                            elements: [
                                {
                                    className: 'col-md-6',
                                    elements: [
                                        {
                                            form_id: 'vcip_request',
                                            type: 'card',
                                            title: 'VCIP',
                                        },
                                        {
                                            form_id: 'sanction_letter',
                                            type: 'card',
                                            title: 'Sanction Letter',
                                        },
                                    ],
                                },
                                {
                                    className: 'col-md-6',
                                    elements: [
                                        {
                                            form_id: 'pre_sanction_checklist',
                                            type: 'card',
                                            title: 'Pre Sanction Checklist',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    forms: [
                        {
                            id: 'vcip_request',
                            condition: '${ not STORE.video_kyc.init_response.id }.safe_eval',
                            action: 'vcip_request',
                            type: 'page',
                            layout: [
                                {
                                    type: 'heading',
                                    tag: 'h1',
                                    text: 'Video KYC',
                                },
                                {
                                    type: 'paragraph',
                                    tag: 'p',
                                    text: 'Click the button below to start Video KYC',
                                },
                                {
                                    type: 'action',
                                    text: 'Start Video KYC',
                                    confirm: 'Do you really want to start video KYC?',
                                },
                            ],
                        },
                        {
                            id: 'vcip_request',
                            condition: '${ STORE.video_kyc.init_response.id }.safe_eval',
                            action: 'vcip_refresh',
                            type: 'page',
                            layout: [
                                {
                                    className: 'text-center',
                                    elements: [
                                        {
                                            type: 'icon',
                                            className: 'bi bi-check-circle text-success',
                                        },
                                        {
                                            type: 'heading',
                                            tag: 'h1',
                                            text: "${title(STORE.video_kyc.vkyc_response.status) if STORE.video_kyc.vkyc_response.status else 'Video KYC link sent'}",
                                        },
                                        {
                                            type: 'vertical_nav',
                                            className: 'my-2',
                                            items: [
                                                {
                                                    href: "${'https://enterprise.digio.in/#/kyc/requests/details/KID2503201738255367YJMVFAYOHQ7BF//' if STORE.video_kyc.init_response.id else 'https://enterprise.digio.in/digi-kyc/#/vkyc/dashboard/overview'}",
                                                    icon: 'bi-webcam',
                                                    iconBg: "${'bg-success' if STORE.video_kyc.init_response.id else 'bg-primary'} text-white",
                                                    label: "${'View Video KYC Details on Digio' if STORE.video_kyc.init_response.id else 'Open Agent Login'}",
                                                },
                                            ],
                                        },
                                        {
                                            type: 'action',
                                            text: 'Refresh Video KYC Status',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'sanction_letter',
                            condition:
                                '${bool(STORE.sanction_letter.sanction_letter_id)}.safe_eval',
                            type: 'page',
                            toolbar: [],
                            layout: [
                                {
                                    className: 'd-flex',
                                    elements: [
                                        {
                                            type: 'data_list',
                                            items: [
                                                {
                                                    value: '${STORE.sanction_letter.sanction_letter_id}',
                                                    label: 'Sanction Letter',
                                                    type: 'storage',
                                                },
                                                {
                                                    value: '${STORE.sanction_letter.signed_sanction_letter_id}',
                                                    label: 'Signed Sanction Letter',
                                                    type: 'storage',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'link',
                                            action: 'navigate',
                                            target_node: 'digio_sanction_letter_esign_refresh',
                                            className: 'fs-8 text-primary',
                                            text: 'Refresh eSign Status',
                                        },
                                        {
                                            type: 'link',
                                            action: 'navigate',
                                            target_node: 'sanction_letter_generate',
                                            className: 'fs-8 text-primary',
                                            text: 'Re-generate Sanction Letter',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'pre_sanction_checklist',
                            action: 'submit',
                            form_data: {
                                pre_sanction_checklist: '${STORE.pre_sanction_checklist}.safe_eval',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    pre_sanction_checklist: {
                                        title: 'Pre Sanction Checklist',
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                label: {
                                                    type: 'string',
                                                },
                                                type: {
                                                    type: 'string',
                                                    enum: ['string', 'boolean', 'number'],
                                                },
                                                value: {
                                                    type: ['string', 'boolean', 'number'],
                                                },
                                                comments: {
                                                    type: 'array',
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            ui: {
                                'ui:submitButtonOptions': {
                                    submitText: 'Save',
                                },
                                pre_sanction_checklist: {
                                    'ui:label': false,
                                    'ui:widget': 'checklist',
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['Admin (Default)'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'sanction_letter_esign_form',
                        update_store: {
                            template: 'data',
                            parser: 'jmes',
                        },
                    },
                    {
                        action: 'vcip_request',
                        target_node: 'digio_2_way_video_kyc_initiate',
                    },
                    {
                        action: 'vcip_refresh',
                        target_node: 'digio_2_way_video_kyc_refresh',
                    },
                ],
            },
            cibil_report_upload_form: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            form_data: {
                                bureau_report_file: '${STORE.bureau.file}',
                            },
                            schema: {
                                type: 'object',
                                properties: {
                                    bureau_report_file: {
                                        type: 'string',
                                        title: 'Bureau Report',
                                    },
                                },
                                required: ['bureau_report_file'],
                            },
                            ui: {
                                bureau_report_file: {
                                    'ui:widget': 'file',
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        target_node: 'underwriting',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.bureau.pdf_id',
                                        value: '${data.bureau_report_file}',
                                    },
                                    parser: 'mako',
                                },
                            ],
                        },
                    },
                ],
            },
            basic_details: {
                type: 'web_form',
                config: {
                    forms: [
                        {
                            action: 'submit',
                            schema: {
                                title: 'Enter PAN to get Started',
                                type: 'object',
                                properties: {
                                    applicant: {
                                        type: 'object',
                                        properties: {
                                            mobile_no: {
                                                type: 'string',
                                                title: 'Mobile Number',
                                                pattern: '^[0-9]{10}$',
                                            },
                                            email: {
                                                type: 'string',
                                                title: 'E-Mail',
                                                format: 'email',
                                            },
                                            pan: {
                                                type: 'string',
                                                title: 'PAN',
                                                pattern: '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$',
                                            },
                                        },
                                        required: ['pan'],
                                    },
                                },
                            },
                            ui: {
                                applicant: {
                                    'ui:label': false,
                                    mobile_no: {
                                        'ui:widget':
                                            "${'hidden' if (STORE.applicant.mobile_no or (len(USER.user_id) == 10 if USER.user_id else False)) else 'text'}",
                                    },
                                    pan: {
                                        errors: {
                                            pattern:
                                                'Please enter a valid PAN number in the format: AAAAA0000A',
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
                permissions: [
                    {
                        roles: ['*'],
                        actions: ['*'],
                    },
                ],
                edges: [
                    {
                        action: 'submit',
                        update_store: {
                            templates: [
                                {
                                    template: {
                                        path: 'STORE.applicant',
                                        value: '${data.applicant}.safe_eval',
                                    },
                                    parser: 'mako',
                                },
                            ],
                            parser: 'mako',
                        },
                        target_node: 'outgoing_webhook_lead_created',
                    },
                ],
            },
        },
        start_node: 'init',
    },
    is_current: true,
    secret: 'c01d8a27-a627-47b0-8eed-104a96570ec3',
    store_schema: {
        type: 'object',
    },
    updated_at: {
        $date: '2025-03-07T08:32:00.146Z',
    },
    updated_by: 'eceac2ca-281e-4f2a-9b98-1f89a52e0d70',
    params: {
        environment: 'prod',
        prod: {
            program_id: 'bfa62c1d-fac4-4c45-9c97-35bd126cc7c6',
            co_applicant_flow_id: 'ad1ff990-15a1-4aad-85ba-0bf8f7a68f98',
            jupiter_host: 'https://jupiter.crego.io',
            rego_host: 'https://rego.crego.io',
            co_applicant_email_template_id: '41ae6728-1e3c-463c-ac97-872b92c52e5b',
            sanction_letter_template_id: 'xc169bbc-afae-4a51-ab41-291e811a234e',
            loan_agreement_template_id: 'wc169bbc-afae-4a51-ab41-291e811a234e',
            vcip_flow: 'DHAN FLOW',
        },
        storage: {
            vendor: '${SECRET.storage.vendor}',
            creds_key: 'storage_s3_creds',
        },
        professions: [
            'Agriculture',
            'Army/Defense',
            'Artist',
            'Arms & Ammunition',
            'Banker',
            'Broker/Real Estate',
            'Collection/Recovery Agent',
            'Corporate Employed',
            'Doctor/Nurse',
            'DSA/DST/Commission Agent/Money Lender/Private Financing',
            'Engineer',
            'Freelancer',
            'Government Employee',
            'Gym',
            'Lawyer/Judge',
            'Liquor/Wine Shop',
            'Police',
            'Politician/Political Relation',
            'Religious Institution',
            'Scientist',
            'Self Employed',
            'Teachers/Professors',
            'Trust',
            'Others',
        ],
        states: [
            'ANDAMAN AND NICOBAR ISLANDS',
            'ANDHRA PRADESH',
            'ARUNACHAL PRADESH',
            'ASSAM',
            'BIHAR',
            'CHANDIGARH',
            'CHATTISGARH',
            'DADRA AND NAGAR HAVELI AND DAMAN AND DIU',
            'DELHI',
            'GOA',
            'GUJARAT',
            'HARYANA',
            'HIMACHAL PRADESH',
            'JAMMU AND KASHMIR',
            'JHARKHAND',
            'KARNATAKA',
            'KERALA',
            'LADAKH',
            'LAKSHADWEEP',
            'MADHYA PRADESH',
            'MAHARASHTRA',
            'MANIPUR',
            'MEGHALAYA',
            'MIZORAM',
            'NAGALAND',
            'ODISHA',
            'PUDUCHERRY',
            'PUNJAB',
            'RAJASTHAN',
            'SIKKIM',
            'TAMIL NADU',
            'TELANGANA',
            'TRIPURA',
            'UTTAR PRADESH',
            'UTTARAKHAND',
            'WEST BENGAL',
        ],
        statesMap: {
            'JAMMU and KASHMIR': '01',
            'HIMACHAL PRADESH': '02',
            PUNJAB: '03',
            CHANDIGARH: '04',
            UTTRANCHAL: '05',
            HARYANA: '06',
            DELHI: '07',
            RAJASTHAN: '08',
            'UTTAR PRADESH': '09',
            BIHAR: '10',
            SIKKIM: '11',
            'ARUNACHAL PRADESH': '12',
            NAGALAND: '13',
            MANIPUR: '14',
            MIZORAM: '15',
            TRIPURA: '16',
            MEGHALAYA: '17',
            ASSAM: '18',
            'WEST BENGAL': '19',
            JHARKHAND: '20',
            ORRISA: '21',
            CHHATTISGARH: '22',
            CHATTISGARH: '22',
            'MADHYA PRADESH': '23',
            GUJARAT: '24',
            GUJRAT: '24',
            'DAMAN and DIU': '25',
            'DADARA and NAGAR HAVELI': '26',
            MAHARASHTRA: '27',
            'ANDHRA PRADESH': '28',
            KARNATAKA: '29',
            GOA: '30',
            LAKSHADWEEP: '31',
            KERALA: '32',
            'TAMIL NADU': '33',
            PONDICHERRY: '34',
            'ANDAMAN and NICOBAR ISLANDS': '35',
            TELANGANA: '36',
        },
    },
};
