export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_REGO || process.env.REACT_APP_API_ROOT_DEFAULT) + '/rego/';

export const urls = {
    flows: BASE_URL + 'flows/',
    flow_details: BASE_URL + 'flows/:flow_id/',
    flow_activate: BASE_URL + 'flows/:flow_id/activate/',
    flow_hold: BASE_URL + 'flows/:flow_id/hold/',
    flow_run: BASE_URL + 'flows/:flow_id/run/',
    flow_start: BASE_URL + 'flows/:flow_id/start/',
    flow_current_design: BASE_URL + 'flows/:flow_id/current_design/',

    designs: BASE_URL + 'designs/',
    design_details: BASE_URL + 'designs/:design_id/',
    design_mark_as_current: BASE_URL + 'designs/:design_id/current',

    warehouses: BASE_URL + 'warehouses/',
    warehouse_details: BASE_URL + 'warehouses/:warehouse_id/',

    runners: BASE_URL + 'runners/',
    runner_details: BASE_URL + 'runners/:runner_id/',
    runner_update_design: BASE_URL + 'runners/:runner_id/update_design/',
    runner_next: BASE_URL + 'runners/:runner_id/next/',
    runner_store: BASE_URL + 'runners/:runner_id/store/',
    runner_activities: BASE_URL + 'runners/:runner_id/activity/',

    nodes: BASE_URL + 'nodes/',
    node_details: BASE_URL + 'nodes/:node_id/',
    node_duplicate: BASE_URL + 'nodes/:node_id/duplicate/',

    stores: BASE_URL + 'stores/',
    store_details: BASE_URL + 'stores/:store_id/',

    secrets: BASE_URL + 'secrets/',
    secret_details: BASE_URL + 'secrets/:secret_id/',

    templates: BASE_URL + 'templates/',
    template_details: BASE_URL + 'templates/:template_id/',

    edges: BASE_URL + 'edges/',
    edge_details: BASE_URL + 'edges/:edge_id/',
    edge_duplicate: BASE_URL + 'edges/:edge_id/duplicate/',

    storage_documents_details: BASE_URL + 'documents/:id/',
    storage_file: BASE_URL + 'documents/files/download/',
};
