import { Handle, Position } from '@xyflow/react';
import React from 'react';

const NodeWebForm = ({ id, data, deletable, dragHandle, isConnectable, type }) => {
    const iconsMap = {
        string: 'bi bi-type',
        number: 'bi bi-123',
        integer: 'bi bi-123',
        object: 'bi bi-list-nested',
        boolean: 'bi bi-toggle-on',
        array: 'bi bi-view-stacked',
    };
    return (
        <>
            <Handle
                type='target'
                id='left'
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='top'
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='bottom'
                position={Position.Bottom}
                isConnectable={isConnectable}
            />
            <Handle
                type='source'
                position={Position.Right}
                id='right'
                isConnectable={isConnectable}
            />
            <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-flush w-100'>
                    <div className='card-header p-3 min-h-1px min-w-200px'>
                        <div className='card-title m-0 fs-6'>{id}</div>
                    </div>
                    <div className='card-body px-3 py-0'>
                        {data?.node?.config?.forms?.map((form, formIndex) => {
                            return (
                                <React.Fragment key={formIndex}>
                                    {form?.schema?.properties &&
                                        Object.entries(form?.schema?.properties).map(
                                            ([key, property], propertyIndex) => {
                                                return (
                                                    <div
                                                        key={propertyIndex}
                                                        className='border border-dotted border-gray-400 rounded my-1 px-2 py-1 fs-8 d-flex gap-2 align-items-center'>
                                                        <i
                                                            className={
                                                                iconsMap?.[property?.type]
                                                            }></i>
                                                        <label className=''>{key}</label>
                                                    </div>
                                                );
                                            }
                                        )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className='card-footer p-3 d-flex justify-content-between'>
                        <span className='text-primary cursor-pointer me-2'>Edit</span>
                        {deletable && (
                            <span role='button'>
                                <i className='bi bi-trash text-danger'></i>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default NodeWebForm;
