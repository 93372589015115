import React, { useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { date } from '../../../helpers/datetime';
import { amount, beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import BureauScore from '../BureauScore';
import { gender } from './_cibil-report.consts';
import CibilAccounts from './CibilAccounts';
import CibilAddresses from './CibilAddresses';
import CibilEmployment from './CibilEmployment';
import CibilEnquiries from './CibilEnquiries';
import CibilIds from './CibilIds';
import CibilSummary from './CibilSummary';

const CibilReportV2 = ({ details, applicant, view = 'full' }) => {
    useEffect(() => {}, [details]);

    return (
        <>
            {view === 'full' && (
                <>
                    {details?.consumerCreditData.map((row, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='card card-flush card-custom h-100'>
                                            <div className='card-body'>
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between me-3'>
                                                        <div className='d-flex flex-column'>
                                                            {row?.names?.map((name) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={name?.index}>
                                                                        <div className='d-flex align-items-end mb-2'>
                                                                            <span className='fs-2 fw-bold me-2'>
                                                                                {name?.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className='d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2'>
                                                                            <span className='d-flex align-items-center text-gray-500 me-5'>
                                                                                <i
                                                                                    className={`bi bi-gender-${gender[
                                                                                        name?.gender
                                                                                    ]?.toLowerCase()} me-2`}></i>
                                                                                {
                                                                                    gender[
                                                                                        name?.gender
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                            <span className='d-flex align-items-center text-gray-500 me-5'>
                                                                                <i className='bi bi-calendar-date-fill me-2'></i>
                                                                                DOB:{' '}
                                                                                {transform(
                                                                                    'date',
                                                                                    name?.birthDate,
                                                                                    'Do MMM YYYY',
                                                                                    'DDMMYYYY'
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                            <div className='d-flex flex-wrap'>
                                                                {[
                                                                    ...new Set(
                                                                        row?.emails?.map(
                                                                            (email) =>
                                                                                email?.emailID
                                                                        )
                                                                    ),
                                                                ].map((emailID, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <span className='d-flex align-items-center text-gray-500 me-5'>
                                                                                <i className='bi bi-envelope-fill me-2'></i>
                                                                                {emailID}
                                                                            </span>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className='d-flex flex-wrap fw-semibold fs-6 my-2 pe-2'>
                                                                {[
                                                                    ...new Set(
                                                                        row?.telephones?.map(
                                                                            (telephone) =>
                                                                                telephone?.telephoneNumber
                                                                        )
                                                                    ),
                                                                ].map((telephoneNumber, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <span className='d-flex align-items-center text-gray-500 me-5'>
                                                                                <i className='bi bi-telephone-fill me-2'></i>
                                                                                {telephoneNumber}
                                                                            </span>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='w-25 me-10 align-self-start'>
                                                            {row?.scores?.map((score) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={score?.scoreName}>
                                                                        <div>
                                                                            <BureauScore
                                                                                score={score?.score}
                                                                                source='cibil'
                                                                            />
                                                                        </div>
                                                                        <div className='mt-5 text-center'>
                                                                            <a
                                                                                href='#'
                                                                                className='text-gray-800 text-hover-primary fs-1 fw-bold lh-0'>
                                                                                {parseInt(
                                                                                    score?.score
                                                                                )}
                                                                            </a>
                                                                            <span className='text-gray-400 fw-semibold d-block fs-6'>
                                                                                CIBIL Score
                                                                            </span>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='separator separator-dashed my-3'></div>
                                                    {row?.scores?.map((score) => {
                                                        return (
                                                            <React.Fragment key={score.scoreName}>
                                                                <strong>Reasons</strong>:{' '}
                                                                {score?.reasonCodes?.map(
                                                                    (reason) => {
                                                                        return (
                                                                            <span
                                                                                className='badge badge-light-primary me-2'
                                                                                key={
                                                                                    reason.reasonCodeName
                                                                                }>
                                                                                {
                                                                                    reason.reasonCodeValue
                                                                                }
                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    <div className='separator separator-dashed my-3'></div>
                                                    <div className='d-flex flex-wrap gap-10 mt-5'>
                                                        {details?.consumerSummaryData
                                                            ?.accountSummary &&
                                                            Object.entries(
                                                                details?.consumerSummaryData
                                                                    ?.accountSummary
                                                            ).map(([key, value]) => {
                                                                return (
                                                                    <div key={key}>
                                                                        <div className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                                            {[
                                                                                'highCreditAmount',
                                                                                'currentBalance',
                                                                                'overdueBalance',
                                                                                'recentDateOpened',
                                                                                'oldestDateOpened',
                                                                            ].indexOf(key) ===
                                                                                -1 && <>{value}</>}
                                                                            {[
                                                                                'highCreditAmount',
                                                                                'currentBalance',
                                                                                'overdueBalance',
                                                                            ].indexOf(key) !==
                                                                                -1 && (
                                                                                <>{amount(value)}</>
                                                                            )}
                                                                            {[
                                                                                'recentDateOpened',
                                                                                'oldestDateOpened',
                                                                            ].indexOf(key) !==
                                                                                -1 && (
                                                                                <>
                                                                                    {date(
                                                                                        value,
                                                                                        'Do MMM YYYY',
                                                                                        'DDMMYYYY'
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className='text-gray-400 fw-semibold d-block fs-6'>
                                                                            {beautify(key)}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    <div className='separator separator-dashed my-3'></div>
                                                    <div className='d-flex flex-wrap gap-10 mt-5'>
                                                        {details?.consumerSummaryData
                                                            ?.inquirySummary &&
                                                            Object.entries(
                                                                details?.consumerSummaryData
                                                                    ?.inquirySummary
                                                            ).map(([key, value]) => {
                                                                return (
                                                                    <div key={key}>
                                                                        <div className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                                            {[
                                                                                'recentInquiryDate',
                                                                            ].indexOf(key) ===
                                                                                -1 && <>{value}</>}
                                                                            {[
                                                                                'recentInquiryDate',
                                                                            ].indexOf(key) !==
                                                                                -1 && (
                                                                                <>
                                                                                    {date(
                                                                                        value,
                                                                                        'Do MMM YYYY',
                                                                                        'DDMMYYYY'
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className='text-gray-400 fw-semibold d-block fs-6'>
                                                                            {beautify(key)}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CibilSummary
                                    accounts={row?.accounts}
                                    enquiries={row?.enquiries}
                                />
                                <div
                                    className='accordion accordion-icon-toggle mt-5'
                                    id='cibil_accordion'>
                                    <div className='mb-5'>
                                        <div
                                            className='accordion-header py-3 d-flex'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#accordion_cibil_accounts'>
                                            <span className='accordion-icon'>
                                                <KTSVG
                                                    className='svg-icon svg-icon-4'
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                />
                                            </span>
                                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>
                                                Accounts
                                            </h3>
                                        </div>
                                        <div
                                            id='accordion_cibil_accounts'
                                            className='fs-6 collapse show'
                                            data-bs-parent='#cibil_accordion'>
                                            <CibilAccounts details={row?.accounts} />
                                        </div>
                                    </div>
                                    <div className='mb-5'>
                                        <div
                                            className='accordion-header py-3 d-flex'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#accordion_cibil_enquiries'>
                                            <span className='accordion-icon'>
                                                <KTSVG
                                                    className='svg-icon svg-icon-4'
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                />
                                            </span>
                                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>
                                                Enquiries
                                            </h3>
                                        </div>
                                        <div
                                            id='accordion_cibil_enquiries'
                                            className='fs-6 collapse show'
                                            data-bs-parent='#cibil_accordion'>
                                            <CibilEnquiries details={row?.enquiries} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-5'>
                                    <div
                                        className='accordion-header py-3 d-flex'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#accordion_cibil_ids'>
                                        <span className='accordion-icon'>
                                            <KTSVG
                                                className='svg-icon svg-icon-4'
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                            />
                                        </span>
                                        <h3 className='text-gray-800 fw-bold mb-0 ms-4'>IDs</h3>
                                    </div>
                                    <div
                                        id='accordion_cibil_ids'
                                        className='fs-6 collapse show'
                                        data-bs-parent='#kt_accordion_2'>
                                        <CibilIds details={row?.ids} />
                                    </div>
                                </div>
                                <div className='mb-5'>
                                    <div
                                        className='accordion-header py-3 d-flex'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#accordion_employment'>
                                        <span className='accordion-icon'>
                                            <KTSVG
                                                className='svg-icon svg-icon-4'
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                            />
                                        </span>
                                        <h3 className='text-gray-800 fw-bold mb-0 ms-4'>
                                            Employment
                                        </h3>
                                    </div>
                                    <div
                                        id='accordion_employment'
                                        className='fs-6 collapse show'
                                        data-bs-parent='#kt_accordion_2'>
                                        <CibilEmployment details={row?.employment} />
                                    </div>
                                </div>
                                <div className='mb-5'>
                                    <div
                                        className='accordion-header py-3 d-flex'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#accordion_cibil_addresses'>
                                        <span className='accordion-icon'>
                                            <KTSVG
                                                className='svg-icon svg-icon-4'
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                            />
                                        </span>
                                        <h3 className='text-gray-800 fw-bold mb-0 ms-4'>
                                            Addresses
                                        </h3>
                                    </div>
                                    <div
                                        id='accordion_cibil_addresses'
                                        className='fs-6 collapse show'
                                        data-bs-parent='#kt_accordion_2'>
                                        <CibilAddresses details={row?.addresses} />
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default CibilReportV2;
