import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWarehouseDetails } from '../_rego.service';
import DataList from '../../../components/organisms/DataList';

const SignzyItrReport = () => {
    const { report_id } = useParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        setReport(data);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, [report_id]);
    return (
        <>
            {report?.ITR?.['Tax Calculation']?.['Tax Calculation'].map((row, index) => {
                return (
                    <React.Fragment key={`${index}`}>
                        <h1>
                            {row.Year} -{' '}
                            {
                                report?.ITR?.['General Information']?.['General Information']?.[
                                    index
                                ]?.['ITR Type']
                            }
                        </h1>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title'>General Information</div>
                                    </div>
                                    <div className='card-body'>
                                        <DataList
                                            fields={[
                                                {
                                                    value: 'PAN',
                                                    label: 'PAN Number',
                                                },
                                                {
                                                    value: 'Original Return Filing Date',
                                                    label: 'Filing Date',
                                                },
                                                {
                                                    value: 'ITR Type',
                                                    label: 'ITR Type',
                                                },
                                                {
                                                    value: 'Acknowledgement Number',
                                                    label: 'Acknowledgement Number',
                                                },
                                                {
                                                    value: 'Original or Revised',
                                                    label: 'Filing Type',
                                                },
                                            ]}
                                            details={
                                                report?.ITR?.['General Information']?.[
                                                    'General Information'
                                                ]?.[index]
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='card mt-5'>
                                    <div className='card-header'>
                                        <div className='card-title'>Personal Information</div>
                                    </div>
                                    <div className='card-body'>
                                        <DataList
                                            details={
                                                report?.ITR?.['General Information']?.[
                                                    'General Information'
                                                ]?.[index]
                                            }
                                            fields={[
                                                'panNumber',
                                                {
                                                    value: `Name`,
                                                    label: 'Name',
                                                },
                                                {
                                                    value: `Father's Name`,
                                                    label: "Father's Name",
                                                },
                                                {
                                                    value: `Registered Address`,
                                                    label: 'Registered Address',
                                                },
                                                {
                                                    value: `PAN`,
                                                    label: 'PAN',
                                                },
                                                {
                                                    value: `Date of Birth`,
                                                    label: 'Date of Birth',
                                                },
                                                {
                                                    value: `Nature of Employment (Govt/PSU/Other/Pensioners/NA)`,
                                                    label: 'Employer Category',
                                                },
                                                {
                                                    value: `Aadhar Number/Aadhar Enrollment Number`,
                                                    label: 'Aadhaar Card Number',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                {(report?.ITR?.['General Information']?.[
                                    'Nature of Business/Profession'
                                ]?.[index]?.Year ||
                                    report?.ITR?.['General Information']?.[
                                        'Gross Receipt Reported for GST'
                                    ]?.[index]?.Year) && (
                                    <div className='card mt-5'>
                                        <div className='card-header'>
                                            <div className='card-title'>Business Details</div>
                                        </div>
                                        <div className='card-body'>
                                            <DataList
                                                fields={[
                                                    {
                                                        value: `Trade Name of Business, if any`,
                                                        label: 'Name of Business',
                                                    },
                                                    {
                                                        value: `Code-Sub Sector`,
                                                        label: 'Code-Sub Sector',
                                                    },
                                                ]}
                                                details={
                                                    report?.ITR?.['General Information']?.[
                                                        'Nature of Business/Profession'
                                                    ]?.[index]
                                                }
                                            />
                                            <DataList
                                                fields={[
                                                    {
                                                        value: `GSTIN`,
                                                        label: 'GSTIN',
                                                    },
                                                    {
                                                        value: `Annual Value of Outward Supplies`,
                                                        label: 'Annual Value of Outward Supplies',
                                                        transform: 'amount',
                                                    },
                                                ]}
                                                details={
                                                    report?.ITR?.['General Information']?.[
                                                        'Gross Receipt Reported for GST'
                                                    ]?.[index]
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6'>
                                {report?.ITR?.['Tax Calculation']?.['Tax Calculation']?.[index] && (
                                    <div className='card'>
                                        <div className='card-header'>
                                            <div className='card-title'>Tax Calculations</div>
                                        </div>
                                        <div className='card-body'>
                                            <DataList
                                                details={
                                                    report?.ITR?.['Tax Calculation']?.[
                                                        'Tax Calculation'
                                                    ]?.[index]
                                                }
                                                fields={[
                                                    {
                                                        value: `Income from Salary`,
                                                        label: 'Income from Salary',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income under House Property`,
                                                        label: 'Income under House Property',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Profits and gains from business and profession`,
                                                        label: 'Profits and gains from business and profession',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income from Short Term Capital Gains as per applicable rates`,
                                                        label: 'Income from Short Term Capital Gains as per applicable rates',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income from Long Term Capital Gains as per applicable rates`,
                                                        label: 'Income from Long Term Capital Gains as per applicable rates',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income from Other Sources`,
                                                        label: 'Income from Other Sources',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Total of Head-Wise Income`,
                                                        label: 'Total of Head-Wise Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Total losses of current year and brought forward losses set off`,
                                                        label: 'Total losses of current year and brought forward losses set off',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Gross Total Income`,
                                                        label: 'Gross Total Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Total Deductions under Chapter VI-A`,
                                                        label: 'Total Deductions under Chapter VI-A',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Deductions under Section 10AA`,
                                                        label: 'Deductions under Section 10AA',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income not Forming Part of Total Income (Section 10AA, 10(23FB), 10(23FC))`,
                                                        label: 'Income not Forming Part of Total Income (Section 10AA, 10(23FB), 10(23FC))',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Total Taxable Income`,
                                                        label: 'Total Taxable Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Income chargeable to tax at special rates`,
                                                        label: 'Income chargeable to tax at special rates',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Net Agricultural Income`,
                                                        label: 'Net Agricultural Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Aggregate Income`,
                                                        label: 'Aggregate Income',
                                                        transform: 'amount',
                                                    },
                                                    {
                                                        value: `Deemed Income under section 115JC/115JB`,
                                                        label: 'Deemed Income under section 115JC/115JB',
                                                        transform: 'amount',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </>
    );
};
export default SignzyItrReport;
