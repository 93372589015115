import {
    Background,
    Controls,
    Panel,
    ReactFlow,
    useEdgesState,
    useNodesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useEffect, useState } from 'react';
import FormControlSwitch from '../../../../components/molecules/FormControl/FormControlSwitch';
import JourneyBuilderContext from '../../../../components/organisms/JourneyBuilder/JourneyBuilderContext';
import { SAMPLE_DESIGN } from '../sample';
import NodeWebForm from './NodeWebForm';
import NodeTask from './NodeTask';
import NodeApiRequest from './NodeApiRequest';
import NodeEdgeOperation from './NodeEdgeOperation';
import NodeExpression from './NodeExpression';
import NodeFlowConnector from './NodeFlowConnector';
import { beautify } from '../../../../helpers/str';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import EdgeDefault from './EdgeDefault';
import { generateReadableText, parseMakoToReadable } from '../../../../helpers/utils';

const DesignEditor = () => {
    const HORIZONTAL_SPACING = 80;
    const VERTICAL_SPACING = 80;
    const nodeTypes = {
        default: {
            width: '180px',
        },
        web_form: {
            width: '180px',
        },
        task: {
            width: '180px',
            height: '80px',
        },
        edge_operation: {
            width: '180px',
            height: '80px',
        },
        expression: {
            width: '180px',
            height: '80px',
        },
        api_request: {
            width: '350px',
        },
        flow_connector: {
            width: '180px',
        },
    };

    const [nerd, setNerd] = useState(false);
    const [theme, setTheme] = useState('light');
    const [design, setDesign] = useState(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        setDesign(SAMPLE_DESIGN);
        const parsedNodes = parseNodes(SAMPLE_DESIGN.graph);
        const parsedEdges = parseEdges(SAMPLE_DESIGN.graph);

        setNodes(parsedNodes);
        setEdges(parsedEdges);
    }, []);

    const parseNodes = (graph) => {
        let res = [];
        const visitedNodes = [];

        res = createNode(graph.start_node, res, {
            nodes: graph.nodes,
            visitedNodes,
        });
        return res;
    };

    const createNode = (id, res, config) => {
        const node = config?.nodes?.[id];
        const nodeType = Object.keys(nodeTypes).indexOf(node?.type) !== -1 ? node?.type : 'default';
        const newNode = buildNode(id, res, {
            ...config,
            nodeType,
        });
        res.push(newNode);
        config.visitedNodes.push(id);
        let edgesCreated = 0;
        config?.nodes?.[id]?.edges?.forEach((edge, edgeIndex) => {
            if (
                edge?.target_node &&
                typeof edge?.target_node === 'string' &&
                config.visitedNodes.indexOf(edge?.target_node) === -1 &&
                config?.nodes?.[edge.target_node]
            ) {
                res = createNode(edge.target_node, res, {
                    ...config,
                    prevPosition: {
                        x: newNode.position.x,
                        y:
                            (parseInt(newNode?.position?.y) || 0) +
                            (parseInt(edgesCreated ? newNode?.style?.height : 0) || 0) +
                            edgesCreated * VERTICAL_SPACING,
                    },
                    prevStyle: newNode.style,
                });
                edgesCreated = edgesCreated + 1;
            }
        });
        return res;
    };

    const buildNode = (id, res, config) => {
        const x =
            parseInt(config?.prevPosition?.x || 0) +
            parseInt(config?.prevStyle?.width || 0) +
            HORIZONTAL_SPACING;

        return {
            id,
            type: config.nodeType,
            position: { x, y: config?.prevPosition?.y || 0 },
            data: {
                label: id,
                node: config?.nodes?.[id],
            },
            style: {
                width: nodeTypes?.[config.nodeType].width,
                height: nodeTypes?.[config.nodeType].height,
            },
            sourcePosition: 'right',
        };
    };

    const parseEdges = (graph) => {
        const res = [];
        const visitedEdges = [];
        Object.entries(graph?.nodes)?.forEach(([id, node]) => {
            node?.edges?.forEach((edge) => {
                const edgeId = `${id}->${edge.target_node}`;
                if (
                    edge?.target_node &&
                    typeof edge?.target_node === 'string' &&
                    edge?.action !== 'navigate' &&
                    Object.keys(graph.nodes).indexOf(id) !== -1 &&
                    visitedEdges.indexOf(edgeId) === -1
                ) {
                    visitedEdges.push(edgeId);
                    const newEdge = {
                        id: edgeId,
                        source: id,
                        target: edge.target_node,
                        type: 'default',
                        data: { edge },
                    };
                    if (edge?.condition) {
                        let condition = edge?.condition;
                        if (typeof condition === 'object') {
                            condition = condition.template;
                        }
                        newEdge.data.label = {
                            center: parseMakoToReadable(condition),
                        };
                    }
                    res.push(newEdge);
                }
            });
        });
        console.log(res);
        return res;
    };

    return (
        <div style={{ width: 'calc(100vw - 50px)', height: 'calc(100vh - 110px)' }}>
            <JourneyBuilderContext.Provider value={{ nerd }}>
                <ReactFlow
                    panOnScroll={true}
                    nodes={nodes}
                    edges={edges}
                    colorMode={theme}
                    nodeTypes={{
                        web_form: NodeWebForm,
                        task: NodeTask,
                        api_request: NodeApiRequest,
                        edge_operation: NodeEdgeOperation,
                        expression: NodeExpression,
                        flow_connector: NodeFlowConnector,
                        /* api_request: JourneyNodeApi,
                        task: JourneyNodeTask,
                        edge_operation: JourneyNodeOperation, */
                    }}
                    // onConnect={handleConnect}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    // onEdgesDelete={handleDeleteEdge}
                    // onNodeDragStop={handleNodeDragStop}
                    // nodeTypes={nodeTypes}
                    edgeTypes={{ default: EdgeDefault }}>
                    <Background />
                    <Controls />
                    <Panel position='bottom-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <button className='btn btn-lg btn-success shadow mb-5'>
                                Save Flow
                            </button>
                            <div className='d-flex justify-content-center align-items-center'>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip>{nerd ? 'Disable' : 'Enable'} Nerd Mode</Tooltip>
                                    }>
                                    <button
                                        className={clsx(
                                            `btn btn-icon btn-xs shadow shadow-sm border me-2`,
                                            {
                                                'border-gray-400': !nerd,
                                                'border-dotted': !nerd,
                                                'btn-warning': nerd,
                                                'border-warning': nerd,
                                            }
                                        )}>
                                        {nerd && (
                                            <i
                                                className='ki-duotone ki-ghost fs-3'
                                                onClick={() => setNerd(false)}></i>
                                        )}
                                        {!nerd && (
                                            <i
                                                className='ki-duotone ki-emoji-happy fs-3'
                                                onClick={() => setNerd(true)}></i>
                                        )}
                                    </button>
                                </OverlayTrigger>
                                <div>
                                    <button
                                        className='btn btn-icon btn-xs shadow shadow-sm border border-dotted border-gray-400'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-start'>
                                        {theme === 'light' && (
                                            <i className='ki-duotone ki-night-day fs-3'></i>
                                        )}
                                        {theme === 'dark' && (
                                            <i className='ki-duotone ki-moon fs-3'></i>
                                        )}
                                    </button>
                                    <div
                                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                                        data-kt-menu='true'>
                                        <div
                                            onClick={() => setTheme('light')}
                                            className='menu-item px-3'>
                                            <div className='menu-link px-3'>
                                                <i className='ki-duotone ki-night-day fs-3 me-2'></i>
                                                Light
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => setTheme('dark')}
                                            className='menu-item px-3'>
                                            <div className='menu-link px-3'>
                                                <i className='ki-duotone ki-moon fs-3 me-2'></i>{' '}
                                                Dark
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => setTheme('system')}
                                            className='menu-item px-3'>
                                            <div className='menu-link px-3'>
                                                <i className='ki-duotone ki-screen fs-3 me-2'></i>
                                                System
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </ReactFlow>
            </JourneyBuilderContext.Provider>
        </div>
    );
};
export default DesignEditor;
