import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { transform } from '../../../helpers/transform';
import { getValue } from '../../../helpers/utils';

const FormViewLabel = (props) => {
    const { details, item, onEnabledToggle, inlineLabel, className } = props;
    return (
        <>
            {((item.type !== 'switch' && item.type !== 'hidden') || item.showLabel === true) && (
                <span className={inlineLabel ? 'col-md-4' : 'col-md-12'}>
                    {item.optional && (
                        <div className='form-check form-check-custom form-check-solid'>
                            <input
                                className='cursor-pointer form-check-input'
                                type='checkbox'
                                value={item.name}
                                onChange={onEnabledToggle}
                            />
                            <label
                                className={`form-check-label ${
                                    item.is_required ? 'required' : ''
                                }`}>
                                {item.label || transform('beautify', item.name)}
                            </label>
                        </div>
                    )}
                    {!item.optional && (
                        <label
                            className={`${
                                item.is_required ? 'required' : ''
                            } col-form-label form-label me-2 ${className}`}>
                            {item.label || transform('beautify', item.name)}
                        </label>
                    )}
                    {item.unit && details && <>(in {getValue(details, item.unit)}) </>}
                    {item.tooltip && (
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-user-name'>{item.tooltip}</Tooltip>}>
                            <i className='bi bi-info-circle-fill cursor-pointer'></i>
                        </OverlayTrigger>
                    )}
                </span>
            )}
        </>
    );
};

export default FormViewLabel;
