export const DUOTONE_ICONS = {
    dashboard: 'general/gen025.svg',

    // Business Verticals
    agriculture: 'abstract/abs047.svg',
    grocery: 'ecommerce/ecm004.svg',
    electronics: 'electronics/elc009.svg',
    logistics: 'general/gen017.svg',
    automobiles: 'ecommerce/ecm006.svg',
    medical: 'medicine/med001.svg',
    apparels: 'ecommerce/ecm003.svg',
    testing: 'coding/cod004.svg',
    hardwareservices: 'ecommerce/ecm009.svg',
    manufacturer: 'general/gen031.svg',

    //icons
    users: 'communication/com006.svg',
    roles: 'general/gen062.svg',
    settings: 'coding/cod001.svg',
    config: 'general/gen019.svg',
    file: 'files/fil003.svg',
    excel: 'files/fil002.svg',
    constants: 'abstract/abs050.svg',
    filter: 'general/gen031.svg',
    sort: 'arrows/arr032.svg',
    search: 'general/gen021.svg',
    location: 'maps/map008.svg',
    notification: 'general/gen007.svg',
    shield: 'general/gen048.svg',
    calendar: 'general/gen014.svg',

    cash: 'finance/fin003.svg',
    wallet: 'finance/fin008.svg',

    company: 'finance/fin001.svg',
    program: 'abstract/abs049.svg',
    bank: 'finance/fin001.svg',
    lead: 'communication/com005.svg',

    target: 'maps/map007.svg',
    flag: 'maps/map001.svg',
    route: 'maps/map009.svg',
    brain: 'technology/teh003.svg',
    pin: 'communication/com009.svg',
    comment: 'communication/com007.svg',
    pie: 'graphs/gra007.svg',
    return: 'arrows/arr059.svg',

    phone: 'electronics/elc002.svg',
    email: 'communication/com011.svg',

    accounts: 'abstract/abs028.svg',

    /* Product Icons Begin */

    crm: 'abstract/abs020.svg',
    los: 'abstract/abs037.svg',
    engine: 'abstract/abs025.svg',
    disburse: 'abstract/abs027.svg',
    accounting: 'abstract/abs028.svg',

    /* Product Icons End */

    design: 'art/art007.svg',

    // directions
    left: 'arrows/arr063.svg',
    right: 'arrows/arr064.svg',
    right_up: 'arrows/arr094.svg',
    down: 'arrows/arr065.svg',
    up: 'arrows/arr066.svg',

    plus: 'arrows/arr075.svg',
    check: 'arrows/arr012.svg',
    check_fill: 'arrows/arr016.svg',
    check_double: 'arrows/arr086.svg',
    remove: 'arrows/arr061.svg',
    info: 'general/gen044.svg',
    copy: 'general/gen028.svg',
    export: 'arrows/arr091.svg',
    import: 'arrows/arr078.svg',
    loading: 'coding/cod005.svg',
    activate: 'arrows/arr084.svg',
    refresh: 'arrows/arr029.svg',

    menu: 'general/gen052.svg',
    text: 'text/txt006.svg',

    magnifier: 'general/gen021.svg',
    cross: 'arrows/arr011.svg',
    home: 'general/gen001.svg',

    signing: 'technology/teh004.svg',
    stamp: 'general/gen048.svg',
    document: 'files/fil004.svg',
    agreement: 'files/fil003.svg',
    cam: 'files/fil001.svg',
    line_details: 'files/fil001.svg',
    loan_data_sets: 'files/fil001.svg',
    approvals: 'arrows/arr084.svg',
    approval: 'arrows/arr084.svg',
    activation_approval: 'maps/map009.svg',
    mandate: 'finance/fin002.svg',
    lead_programs_maps: 'coding/cod007.svg',
    activation: 'maps/map001.svg',

    selfie: 'technology/teh002.svg',
    photo: 'general/gen006.svg',

    perc: 'ecommerce/ecm011.svg',

    // Abstract
    plus: 'abstract/abs011.svg',
x: 'abstract/abs012.svg',
    loading: 'abstract/abs013.svg',
    plugins: 'abstract/abs014.svg',
    hamburger: 'abstract/abs015.svg',
    waves_vertical: 'abstract/abs016.svg',
    waves: 'abstract/abs017.svg',
    rss: 'abstract/abs018.svg',
    credit_line: 'abstract/abs019.svg',
};
