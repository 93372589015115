import React from 'react';
import { searchArray } from '../../../../helpers/arr';
import { beautify } from '../../../../helpers/str';
import { Handle, Position } from '@xyflow/react';

const NodeTask = ({ id, data, deletable, dragHandle, isConnectable, type }) => {
    const tasks = [
        { value: 'dedupe', label: 'Dedupe/PAN', icon: 'bi-search' },
        { value: 'generate_agreement', label: 'Generate Agreement', icon: 'bi-file-earmark-post' },
    ];
    return (
        <>
            <Handle
                type='target'
                id='left'
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='top'
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='bottom'
                position={Position.Bottom}
                isConnectable={isConnectable}
            />
            <Handle
                type='source'
                position={Position.Right}
                id='right'
                isConnectable={isConnectable}
            />
            <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-flush bg-light-warning border-warning w-100'>
                    <div className='card-header p-3 min-h-1px min-w-200px'>
                        <div className='card-title m-0 fs-6'>{id}</div>
                    </div>
                    <div className='card-body px-3 py-2'>
                        <div className='d-flex flex-column align-items-center justify-content-center mb-2'>
                            <i
                                className={`${
                                    searchArray(tasks, 'value', id).icon
                                } fs-2x text-warning`}></i>
                            {data?.node?.config?.name === 'dedupe' && (
                                <div className='text-center fs-8 text-warning'>
                                    Dedupe on{' '}
                                    {Object.keys(data?.node?.config?.params)
                                        .map((name) => beautify(name))
                                        .join(', ')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='card-footer p-3 d-flex justify-content-between'>
                        <span className='text-primary cursor-pointer me-2'>Edit</span>
                        {deletable && (
                            <span role='button'>
                                <i className='bi bi-trash text-danger'></i>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default NodeTask;
