import React from 'react';
import { getValue } from '../../helpers/utils';
import { beautify, validateCondition } from '../../helpers/str';
import { transform } from '../../helpers/transform';
import clsx from 'clsx';
import DocumentPreview from '../../modules/_Rego/Documents/DocumentPreview';

const DataList = ({ fields, details }) => {
    return (
        <div className='row'>
            {fields.map((field, index) => {
                let value, label;
                if (typeof field === 'string') {
                    value = getValue(details, field);
                    label = beautify(field);
                } else {
                    value = getValue(details, field.value, true, '', field?.delimeter || ', ');
                    label = field?.label || beautify(field.value);
                    if (field?.transform) {
                        value = transform(field.transform, value);
                    }
                }

                return (
                    <React.Fragment
                        key={`${typeof field === 'string' ? field : field.value}_${index}`}>
                        {typeof value !== 'object' &&
                            (!field.condition || validateCondition(field.condition, details)) && (
                                <div className='col-md-6 mb-4'>
                                    <div className='info'>
                                        <div className='info__item'>
                                            <div className='info__item__key fs-8 text-muted mb-1'>
                                                {label}
                                            </div>
                                            <div className='info__item__value fw-semibold'>
                                                {field.type === 'badge' && value && (
                                                    <label className={clsx('badge badge-primary')}>
                                                        {value}
                                                    </label>
                                                )}
                                                {field?.type === 'storage' && (
                                                    <DocumentPreview
                                                        config={{
                                                            label: 'View File',
                                                            // position: 'start',
                                                            icon: 'btn btn-icon btn-circle btn-sm btn-light-primary la la-file',
                                                        }}
                                                        documents={[
                                                            {
                                                                storage_id: value,
                                                            },
                                                        ]}
                                                    />
                                                )}
                                                {!field?.type && <>{value || '-'}</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
export default DataList;
