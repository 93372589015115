import { Handle, Position } from '@xyflow/react';

const NodeFlowConnector = ({ id, data, deletable, dragHandle, isConnectable, type }) => {
    return (
        <>
            <Handle
                type='target'
                id='left'
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='top'
                position={Position.Top}
                isConnectable={isConnectable}
            />
            <Handle
                type='target'
                id='bottom'
                position={Position.Bottom}
                isConnectable={isConnectable}
            />
            <Handle
                type='source'
                position={Position.Right}
                id='right'
                isConnectable={isConnectable}
            />
            <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-flush bg-light-success border-success border-dashed w-100'>
                    <div className='card-header p-3 min-h-1px min-w-200px'>
                        <div className='card-title m-0 fs-6'>{id}</div>
                    </div>
                    <div className='card-body px-3 py-2'>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <i className={`bi bi-node-plus fs-3x text-success`}></i>
                        </div>
                    </div>
                    <div className='card-footer p-3 d-flex justify-content-between'>
                        <span className='text-primary cursor-pointer me-2'>Edit</span>
                        {deletable && (
                            <span role='button'>
                                <i className='bi bi-trash text-danger'></i>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default NodeFlowConnector;
