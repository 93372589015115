import React from 'react';
import { idType } from './_cibil-report.consts';

const CibilIds = ({ details }) => {
    return (
        <div className='d-flex gx-5'>
            {details &&
                details.map((row, index) => {
                    return (
                        <div
                            className='card card-custom me-5'
                            key={index}>
                            <div className='card-body'>
                                <div className='d-flex align-banks-center me-3'>
                                    <div className='flex-grow-1'>
                                        <span className='text-gray-800 text-hover-primary fs-5 fw-bold lh-0'>
                                            {row.idNumber}
                                        </span>
                                    </div>
                                </div>
                                <div className='separator separator-dashed my-3'></div>
                                <div className='d-flex justify-content-between'>
                                    <span className='badge badge-light-info'>
                                        {idType[row.idType]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default CibilIds;
