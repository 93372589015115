import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from '@xyflow/react';

const EdgeDefault = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
}) => {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: 4,
    });

    const actionColors = {
        submit: '#f9295a',
        api_request: '#1c84ff',
        navigate: '#7239ea',
        auto: '#ccc',
    };

    const actionClasses = {
        submit: 'danger',
        api_request: 'primary',
        navigate: 'info',
        auto: 'secondary',
    };

    const EdgeLabel = ({ transform, label }) => {
        const key = label?.toLowerCase();
        let badgeClass = 'light';
        if (actionClasses?.[key]) {
            badgeClass = actionClasses[key];
        } else {
            if (
                key?.indexOf('false') !== -1 ||
                key?.indexOf('inactive') !== -1 ||
                key?.indexOf('not') !== -1
            ) {
                badgeClass = 'light-danger border border-danger';
            } else if (
                key?.indexOf('true') !== -1 ||
                key?.indexOf('active') !== -1 ||
                key?.indexOf('in') !== -1 ||
                key?.indexOf('==') !== -1
            ) {
                badgeClass = 'light-success border border-success';
            }
        }
        return (
            <label
                style={{
                    position: 'absolute',
                    transform,
                }}
                className={`nodrag nopan badge badge-${badgeClass} rounded-pill`}>
                {label}
            </label>
        );
    };

    return (
        <>
            <svg style={{ height: 2 }}>
                <defs>
                    <marker
                        id={`arrowClosed-${data?.edge?.action}`}
                        markerWidth='10'
                        markerHeight='10'
                        refX='5'
                        refY='3'
                        orient='auto'
                        markerUnits='strokeWidth'>
                        <path
                            d='M0,0 L0,6 L6,3 z'
                            fill={actionColors?.[data?.edge?.action] || '#ccc'}
                        />
                    </marker>
                </defs>
            </svg>
            <BaseEdge
                id={id}
                path={edgePath}
                markerEnd={`url(#arrowClosed-${data?.edge?.action})`}
                style={{
                    strokeWidth: data?.edge?.action === 'submit' ? 2 : 1,
                    stroke: actionColors[data?.edge?.action] || '#ccc',
                }}
            />
            <EdgeLabelRenderer>
                {data?.label?.start && (
                    <EdgeLabel
                        transform={`translate(-50%, 0%) translate(${sourceX + 30}px,${
                            sourceY - 10
                        }px)`}
                        label={data?.label?.start}
                    />
                )}
                {data?.label?.end && (
                    <EdgeLabel
                        transform={`translate(-110%, -50%) translate(${targetX}px,${targetY}px)`}
                        label={data?.label?.end}
                    />
                )}
                {data?.label?.center && (
                    <EdgeLabel
                        transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
                        label={data?.label?.center}
                    />
                )}
            </EdgeLabelRenderer>
        </>
    );
};
export default EdgeDefault;
